import { createUseStyles } from "react-jss";

import { addPopup, removePopup } from "../popups";
import { CardSetDto } from "dtos/shard/card-set.dto";
import { ttsService } from "services";

import Popup from "components/popups/popup";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
    set: {
        textTransform: "capitalize",
    },
});

export default function CardSetView(props: {
    cardSet?: Partial<CardSetDto>,
}): JSX.Element
{
    // Actions:
    const ttsExport = () => {
        if (!props.cardSet?.id) {
            return;
        }
        addPopup("ttsExport", (
            <Popup
                title="Tabletop Simulator Export"
                description={[
                    `Click "Export" to download a card deck object for Tabletop Simulator with one of each Card in this Set. You can use it by copying it into your TTS "My Saves" folder located at:`,
                    `Linux: ~/.local/share/Tabletop Simulator`,
                    `Mac: ~/Library/Tabletop Simulator/Saves/Saved Objects`,
                    `Windows: %USERPROFILE%/Documents/My Games/Tabletop Simulator/Saves/Saved Objects`,
                ]}
                confirmText="Export"
                confirmAction={() => {
                    ttsService.exportCardSet(props.cardSet!.id!);
                    removePopup("ttsExport");
                }}
                cancelText="Cancel"
                cancelAction={() => removePopup("ttsExport")}
            />
        ));
    };

    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.cardSet?.name ?? "New Card Set"}</h3>
            <p className={`${classes.set}`}>{props.cardSet?.cardSetType?.name ?? "Set Type"}</p>
            {props.cardSet?.id && <button onClick={() => ttsExport()}>
                Export as Tabletop Simulator Deck
            </button>}
        </div>
    );
}