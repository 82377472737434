import { createUseStyles } from "react-jss";

import { IResource } from "bos/resource.bo";

import widgets from "styles/widgets";

const styles = createUseStyles({
    container: {
        "& > button": {
            whiteSpace: "nowrap",
            flexGrow: 0,
            flexBasis: 1,
        },
    },
});

export default function Resources(props: {
    className?: string,
    resources: IResource[],
    resource?: IResource,
    setResource: (resource: string) => void,
}): JSX.Element
{
    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Return Component:
    return (
        <div className={`${props.className ?? ""} ${classes.container}`}>
            {props.resources.map(resource => (
                <button
                    key={resource.service.name}
                    onClick={() => props.setResource(resource.service.name)}
                    className={`${resource === props.resource ? widgetClasses.selected : ""}`}
                >{resource.service.plural}</button>
            ))}
        </div>
    );
}