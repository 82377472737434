import { useState } from "react";
import { v4 as uuid } from "uuid";

import { actionEffectService, actionService, actionTypeService, artistService, cardEntryService, cardService, cardSetService, cardSetTypeService, cardTypeService, creatureEffectService, deckService, elementService, rarityService, ruleCategoryService, ruleService, templateService, titleService, userService } from "services";
import { useServiceListener } from "hooks/service-listener.hook";
import { ResourceBo } from "bos/resource.bo"

import { CardDto } from "dtos/shard/card.dto";
import { ActionDto } from "dtos/shard/action.dto";
import { ElementDto } from "dtos/shard/element.dto";
import { CreatureEffectDto } from "dtos/shard/creature-effect.dto";
import { ActionEffectDto } from "dtos/shard/action-effect.dto";
import { ActionTypeDto } from "dtos/shard/action-type.dto";
import { ArtistDto } from "dtos/shard/artist.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import { CardSetTypeDto } from "dtos/shard/card-set-type.dto";
import { CardSetDto } from "dtos/shard/card-set.dto";
import { CardTypeDto } from "dtos/shard/card-type.dto";
import { RarityDto } from "dtos/shard/rarity.dto";
import { TitleDto } from "dtos/shard/title.dto";
import { TemplateDto } from "dtos/shard/template.dto";
import { DeckDto } from "dtos/shard/deck.dto";
import { RuleCategoryDto } from "dtos/shard/rule-category.dto";
import { RuleDto } from "dtos/shard/rule.dto";
import { UserDto } from "dtos/user.dto";

import CardItem from "components/widgets/items/card-item";
import ActionItem from "components/widgets/items/action-item";
import ElementItem from "components/widgets/items/element-item";
import CreatureEffectItem from "components/widgets/items/creature-effect-item";
import ActionEffectItem from "components/widgets/items/action-effect-item";
import ActionTypeItem from "components/widgets/items/action-type-item";
import ArtistItem from "components/widgets/items/artist-item";
import CardEntryItem from "components/widgets/items/card-entry-item";
import CardSetTypeItem from "components/widgets/items/card-set-type-item";
import CardSetItem from "components/widgets/items/card-set-item";
import CardTypeItem from "components/widgets/items/card-type-item";
import RarityItem from "components/widgets/items/rarity-item";
import TitleItem from "components/widgets/items/title-item";
import TemplateItem from "components/widgets/items/template-item";
import DeckItem from "components/widgets/items/deck-item";

import CardDisplay from "components/widgets/displays/card-display";
import CardEntryDisplay from "components/widgets/displays/card-entry-display";
import ActionDisplay from "components/widgets/displays/action-display";
import CreatureEffectDisplay from "components/widgets/displays/creature-effect-display";
import CardSetDisplay from "components/widgets/displays/card-set-display";
import CardTypeDisplay from "components/widgets/displays/card-type-display";
import CardSetTypeDisplay from "components/widgets/displays/card-set-type-display";
import TitleDisplay from "components/widgets/displays/title-display";
import ActionTypeDisplay from "components/widgets/displays/action-type-display";
import ActionEffectDisplay from "components/widgets/displays/action-effect-display";
import ElementDisplay from "components/widgets/displays/element-display";
import RarityDisplay from "components/widgets/displays/rarity-display";
import ArtistDisplay from "components/widgets/displays/artist-display";
import TemplateDisplay from "components/widgets/displays/template-display";

import CardEditor from "components/widgets/editors/card-editor";
import CardEntryEditor from "components/widgets/editors/card-entry-editor";
import ActionEditor from "components/widgets/editors/action-editor";
import CreatureEffectEditor from "components/widgets/editors/creature-effect-editor";
import CardSetEditor from "components/widgets/editors/card-set-editor";
import CardTypeEditor from "components/widgets/editors/card-type-editor";
import CardSetTypeEditor from "components/widgets/editors/card-set-type-editor";
import TitleEditor from "components/widgets/editors/title-editor";
import ActionTypeEditor from "components/widgets/editors/action-type-editor";
import ActionEffectEditor from "components/widgets/editors/action-effect-editor";
import ElementEditor from "components/widgets/editors/element-editor";
import RarityEditor from "components/widgets/editors/rarity-editor";
import ArtistEditor from "components/widgets/editors/artist-editor";
import TemplateEditor from "components/widgets/editors/template-editor";
import UserItem from "components/widgets/items/user-item";
import DeckDisplay from "components/widgets/displays/deck-display";
import DeckEditor from "components/widgets/editors/deck-editor";

/**
 * Creates a resources map for accessing components, states and hooks for resources.
 * @param user An optional user dto, used by some resources.
 * @param firstItem An optional function to call on the first item loaded by an index.
 * @returns A resources map.
 */
export function useResources(user?: UserDto, firstItem?: (resourceName: string, item: Record<string, any>) => void): Record<string, ResourceBo<any, any, any, any, any>> {
    const requestId: string = uuid();
    const resources: Record<string, ResourceBo<any, any, any, any, any>> = {
        "card": new ResourceBo(
            cardService,
            requestId,
            { secret: { eq: false } },
            {
                name: "asc",
                subspecies: "asc",
                morph: "asc",
            },
            useState(undefined as CardDto | undefined),
            useState(1), useState(50),
            useState({ secret: { eq: false } } as Record<string, any>),
            useState({
                name: "asc",
                subspecies: "asc",
                morph: "asc",
            } as Record<string, string | undefined>),
            item => (
                <CardItem id={item.props?.id ?? item.key} user={user} card={item.props as CardDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardDisplay user={user} card={dto as CardDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardEditor user={user} card={dto as CardDto} preview={dto as CardDto} onSave={onSave} onClose={onClose} />
            ),
            [actionService, creatureEffectService],
            true,
        ),
        "cardType": new ResourceBo(
            cardTypeService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as CardTypeDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <CardTypeItem id={item.props?.id ?? item.key} cardType={item.props as CardTypeDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardTypeDisplay user={user} cardType={dto as CardTypeDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardTypeEditor user={user} cardType={dto as CardTypeDto} preview={dto as CardTypeDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "cardEntry": new ResourceBo(
            cardEntryService,
            requestId,
            { secret: { eq: false } },
            {
                reference: "asc",
                cardSetId: "asc",
            },
            useState(undefined as CardEntryDto | undefined),
            useState(1), useState(50),
            useState({ secret: { eq: false } } as Record<string, any>),
            useState({
                reference: "asc",
                cardSetId: "asc",
            } as Record<string, string | undefined>),
            item => (
                <CardEntryItem id={item.props?.id ?? item.key} user={user} cardEntry={item.props as CardEntryDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardEntryDisplay user={user} cardEntry={dto as CardEntryDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardEntryEditor user={user} cardEntry={dto as CardEntryDto} preview={dto as CardEntryDto} onSave={onSave} onClose={onClose} />
            ),
            [],
            true,
        ),
        "cardSet": new ResourceBo(
            cardSetService,
            requestId,
            { secret: { eq: false } },
            { name: "asc" },
            useState(undefined as CardSetDto | undefined),
            useState(1), useState(50),
            useState({ secret: { eq: false } } as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <CardSetItem id={item.props?.id ?? item.key} cardSet={item.props as CardSetDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardSetDisplay user={user} cardSet={dto as CardSetDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardSetEditor user={user} cardSet={dto as CardSetDto} preview={dto as CardSetDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "cardSetType": new ResourceBo(
            cardSetTypeService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as CardSetTypeDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <CardSetTypeItem id={item.props?.id ?? item.key} cardSetType={item.props as CardSetTypeDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardSetTypeDisplay user={user} cardSetType={dto as CardSetTypeDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CardSetTypeEditor user={user} cardSetType={dto as CardSetTypeDto} preview={dto as CardSetTypeDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "title": new ResourceBo(
            titleService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as TitleDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <TitleItem id={item.props?.id ?? item.key} title={item.props as TitleDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <TitleDisplay user={user} title={dto as TitleDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <TitleEditor user={user} title={dto as TitleDto} preview={dto as TitleDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "action": new ResourceBo(
            actionService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as ActionDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <ActionItem id={item.props?.id ?? item.key} action={item.props as ActionDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionDisplay user={user} action={dto as CardDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionEditor user={user} action={dto as ActionDto} preview={dto as ActionDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "actionType": new ResourceBo(
            actionTypeService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as ActionTypeDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <ActionTypeItem id={item.props?.id ?? item.key} actionType={item.props as ActionTypeDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionTypeDisplay user={user} actionType={dto as ActionTypeDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionTypeEditor user={user} actionType={dto as ActionTypeDto} preview={dto as ActionTypeDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "actionEffect": new ResourceBo(
            actionEffectService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as ActionEffectDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <ActionEffectItem id={item.props?.id ?? item.key} actionEffect={item.props as ActionEffectDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionEffectDisplay user={user} actionEffect={dto as ActionEffectDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ActionEffectEditor user={user} actionEffect={dto as ActionEffectDto} preview={dto as ActionEffectDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "creatureEffect": new ResourceBo(
            creatureEffectService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as CreatureEffectDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <CreatureEffectItem id={item.props?.id ?? item.key} creatureEffect={item.props as CreatureEffectDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CreatureEffectDisplay user={user} creatureEffect={dto as CreatureEffectDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <CreatureEffectEditor user={user} creatureEffect={dto as CreatureEffectDto} preview={dto as CreatureEffectDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "element": new ResourceBo(
            elementService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as ElementDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <ElementItem id={item.props?.id ?? item.key} element={item.props as ElementDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ElementDisplay user={user} element={dto as ElementDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ElementEditor user={user} element={dto as ElementDto} preview={dto as ElementDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "rarity": new ResourceBo(
            rarityService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as RarityDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <RarityItem id={item.props?.id ?? item.key} rarity={item.props as RarityDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <RarityDisplay user={user} rarity={dto as RarityDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <RarityEditor user={user} rarity={dto as RarityDto} preview={dto as RarityDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "artist": new ResourceBo(
            artistService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as ArtistDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <ArtistItem id={item.props?.id ?? item.key} artist={item.props as ArtistDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ArtistDisplay user={user} artist={dto as ArtistDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <ArtistEditor user={user} artist={dto as ArtistDto} preview={dto as ArtistDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "template": new ResourceBo(
            templateService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as TemplateDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <TemplateItem id={item.props?.id ?? item.key} template={item.props as TemplateDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <TemplateDisplay user={user} template={dto as TemplateDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <TemplateEditor user={user} template={dto as TemplateDto} preview={dto as TemplateDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "rule": new ResourceBo(
            ruleService,
            requestId,
            {},
            { position: "asc" },
            useState(undefined as RuleDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ position: "asc" } as Record<string, string | undefined>),
            item => (
                <button key={item.props?.id ?? item.key} onClick={() => item.action()}>{item.props?.name ?? "New Rule"}</button>
            ),
        ),
        "ruleCategory": new ResourceBo(
            ruleCategoryService,
            requestId,
            {},
            { position: "asc" },
            useState(undefined as RuleCategoryDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ position: "asc" } as Record<string, string | undefined>),
            item => (
                <button key={item.props?.id ?? item.key} onClick={() => item.action()}>{item.props?.name ?? "New Category"}</button>
            ),
        ),
        "deck": new ResourceBo(
            deckService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as DeckDto | undefined),
            useState(1), useState(50),
            useState({ userId: { eq: user?.id } } as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <DeckItem id={item.props?.id ?? item.key} user={user} deck={item.props as DeckDto} cardEntry={item.metadata?.cardEntry as CardEntryDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <DeckDisplay user={user} deck={dto as DeckDto} onSave={onSave} onClose={onClose} />
            ),
            (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
                <DeckEditor user={user} deck={dto as DeckDto} preview={dto as DeckDto} onSave={onSave} onClose={onClose} />
            ),
        ),
        "user": new ResourceBo(
            userService,
            requestId,
            {},
            { name: "asc" },
            useState(undefined as UserDto | undefined),
            useState(1), useState(50),
            useState({} as Record<string, any>),
            useState({ name: "asc" } as Record<string, string | undefined>),
            item => (
                <UserItem id={item.props?.id ?? item.key} user={item.props as UserDto} selected={item.selected ?? false} onClick={item.action} />
            ),
            // (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
            //     <UserDisplay user={user} onSave={onSave} onClose={onClose} />
            // ),
            // (dto, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => (
            //     <UserEditor user={user} userSubject={dto as UserDto} preview={dto as UserDto} onSave={onSave} onClose={onClose} />
            // ),
        ),
    };

    // Service Listeners:
    // for (const resourceName of Object.keys(resources)) {
    //     const resource: ResourceBo<any, any, any, any, any> = resources[resourceName];
    //     [resource.items, resource.pagination] = useServiceListener(resource.service, requestId, undefined, resource);
    // }
    [resources.card.items, resources.card.pagination] = useServiceListener(cardService, firstItem ? item => firstItem("card", item) : undefined, resources.card, undefined, resources.card.relationServices);
    [resources.cardType.items, resources.cardType.pagination] = useServiceListener(cardTypeService, firstItem ? item => firstItem("cardType", item) : undefined, resources.cardType, undefined, resources.cardType.relationServices);
    [resources.cardEntry.items, resources.cardEntry.pagination] = useServiceListener(cardEntryService, firstItem ? item => firstItem("cardEntry", item) : undefined, resources.cardEntry, undefined, resources.cardEntry.relationServices);
    [resources.cardSet.items, resources.cardSet.pagination] = useServiceListener(cardSetService, firstItem ? item => firstItem("cardSet", item) : undefined, resources.cardSet, undefined, resources.cardSet.relationServices);
    [resources.cardSetType.items, resources.cardSetType.pagination] = useServiceListener(cardSetTypeService, firstItem ? item => firstItem("cardSetType", item) : undefined, resources.cardSetType, undefined, resources.cardSetType.relationServices);
    [resources.title.items, resources.title.pagination] = useServiceListener(titleService, firstItem ? item => firstItem("title", item) : undefined, resources.title, undefined, resources.title.relationServices);
    [resources.action.items, resources.action.pagination] = useServiceListener(actionService, firstItem ? item => firstItem("action", item) : undefined, resources.action, undefined, resources.action.relationServices);
    [resources.actionType.items, resources.actionType.pagination] = useServiceListener(actionTypeService, firstItem ? item => firstItem("actionType", item) : undefined, resources.actionType, undefined, resources.actionType.relationServices);
    [resources.actionEffect.items, resources.actionEffect.pagination] = useServiceListener(actionEffectService, firstItem ? item => firstItem("actionEffect", item) : undefined, resources.actionEffect, undefined, resources.actionEffect.relationServices);
    [resources.creatureEffect.items, resources.creatureEffect.pagination] = useServiceListener(creatureEffectService, firstItem ? item => firstItem("creatureEffect", item) : undefined, resources.creatureEffect, undefined, resources.creatureEffect.relationServices);
    [resources.element.items, resources.element.pagination] = useServiceListener(elementService, firstItem ? item => firstItem("element", item) : undefined, resources.element, undefined, resources.element.relationServices);
    [resources.rarity.items, resources.rarity.pagination] = useServiceListener(rarityService, firstItem ? item => firstItem("rarity", item) : undefined, resources.rarity, undefined, resources.rarity.relationServices);
    [resources.artist.items, resources.artist.pagination] = useServiceListener(artistService, firstItem ? item => firstItem("artist", item) : undefined, resources.artist, undefined, resources.artist.relationServices);
    [resources.template.items, resources.template.pagination] = useServiceListener(templateService, firstItem ? item => firstItem("template", item) : undefined, resources.template, undefined, resources.template.relationServices);
    [resources.rule.items, resources.rule.pagination] = useServiceListener(ruleService, firstItem ? item => firstItem("rule", item) : undefined, resources.rule, undefined, resources.rule.relationServices);
    [resources.ruleCategory.items, resources.ruleCategory.pagination] = useServiceListener(ruleCategoryService, firstItem ? item => firstItem("ruleCategory", item) : undefined, resources.ruleCategory, undefined, resources.ruleCategory.relationServices);
    [resources.deck.items, resources.deck.pagination] = useServiceListener(deckService, firstItem ? item => firstItem("deck", item) : undefined, resources.deck, undefined, resources.deck.relationServices);
    [resources.user.items, resources.user.pagination] = useServiceListener(userService, firstItem ? item => firstItem("user", item) : undefined, resources.user, undefined, resources.user.relationServices);

    // Return Actions:
    return resources;
}