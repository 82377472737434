import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { DeckDto, DeckListDto, DeckPartialDto, DeckQueryDto, DeckRequestDto } from "dtos/shard/deck.dto";

export class DeckService extends AbstractResourceService<DeckDto, DeckRequestDto, DeckPartialDto, DeckListDto, DeckQueryDto>
{
    public deckDtos: Record<string, DeckDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "deck", "Deck", "Decks", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "description",
                name: "Description",
                type: "string",
            },
            {
                key: "private",
                name: "Private",
                type: "boolean",
            },
            // {
            //     key: "userId",
            //     name: "Creator",
            //     type: "list",
            //     resourceName: "user",
            //     admin: true,
            // },
        ]);
    }
}