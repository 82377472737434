import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { ElementDto, ElementListDto, ElementPartialDto, ElementQueryDto, ElementRequestDto } from "dtos/shard/element.dto";

export class ElementService extends AbstractResourceService<ElementDto, ElementRequestDto, ElementPartialDto, ElementListDto, ElementQueryDto>
{
    public elementDtos: Record<string, ElementDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "element", "Element", "Elements", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "color",
                name: "Color Hex",
                type: "string",
            },
        ]);
    }
}