import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Params, useParams } from "react-router-dom";

import variables from "styles/variables";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import CardView from "components/widgets/views/card-view";
import { UserState } from "states/user.state";
import displayStyles from "styles/display-styles";
import { cardEntryService, cardService } from "services";

const styles = createUseStyles({
    container: {
        padding: "4em",
        width: "calc(100vw - 8em)",
        height: "calc(100vh - 8em)",
        display: "flex",
        overflowX: "hidden",
        overflowY: "auto",
        flexDirection: "row",
        alignItems: "normal",
        justifyContent: "center",
        flexWrap: "wrap",

        backgroundImage: `
            linear-gradient(
                0deg,
                rgba(33, 18, 67, 0.5),
                rgba(33, 18, 67, 0.25),
                rgba(33, 18, 67, 0.5)
            ),
            url("/images/background.png")
        `,
        color: variables.colors.foreground,
        textShadow: `2px 2px #000000AA`,
    },
    bob: {
        animation: "$bob-animation",
        animationDuration: 20000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "ease-in-out",
        animationDirection:  "alternate",
        animationFillMode: "both",
    },
    "@keyframes bob-animation": {
        "0%": {
            backgroundPositionX: "-10vh",
            backgroundPositionY: "-5vh",
        },
        "25%": {
            backgroundPositionY: "5vh",
        },
        "50%": {
            backgroundPositionY: "-5vh",
        },
        "100%": {
            backgroundPositionX: "10vh",
            backgroundPositionY: "5vh",
        },
    },

    card: {
        display: "flex",
        height: "auto",
        width: "auto",
        flexDirection: "column",
        gap: "1em",

        flexGrow: 0,
    },
});

export default function Fullscreen(props: {
    userState?: UserState, // Optional dto of the user.
}): JSX.Element {
    // Router:
    const params: Params<string> = useParams();

    // States:
    const [cardEntries, setCardEntries] = useState([] as CardEntryDto[]);
    const [cards, setCards] = useState([] as CardDto[]);

    // Effects:
    useEffect(() => {
        const cardEntryIds: string[] = params.cardEntryIds ? params.cardEntryIds.split(",") : [];
        if (!cardEntryIds.length) {
            return;
        }

        cardEntryService.index({ filter: { id: { in: params.cardEntryIds } } });

        return cardEntryService.addListListener((event, listDto) => {
            setCardEntries(listDto.items);
        });
    }, [params.cardEntryIds]);

    useEffect(() => {
        const cardIds: string[] = cardEntries.filter(cardEntry => cardEntry.card).map(cardEntry => cardEntry.card!.id);
        cardService.index({ filter: { id: { in: cardIds.join(",") } } });

        return cardService.addListListener((event, listDto) => {
            setCards(listDto.items);
        });
    }, [cardEntries]);

    // Styles:
    const classes = styles();

    // Return Component:
    return (
        <div className={`${classes.container} ${classes.bob}`}>
            {cardEntries.map(cardEntry => {
                const card: CardDto | undefined = cards.find(card => cardEntry.card?.id === card.id);
                if (!card) {
                    return undefined;
                }
                return ( 
                    <div className={`${classes.card}`}>
                        <CardView user={props.userState?.dto} card={card} cardEntry={cardEntry} animation={true} />
                    </div>
                )
            })}
        </div>
    );
}