import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { deckService } from "services";
import { DeckCommon, DeckDto, DeckRequestDto } from "dtos/shard/deck.dto";
import { UserDto } from "dtos/user.dto";

import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
});

export default function DeckEditor(props: {
    user?: UserDto, // Optional dto of the user.
    deck?: Partial<DeckDto>,
    preview?: Partial<DeckDto>,
    onPreviewUpdate?: (previewDto: Partial<DeckDto>) => void,
    onClose?: () => void,
    onSave?:(deck: DeckDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: DeckCommon = {
        name: props.deck?.name ?? "",
        description: props.deck?.description ?? "",
        userId: props.deck?.userId ?? props.user?.id ?? "",
        private: props.deck?.private ?? true,
        coverCardEntryId: props.deck?.coverCardEntryId ?? "",
        deckEntries: props.deck?.deckEntries ?? [],
    };
    const freshRequestDto: DeckRequestDto = {
        id: props.deck?.id,
        ...freshCommon,
    };
    const freshPreviewDto: Partial<DeckDto> = props.preview ? structuredClone(props.preview) : props.deck ? structuredClone(props.deck) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "deck", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate);

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
    }, [props.deck]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Decks:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate(props.deck ?? previewDto);
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name ?? ""} onChange={onTextChange} />
            </div>

            <div className={`${widgetClasses.row}`}>
                <label>Private</label>
                <input id="private" type="checkbox" checked={requestDto.private ?? ""} onChange={onCheckboxChange} />
            </div>

            <h4>Description</h4>
            <textarea id="description" value={requestDto.description ?? ""} onChange={onTextChange} />

            <hr/>
            <EditorControls
                editorName="deck"
                service={deckService}
                subject={props.deck}
                subjectName={props.deck?.name ?? ""}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <hr/>
        </div>
    );
}