/**
 * Replaces a value in an array using a find function to find what to repalce and a subject to replace it with.
 * @param array The array to make the replacement in.
 * @param find The function to find the item to replace, if not found, nothing will be replaced.
 * @param subject The replacement item.
 */
export function arrayReplace<T>(array: T[], find: (item: T) => boolean, subject: T): void {
    const index: number = array.findIndex(find);
    if (index < 0) {
        return;
    }
    array[index] = subject;
}

/**
 * Replace all polyfill for ancient browers or certain virtual tablets in tabletop simulator!
 * @param subject The string to replace on.
 * @param target The snippet to replace.
 * @param replacement The replacement.
 */
export function replaceAll(subject: string, target: string, replacement: string): string {
    if (String.prototype.replaceAll === undefined) {
        return subject.split(target).join(replacement);
    }
    return subject.replaceAll(target, replacement);
}

/**
 * Returns if high quality styles and effects should be used.
 * @return True for high quality, false otherwise.
 */
export function highQuality(): boolean {
    return (localStorage.getItem("quality") ?? "High") === "High";
}

/**
 * Returns if the stats panel is open.
 * @return True if it is open.
 */
export function statsPanel(): boolean {
    return (localStorage.getItem("stats") ?? "closed") === "open";
}

/**
 * Toggles the stats panel and returns what is has toggled to.
 * @return True if it is open.
 */
export function toggleStatsPanel(): boolean {
    const open: boolean = !statsPanel();
    localStorage.setItem("stats", open ? "open" : "closed");
    return open;
}

/**
 * Returns if card anmiations are enabled.
 * @return True if card animations are enabled.
 */
export function cardAnimations(): boolean {
    return (localStorage.getItem("cardAnimations") ?? "enabled") === "enabled";
}

/**
 * Toggles card anmiations and returns what they have toggled to.
 * @return True if card animations are enabled.
 */
export function toggleCardAnimations(): boolean {
    const enabled: boolean = !cardAnimations();
    localStorage.setItem("cardAnimations", enabled ? "enabled" : "disabled");
    return enabled;
}