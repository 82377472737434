import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { useEffect } from "react";
import { removeNotification } from "components/widgets/notifications";
import { ErrorDto } from "dtos/error.dto";

const styles = createUseStyles<any, {
    actionColor: string,
    error: boolean,
}>({
    container: {
        width: "16em",
        height: "auto",
        padding: "1em",
        textAlign: "center",

        borderRadius: "0.25em",
        backgroundColor: props => (props.error ? variables.colors.negative : props.actionColor) + "AA",

        "& > span": {
            textTransform: "capitalize",
        },
    },
});

export default function ResourceNotification(props: {
    notificationId: string,
    resourceName: string,
    actionName: string,
    dto?: Record<string, any>,
    errorDto?: ErrorDto,
}): JSX.Element
{
    // Effects:
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            removeNotification(props.notificationId);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, []);

    // Properties:
    const displayResource: string = props.resourceName.replace(/([A-Z])/g, ' $1').trim();

    let displayName: string = props.dto?.name ?? "?";
    switch (props.resourceName) {
        case "cardEntry":
            displayName = `${props.dto?.cardSet?.code}-${props.dto?.reference?.toString().padStart(3, "0")}`;
            break;
    }

    // Action Property:
    let displayAction: string = "Updated";
    let actionColor: string = variables.colors.neutral;
    switch (props.actionName) {
        case "store":
            displayAction = "Created";
            actionColor = variables.colors.primary;
            break;
        case "destroy":
            displayAction = "Deleted";
            actionColor = variables.colors.secondary;
            break;
        case "render":
            displayAction = "Rendered";
            actionColor = variables.colors.positive;
            break;
        case "upload":
            displayAction = "Uploaded";
            actionColor = variables.colors.average;
            break;
    }

    let message: JSX.Element = <span>{displayResource}: {displayName} {displayAction}</span>;
    if (props.errorDto) {
        message = <>Error: <span>{displayResource}</span> was not <span>{displayAction}</span> - {props.errorDto.message.length < 100 ? props.errorDto.message : "Server error, check console."}</>;
    }

    // Component:
    const widgetClasses = widgets();
    const classes = styles({
        actionColor,
        error: !!props.errorDto,
    });
    return (
        <div className={`${classes.container}`} onClick={() => removeNotification(props.notificationId)}>
            {message}
        </div>
    );
}