import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { RarityDto } from "dtos/shard/rarity.dto";
import { replaceAll } from "utils";

const styles = createUseStyles({
    container: {
        display: "flex",
        minWidth: "20em",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    rarityWrapper: {
        position: "relative",
        width: "12em",
        height: "12em",
    },
    rarity: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        borderStyle: "outset",
        borderRadius: "50%",
        borderColor: variables.colorsLight.foreground,
        backgroundColor: variables.colors.neutral,

        "& img": {
            width: "8em",
        },
    },

    tilt: {
        animation: "$tilt-animation",
        animationDuration: 6000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "linear",
        animationDirection:  "normal",
        animationFillMode: "both",
    },
    "@keyframes tilt-animation": {
        "0%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(-10deg)
            `,
        },
        "13%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(-15deg)
            `,
        },
        "25%": {
            transform: `
                perspective(2000px)
                rotateX(10deg)
                rotateY(-10deg)
            `,
        },
        "38%": {
            transform: `
                perspective(2000px)
                rotateX(15deg)
                rotateY(0deg)
            `,
        },
        "50%": {
            transform: `
                perspective(2000px)
                rotateX(10deg)
                rotateY(10deg)
            `,
        },
        "63%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(15deg)
            `,
        },
        "75%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(10deg)
            `,
        },
        "88%": {
            transform: `
                perspective(2000px)
                rotateX(-15deg)
                rotateY(0deg)
            `,
        },
        "100%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(-10deg)
            `,
        },
    },
});

export default function RarityView(props: {
    rarity?: Partial<RarityDto>,
    animation?: boolean,
}): JSX.Element
{
    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Actions:
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.rarity?.name ?? "New Rarity"}</h3>
            <div
                onMouseMove={props.animation ? mouseMove : () => {}}
                onMouseLeave={props.animation ? mouseLeave : () => {}}
                className={`${classes.rarityWrapper} ${widgetClasses.mouseTilt}`}
            >
                <div className={`${classes.rarity} ${props.animation ? classes.tilt : ""}`}>
                    {props.rarity && <img src={`/images/card/rarities/${props.rarity.name ? replaceAll(props.rarity.name.toLowerCase(), " ", "_") : undefined}.svg`} />}
                </div>
            </div>
        </div>
    );
}