export type CardTemplate = {
    fullWidth: number,
    fullHeight: number,
    borderWidth: number,
    borderHeight: number,
    innerWidth: number,
    innerHeight: number,
    dividerHeight: number,

    detailsFrameHeight: number,
    detailsSpacing: number,
    detailsMargin: number,
    detailsTop: number,
    detailsBottom: number,

    iconHeight: number,
    elementBoxSize: number,
    titleOffset: number,
};

export const templates: Record<string, CardTemplate> = {};

// Creature Card:
const creature: CardTemplate = {
    fullWidth: 825,
    fullHeight: 1125,
    borderWidth: 78,
    borderHeight: 80,
    innerWidth: 0,
    innerHeight: 0,
    dividerHeight: 8,

    detailsFrameHeight: 120,
    detailsSpacing: 20,
    detailsMargin: 2,
    detailsTop: 0,
    detailsBottom: 0,

    iconHeight: 14,
    elementBoxSize: 100,
    titleOffset: 32,
};
creature.innerWidth = creature.fullWidth - (creature.borderWidth * 2);
creature.innerHeight = creature.fullHeight - (creature.borderHeight * 2);
creature.detailsBottom = creature.innerHeight - creature.detailsSpacing;
creature.detailsTop = creature.detailsBottom - (creature.detailsFrameHeight * 2) - (creature.dividerHeight * 4) - creature.detailsSpacing;
templates.creature = creature;

// Spell Card:
templates.spell = structuredClone(creature);