import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { v4 as uuid } from "uuid";

import { cardEntryService, cardService } from "services";
import { IResource } from "bos/resource.bo";
import { ListItem } from "types/list-item";
import { DeckDto, DeckEntryDto } from "dtos/shard/deck.dto";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import { useResources } from "hooks/resources.hook";
import widgets from "styles/widgets";
import Navigator from "components/widgets/navigator";
import List from "./list";
import { ResourceField } from "types/resource-field";

const styles = createUseStyles({
    header: {
        flexGrow: 0,
        alignItems: "center",

        "& button": {
            minWidth: "16em",
            flexGrow: 0,
            flexBasis: 1,
        },
    },

    list: {
        gap: "0.5em",
        overflowX: "hidden",
    },
    "@media screen and (max-width: 720px)": {
        list: {
            minHeight: "calc(100vh - 2em)",
        },
    },
});

export default function DeckEntryList(props: {
    deck: DeckDto,
    cardEntry?: CardEntryDto,
    onSelect?: (resourceName: string, dto: CardDto, secondaryDto?: CardEntryDto) => void, // An optional function to call when an item is selected, the resource name and dto are passed.
    onAdd?: (resourceName: string, dto: CardDto, secondaryDto?: CardEntryDto) => void, // An optional function to call when the add button is clicked on a list item, if undefined the add button is hidden.
    onRemove?: (resourceName: string, dto: CardDto, secondaryDto?: CardEntryDto) => void, // An optional function to call when the remove button is clicked on a list item, if undefined the remove button is hidden.
    onTts?: () => void,
}): JSX.Element
{
    // Resources:
    const resources: Record<string, IResource> = useResources();
    const cardResource: IResource = resources["card"];
    const cardEntryResource: IResource = resources["cardEntry"];

    // States:
    const [cardEntriesById, setCardEntriesById] = useState({} as Record<string, CardEntryDto>);
    const [cardsById, setCardsById] = useState({} as Record<string, CardDto>);
    const [sort, setSort] = useState({ name: "asc" } as Record<string, string>);

    // Effects:
    useEffect(() => {
        if (!props.deck.deckEntries.length) {
            return;
        }
        const requestId: string = uuid();

        // Fetch Card Entries:
        let cardEntryFilter: Record<string, any> = {
            id: {
                in: props.deck.deckEntries.map(deckEntryDto => deckEntryDto.cardEntryId).join(",")
            },
        };
        cardEntryService.index({
            limit: props.deck.deckEntries.length,
            filter: cardEntryFilter,
        }, requestId);
        const cardEntryListener = cardEntryService.addListListener((event, listDto) => {
            if (listDto.requestId === requestId) {
                const cardEntriesById: Record<string, CardEntryDto> = {};
                listDto.items.forEach(cardEntryDto => cardEntriesById[cardEntryDto.id] = cardEntryDto);
                setCardEntriesById(cardEntriesById);
            }
        });

        // Fetch Cards:
        let cardFilter: Record<string, any> = {
            id: {
                in: props.deck.deckEntries.map(deckEntryDto => deckEntryDto.cardId).join(",")
            },
        };
        cardService.index({
            limit: props.deck.deckEntries.length,
            filter: cardFilter,
        }, requestId);
        const cardListener = cardService.addListListener((event, listDto) => {
            if (listDto.requestId === requestId) {
                const cardsById: Record<string, CardDto> = {};
                listDto.items.forEach(cardDto => cardsById[cardDto.id] = cardDto);
                setCardsById(cardsById);
            }
        });

        // Remove Listeners:
        return () => {
            cardEntryListener();
            cardListener();
        };
    }, [props.deck, props.deck.deckEntries.length]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Properties:
    const getDeckEntryDtos = (deckEntryDto: DeckEntryDto): [cardDto: CardDto | undefined, cardEntryDto: CardEntryDto | undefined] => {
        const cardDto: CardDto | undefined = cardsById[deckEntryDto.cardId];
        const cardEntryDto: CardEntryDto | undefined = cardEntriesById[deckEntryDto.cardEntryId];
        return [cardDto, cardEntryDto];
    };

    // Sort Deck Entries:
    let sortedDeckEntries: DeckEntryDto[] = props.deck.deckEntries;
    Object.keys(sort).forEach(fieldName => {
        const field: ResourceField | undefined = cardService.fields.find(field => field.key === fieldName);
        if (!field) {
            return;
        }
        const key: string = field.type === "list" && field.resourceName ? (field.resourceName ?? field.key) : field.key;
        const descending: boolean = sort[fieldName] === "desc";
        sortedDeckEntries = sortedDeckEntries.sort((a, b) => {
            const [cardDtoA, cardEntryDtoA] = getDeckEntryDtos(a) as [Record<string, any>, Record<string, any>];
            if (!cardDtoA || !cardDtoA[key]) {
                return descending ? 1 : -1;
            }
            const [cardDtoB, cardEntryDtoB] = getDeckEntryDtos(b) as [Record<string, any>, Record<string, any>];
            if (!cardDtoB || !cardDtoB[key]) {
                return descending ? -1 : 1;
            }
            switch (field.type) {
                case "string":
                    return descending ? cardDtoB[key].localeCompare(cardDtoA[key]) : cardDtoA[key].localeCompare(cardDtoB[key]);
                case "list":
                    return descending ? cardDtoB[key].name.localeCompare(cardDtoA[key].name) : cardDtoA[key].name.localeCompare(cardDtoB[key].name);
                default:
                    return descending ? cardDtoB[key] - cardDtoA[key] : cardDtoA[key] - cardDtoB[key];
            }
        });
    });

    // List Items:
    const deckEntryItems: ListItem[] = sortedDeckEntries.map(deckEntryDto => {
        const [cardDto, cardEntryDto] = getDeckEntryDtos(deckEntryDto);
        if (!cardEntryDto || !cardDto) {
            return undefined;
        }
        const displayName: string = [cardDto.subspecies, cardDto.name, cardDto.morph].join(" ");
        const displayCode: string = `${cardEntryDto.cardSet.code}-${cardEntryDto.reference.toString().padStart(3, "0")}`;
        return {
            key: deckEntryDto.cardEntryId,
            name: `${displayName} ${displayCode}`,
            selected: false,
            count: deckEntryDto.count,
            props: cardDto,
            metadata: { cardEntry: cardEntryDto },
            action: () => props.onSelect ? props.onSelect("card", cardDto, cardEntryDto) : undefined,
            add: () => props.onAdd ? props.onAdd("card", cardDto, cardEntryDto) : undefined,
            remove: () => props.onRemove ? props.onRemove("card", cardDto, cardEntryDto) : undefined,
            componentBuilder: cardResource.itemBuilder,
        };
    })
    .filter(listItem => listItem) as ListItem[];

    // Actions:
    const setSortField = (field: string, direction: string) => {
        const newSort = structuredClone(sort);
        if (newSort[field] === direction) {
            delete newSort[field];
        } else {
            newSort[field] = direction;
        }
        setSort(newSort);
    };

    // Return Component:
    return (
        <div className={`${widgetClasses.column} ${widgetClasses.gap}`}>
            <div className={`${widgetClasses.panel}`}>
                <Navigator
                    resource={cardResource}
                    fields={cardResource.service.fields}
                    sort={sort}
                    // filter={{}} // TODO Implement Deck Entry Filter
                    // setFilter={() => {}}
                    setSort={setSortField}
                    reset={() => setSort({ name: "asc" })}
                    onTts={props.onTts}
                />
            </div>
            <div className={`${widgetClasses.grid} ${widgetClasses.background} ${classes.list}`}>
                <List
                    items={deckEntryItems}
                    pseudoBuilder={cardResource.itemBuilder}
                />
            </div>
        </div>
    );
}