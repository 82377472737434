import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CardTypeDto, CardTypeListDto, CardTypePartialDto, CardTypeQueryDto, CardTypeRequestDto } from "dtos/shard/card-type.dto";

export class CardTypeService extends AbstractResourceService<CardTypeDto, CardTypeRequestDto, CardTypePartialDto, CardTypeListDto, CardTypeQueryDto>
{
    public cardTypeDtos: Record<string, CardTypeDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "cardType", "Card Type", "Card Types", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "templateId",
                name: "Template",
                type: "list",
                resourceName: "template",
            },
        ]);
    }
}