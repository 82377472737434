import { createUseStyles } from "react-jss";

import { TemplateDto } from "dtos/shard/template.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
    template: {
        textTransform: "capitalize",
    },
});

export default function TemplateView(props: {
    template?: Partial<TemplateDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3 className={`${classes.template}`}>{props.template?.name ?? "New Template"}</h3>
        </div>
    );
}