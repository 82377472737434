import axios, { AxiosInstance } from "axios";
import { ErrorDto } from "dtos/error.dto";
import { Socket } from "socket.io-client"

export abstract class AbstractService<DtoType>
{
    public readonly listeners: ((event: string, data: DtoType) => void)[] = [];
    public readonly errorListeners: ((event: string, data: ErrorDto) => void)[] = [];
    public readonly socket: Socket;
    public readonly api: AxiosInstance;

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        this.socket = socket;
        this.api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL + "/api/v1" ?? "",
        });
    }

    /**
     * Creates an event where a socket event is listened for and then sent to event listeners.
     * @param event The name of the socket event.
     */
    public createEvent(event: string): void
    {
        this.socket.on(event, (dto: DtoType) => {
            this.listeners.forEach(listener => listener(event, dto));
        });
        this.socket.on(event + ".error", (errorDto: ErrorDto) => {
            console.error(`[Service] [${event + ".error"}] received:`, errorDto);
            this.errorListeners.forEach(listener => listener(event + ".error", errorDto));
        });
    }

    /**
     * Adds an event listener.
     * @param listener The event listener callback function to call.
     * @param errorListener An optional error listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addListener(listener: (event: string, dto: DtoType) => void, errorListener?: (event: string, dto: ErrorDto) => void): () => void
    {
        // Add Listener:
        this.listeners.push(listener);

        // Add Error Listener:
        if (errorListener) {
            this.errorListeners.push(errorListener);
        }

        // Remove Listeners:
        return () => {
            const index: number = this.listeners.indexOf(listener);
            if (index >= 0) {
                this.listeners.splice(index, 1);
            }

            // Remove Error Listener:
            if (errorListener) {
                const errorIndex: number = this.errorListeners.indexOf(errorListener);
                if (errorIndex >= 0) {
                    this.errorListeners.splice(errorIndex, 1);
                }
            }
        };
    }
}