import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { ElementDto } from "dtos/shard/element.dto";

const styles = createUseStyles<any, {
    element?: ElementDto,
    selected: boolean,
}>({
    tile: {
        borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.element ? props.element.color + "77" : variables.colorsLight.foreground),
        backgroundColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.element ? props.element.color + "77" : variables.colors.neutral + "77"),

        "&:hover": {
            backgroundColor: (props) => props.selected ? variables.colorsLight.average + "AA" : (props.element ? props.element.color + "AA" : variables.colors.hover + "77"),
        },
    },
});

export default function ElementItem(props: {
    id: string,
    element?: ElementDto,
    selected: boolean,
    onClick?: (element?: ElementDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.element);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const classes = styles({
        element: props.element,
        selected: props.selected,
    });
    const listItemClasses = listItemStyles({
        create: !!props.element,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.element ? (<>
                    <div className={`${listItemClasses.title}`}>
                        <img src={`/images/card/elements/${props.element.name.toLowerCase()}.svg`} />
                        <span>{props.element.name}</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Element</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}