import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import { useEffect, useState } from "react";
import { imagesService } from "services";
import { StatusDto } from "dtos/status.dto";
import { UserDto } from "dtos/user.dto";

const styles = createUseStyles<any, {
    card?: CardDto,
    imageUrl: string,
}>({
    tile: {
        flexDirection: "column",
        justifyContent: "space-between",

        borderColor: (props) => props.card ? props.card.element.color : variables.colorsLight.foreground,
        backgroundColor: (props) => props.card ? props.card.element.color : variables.colorsDark.background,
        backgroundImage: props => props.imageUrl,
    },
    bar: {
        display: "flex",
        width: "100%",
        height: "3em",

        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",

        backgroundColor: (props) => props.card ? props.card.element.color + "77" : variables.colorsDark.background + "77",

        "& > img": {
            width: "2em",
            height: "auto",
        },
    },
    attributes: {
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
    },
    costs: {
        position: "absolute",
        display: "flex",
        left: "0.5em",
        top: 0,
        bottom: 0,

        flexDirection: "column",
        justifyContent: "center",
        gap: "0.25em",

        "& > img": {
            width: "1em",
            height: "auto",
        },
    },
});

export default function CardItem(props: {
    id: string,
    user?: UserDto,
    card?: CardDto,
    selected: boolean,
    onClick?: (card?: CardDto) => void,
}): JSX.Element
{
    // Properties:
    const displayName: string = props.card ? [props.card.subspecies, props.card.name, props.card.morph].join(" ") : "Create New Card";
    let displayCode: string | undefined;
    if (props.card?.cardEntries.length) {
        const cardEntry: CardEntryDto = props.card.cardEntries[props.card.cardEntries.length - 1];
        displayCode = `${cardEntry.cardSet.code}-${cardEntry.reference.toString().padStart(3, "0")}`;
    }

    // States:
    const [refresh, setRefresh] = useState("");

    // Effects:
    useEffect(() => imagesService.addListener((event, dto) => {
        if (displayCode && dto && (dto as StatusDto).name === displayCode) {
            setRefresh(dto.requestId ?? "");
        }
    }), []);

    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.card);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const imageUrl: string = displayCode ? `url(${process.env.REACT_APP_IMAGES_URL}/thumbnail/${displayCode}?refresh=${refresh}${props.user?.socketId ? "&socketId=" + props.user.socketId : ""})` : "url(/images/card/back_small.png)";
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.card,
        selected: props.selected,
    });
    const classes = styles({
        card: props.card,
        imageUrl,
    });

    // Components:
    const costComps: JSX.Element[] = [];
    if (props.card) {
        for (let i: number = 0; i < props.card.shardCost; i++) {
            costComps.push((<img key={i} src="/images/card/shard.png" />));
        }
        const offset: number = costComps.length;
        for (let i: number = 0; i < props.card.manaCost; i++) {
            costComps.push((<img key={offset + i} src="/images/card/mana.png" />));
        }
    }
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container} ${listItemClasses.card}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.card ? (<>
                    <div className={`${classes.bar} ${listItemClasses.title}`}>
                        <span>{displayName}</span>
                    </div>
                    <div className={`${classes.bar}`}>
                        <img src={`/images/card/elements/${props.card.element.name.toLowerCase()}.svg`} />
                        <div className={`${classes.attributes}`}>
                            <span>{props.card.cardType.name}</span>
                            <span>{props.card.cardType.template.name.replace(props.card.cardType.template.name[0], props.card.cardType.template.name[0].toUpperCase())}</span>
                        </div>
                    </div>
                    <div className={`${classes.costs}`}>
                        {costComps}
                    </div>
                </>) : (<>
                    <div className={`${classes.bar}`}>
                        <span>{displayName}</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}