import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import { useEffect, useState } from "react";
import { imagesService } from "services";
import { StatusDto } from "dtos/status.dto";
import { UserDto } from "dtos/user.dto";
import { replaceAll } from "utils";

const styles = createUseStyles<any, {
    cardEntry?: CardEntryDto,
    imageUrl: string,
}>({
    tile: {
        flexDirection: "column",
        justifyContent: "space-between",
        
        backgroundImage: props => props.imageUrl,
    },
    bar: {
        display: "flex",
        width: "100%",
        height: "3em",

        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",

        backgroundColor: variables.colorsDark.background + "77",

        "& > img": {
            width: "2em",
            height: "auto",
        },
    },
    attributes: {
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
    },
    costs: {
        position: "absolute",
        display: "flex",
        left: "0.5em",
        top: 0,
        bottom: 0,

        flexDirection: "column",
        justifyContent: "center",
        gap: "0.25em",

        "& > img": {
            width: "1em",
            height: "auto",
        },
    },
});

export default function CardEntryItem(props: {
    id: string,
    user?: UserDto,
    cardEntry?: CardEntryDto,
    selected: boolean,
    onClick?: (cardEntry?: CardEntryDto) => void,
}): JSX.Element
{
    // Properties:
    const displayCode: string | undefined = props.cardEntry ? `${props.cardEntry.cardSet.code}-${props.cardEntry.reference.toString().padStart(3, "0")}` : undefined;
    const cardDisplayName: string = props.cardEntry ? [props.cardEntry.card?.subspecies, props.cardEntry.card?.name, props.cardEntry.card?.morph].join(" ") : "Create New Card Entry";

    // States:
    const [refresh, setRefresh] = useState("init");

    // Effects:
    useEffect(() => imagesService.addListener((event, dto) => {
        if (displayCode && dto && (dto as StatusDto).name === displayCode) {
            setRefresh(dto.requestId ?? "init");
        }
    }), []);

    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.cardEntry);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const imageUrl: string = displayCode ? `url(${process.env.REACT_APP_IMAGES_URL}/thumbnail/${displayCode}?refresh=${refresh}${props.user?.socketId ? "&socketId=" + props.user.socketId : ""})` : "url(/images/card/back_small.png)";
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.cardEntry,
        selected: props.selected,
    });
    const classes = styles({
        cardEntry: props.cardEntry,
        imageUrl,
    });

    // Components:
    const costComps: JSX.Element[] = [];
    if (props.cardEntry?.card) {
        for (let i: number = 0; i < props.cardEntry.card.shardCost; i++) {
            costComps.push((<img key={i} src="/images/card/shard.png" />));
        }
        const offset: number = costComps.length;
        for (let i: number = 0; i < props.cardEntry.card.manaCost; i++) {
            costComps.push((<img key={offset + i} src="/images/card/mana.png" />));
        }
    }
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container} ${listItemClasses.card}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.cardEntry ? (<>
                    <div className={`${classes.bar} ${listItemClasses.title}`}>
                        <span>{displayCode}</span>
                    </div>
                    <div className={`${classes.bar}`}>
                        <img src={`/images/card/rarities/${replaceAll(props.cardEntry.rarity.name.toLowerCase(), " ", "_")}.svg`} />
                        <div className={`${classes.attributes}`}>
                            <span>{cardDisplayName}</span>
                            <span>{props.cardEntry.cardSet.name}</span>
                        </div>
                    </div>
                    <div className={`${classes.costs}`}>
                        {costComps}
                    </div>
                </>) : (<>
                    <div className={`${classes.bar} ${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Card Entry</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}