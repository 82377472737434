import { createUseStyles } from "react-jss";

import { loginService, userService } from "services";
import variables from "styles/variables";
import widgets from "styles/widgets";

import { addPopup, removePopup } from "components/widgets/popups";
import { UserState } from "states/user.state";
import { Link } from "react-router-dom";
import Login from "components/popups/login";
import { quality, setQuality } from "components/app";
import { highQuality } from "utils";


const styles = createUseStyles<any, any>({
    container: {
        padding: "0 4em",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "3em",

        ...variables.box(),

        "& h1, & h2, & h3": {
            margin: 0,
        },
        "& a": {
            fontSize: "14pt",
            fontWeight: "bold",
        },
        "& a h1": {
            fontSize: "24pt",
            color: variables.colors.foreground,
            textStrokeWidth: highQuality() ? 1 : 0,
            textStrokeColor: variables.colors.neutral,

            "&:hover": {
                color: variables.colorsLight.foreground,
                textStrokeColor: variables.colors.average,
            },
        },
    },
    group: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.2em",
    },
    links: {
        flexGrow: 1,
        alignItems: "left",
        justifyContent: "start",

        "& > a": {
            color: variables.colorsLight.foreground,
            textStrokeWidth: highQuality() ? 1 : 0,
            textStrokeColor: variables.colors.neutral,

            "&:hover": {
                color: variables.colorsLight.foreground,
                textStrokeColor: variables.colors.hover,
            },
        },
    },
    logo: {
        width: "5em",
        height: "5em",
        margin: "-0.5em",
        zIndex: 10,
        backgroundImage: "url(/icon.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        transform: "translateY(16px)",
    },

    "@media screen and (max-width: 720px)": {
        logo: {
            width: "2em",
            height: "2em",
            margin: "0em",
            transform: "translateY(0)",
        },
        container: {
            paddingTop: "1em",
            gap: "1em",

            "& a h1": {
                fontSize: "20pt",
            },
        },
    },

    glow: {
        animation: highQuality() ? "$glow-animation" : "none",
        animationDuration: 1000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "ease-in-out",
        animationDirection:  "alternate",
        animationFillMode: "both",
    },
    "@keyframes glow-animation": {
        from: {
            filter: `
                brightness(1)
                contrast(1.25)
                saturate(1)
            `,
        },
        to: {
            filter: `
                brightness(1.25)
                contrast(1)
                saturate(1.25)
            `,
        },
    },
});

export default function Header(props: {
    user: UserState,
}): JSX.Element
{
    // Actions:
    const loginToggle = () => {
        if (removePopup("login")) {
            return;
        }
        addPopup("login", (<Login />));
    };
    const logout = () => {
        loginService.logout();
        userService.clearUser();
    };
    const toggleQuality = () => {
        setQuality(quality === "High" ? "Low" : "High");
    };

    // Styles:
    const classes = styles();
    const widgetClasses = widgets();

    // User Component:
    let userComp: JSX.Element = (<div className={`${classes.group}`}>
        <h3>Guest</h3>
        <button className={`${widgetClasses.confirm}`} onClick={loginToggle}>Login</button>
    </div>);
    if (props.user.dto) {
        userComp = (<div className={`${classes.group}`}>
            <h3>{props.user.dto.admin ? "📛" : ""}{props.user.dto.username}</h3>
            <button className={`${widgetClasses.warning}`} onClick={logout}>Logout</button>
        </div>);
    }

    // Component:
    return (
        <nav className={`${classes.container}`}>
            <Link to="/">
                <div className={`${classes.group}`}>
                    <div title="Nephrite Logo" className={`${classes.logo} ${classes.glow}`} />
                    <h1>Shard TCG</h1>
                </div>
            </Link>
            <div className={`${classes.group} ${classes.links}`}>
                <Link to="/rules">Rules</Link>
                <Link to="/cards">Cards</Link>
                <Link to="/decks">Deck Builder</Link>
                <Link to="/store">Store</Link>
            </div>
            <div className={`${classes.group}`}>
                {userComp}
                <button onClick={() => toggleQuality()}>Quality: {quality}</button>
            </div>
        </nav>
    );
}