import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";
import { UserDto } from "dtos/user.dto";
import { addPopup, removePopup } from "../popups";
import { useResources } from "hooks/resources.hook";
import { ListItem } from "types/list-item";
import { IResource } from "bos/resource.bo";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import List from "../list";
import CardView from "../views/card-view";
import CardEditor from "../editors/card-editor";
import displayStyles from "styles/display-styles";
import { cardAnimations, toggleCardAnimations, statsPanel, toggleStatsPanel } from "utils";

const styles = createUseStyles({
    card: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: "1em",

        flexGrow: 1,
    },
    lore: {
        width: "100%",
        height: "25%",
        overflowY: "auto",

        flexGrow: 1,
        flexBasis: 1,
    },
    entries: {
        display: "flex",
        width: "100%",
        flexDirection: "column",

        flexGrow: 2,
        flexBasis: 2,
    },
    entryList: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "1em",
        overflowY: "auto",
        overflowX: "hidden",
    },

    "@media screen and (max-width: 1280px)": {
        card: {
            "& > div": {
                height: "100vh",
            },
            flexGrow: 1,
        },
    },
});

export default function CardDisplay(props: {
    user?: UserDto, // Optional dto of the user.
    className?: string, // Optional css class name to use.
    card?: Partial<CardDto>, // The card dto to display, if undefined a blank card will be displayed instead.
    cardEntry?: CardEntryDto, // The card entry to display, defaults to the first entry of the card dto, if any.
    stats?: boolean, // If true or false, stats are displayed/hidden with the button hidden, if undefined the global stats state is used instead.
    onSave?: (card: CardDto) => void, // A function to call when created or updated.
    onClose?: () => void, // A function to call when the close button is clicked, if not set the close button is hidden.
}): JSX.Element {
    const navigate = useNavigate();

    // Resources:
    const resources: Record<string, IResource> = useResources(props.user);
    const cardEntryResource: IResource = resources["cardEntry"];
    const firstCardEntry: CardEntryDto | undefined = props.cardEntry ?? (props.card?.cardEntries ? props.card?.cardEntries[0] : undefined);

    // States:
    const [stats, setStats] = useState(statsPanel());
    const [animations, setAnimations] = useState(cardAnimations());
    const [cardPreview, setCardPreview] = useState(props.card);
    const [cardEntry, setCardEntry] = useState(firstCardEntry);

    // Effects:
    useEffect(() => {
        setCardPreview(props.card);
        setCardEntry(firstCardEntry);
    }, [props.card]);

    // Actions:
    const onCreateCardEntry = () => {
        if (!cardEntryResource.editorBuilder) {
            return;
        }
        const popupName: string = `card-display-editor-cardEntry-create`;
        addPopup(popupName, (
            <div className={`${widgetClasses.popup} ${widgetClasses.popupLarge}`}>
                {cardEntryResource.editorBuilder(undefined, () => removePopup(popupName), () => removePopup(popupName))}
            </div>
        ));
    };
    const downloadImage = async (type: string) => {
        if (!cardEntry || !cardEntry.cardSet) {
            console.log(`[Card Display] Cannot download due to missing Card Entry.`);
            return;
        }
        const displayCode: string = `${cardEntry.cardSet.code}-${cardEntry.reference.toString().padStart(3, "0")}`;
        const url: string = `${process.env.REACT_APP_IMAGES_URL}/${type}/${displayCode}`;
        console.log(`[Card Display] Downloading: ${url}`);
        const imageBuffer: Buffer = await axios.get(url, {
            responseType: "arraybuffer",
        }).then(response => response.data);
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([imageBuffer]));
        link.download = `${displayCode}-${type}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const fullscreen = () => {
        if (!cardEntry) {
            return;
        }
        console.log(`[Card Display] Navigating to fullscreen display...`);
        navigate(`/fullscreen/${cardEntry.id}`);
    }

    // Styles:
    const widgetClasses = widgets();
    const displayClasses = displayStyles();
    const classes = styles();

    // Components:
    let statsComp: JSX.Element | undefined;
    if (props.stats ?? stats) {
        // Card Entry List:
        let cardEntryListComp: JSX.Element | undefined;
        if (props.card?.id) {
            let items: ListItem[] = props.card?.cardEntries?.map(dto => {
                const popupName: string = `card-display-editor-cardEntry-${dto.id}`;
                return ({
                    key: dto.id,
                    name: dto.reference.toString(),
                    selected: dto.id === cardEntry?.id,
                    props: dto,
                    action: () => setCardEntry(dto),
                    edit: () => cardEntryResource.editorBuilder ? addPopup(popupName, (
                        <div className={`${widgetClasses.popup} ${widgetClasses.popupLarge}`}>
                            {cardEntryResource.editorBuilder(dto, () => removePopup(popupName), () => removePopup(popupName))}
                        </div>
                    )) : undefined,
                    componentBuilder: cardEntryResource.itemBuilder,
                })
            }) ?? [];
            cardEntryListComp = (
                <div className={`${classes.entries}`}>
                    <h3>Entries:</h3>
                    <div className={`${classes.entryList}`}>
                        <List
                            items={items}
                            onCreate={props.user?.admin ? onCreateCardEntry : undefined}
                            pseudoBuilder={cardEntryResource.itemBuilder}
                        />
                    </div>
                </div>
            );
        }

        // Card Stats (Editor for Admins):
        if (props.user?.admin) {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${displayClasses.editor}`}>
                        <h3>Editor:</h3>
                        <CardEditor
                            user={props.user}
                            card={props.card}
                            preview={cardPreview}
                            onPreviewUpdate={setCardPreview}
                            onSave={props.onSave}
                            onClose={props.onClose}
                        />
                    </div>
                    {cardEntryListComp}
                </div>
            );
        } else {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${classes.lore}`}>
                        <h3>Lore:</h3>
                        {props.card?.lore ?? "No lore entry has been written for this Card yet."}
                    </div>
                    {cardEntryListComp}
                </div>
            );
        }
    }
    
    // Return Component:
    return (
        <div className={`${displayClasses.container} ${props.className ?? ""}`}>
            <div className={`${displayClasses.controls}`}>
                <div className={`${displayClasses.button} ${cardAnimations() ? displayClasses.buttonActive : ""}`} onClick={() => setAnimations(toggleCardAnimations())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconAnimation}`}></div>
                </div>
                <div className={`${displayClasses.button} ${cardEntry ? "" : displayClasses.buttonDisabled}`} onClick={fullscreen}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconFullscreen}`}></div>
                </div>
                { props.stats === undefined && (
                    <div className={`${displayClasses.button} ${statsPanel() ? displayClasses.buttonActive : ""}`} onClick={() => setStats(toggleStatsPanel())}>
                        <div className={`${displayClasses.icon} ${displayClasses.iconStats}`}></div>
                    </div>
                )}
                <div className={`${displayClasses.button}`} onClick={() => downloadImage("full")}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconDownload}`}></div>
                </div>
                <div className={`${displayClasses.button}`} onClick={() => downloadImage("cutout")}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconCutout}`}></div>
                </div>
                {props.onClose && <div className={`${displayClasses.button}`} onClick={() => props.onClose!()}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconClose}`}></div>
                </div>}
            </div>
            <div className={`${displayClasses.view}`}>
                <div className={`${classes.card}`}>
                    <CardView user={props.user} card={cardPreview} cardEntry={cardEntry} animation={animations} />
                </div>
                {statsComp}
            </div>
        </div>
    );
}