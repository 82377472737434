import { createUseStyles } from "react-jss";

import { CardTypeDto } from "dtos/shard/card-type.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
    template: {
        textTransform: "capitalize",
    },
});

export default function CardTypeView(props: {
    cardType?: Partial<CardTypeDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.cardType?.name ?? "New Card Type"}</h3>
            <p className={`${classes.template}`}>{props.cardType?.template?.name ?? "New"} Type</p>
        </div>
    );
}