import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { titleService } from "services";
import { TitleCommon, TitleDto, TitleRequestDto } from "dtos/shard/title.dto";
import { UserDto } from "dtos/user.dto";

import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
});

export default function TitleEditor(props: {
    user?: UserDto, // Optional dto of the user.
    title?: Partial<TitleDto>,
    preview?: Partial<TitleDto>,
    onPreviewUpdate?: (previewDto: Partial<TitleDto>) => void,
    onClose?: () => void,
    onSave?:(title: TitleDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: TitleCommon = {
        name: props.title?.name ?? "",
    };
    const freshRequestDto: TitleRequestDto = {
        id: props.title?.id,
        ...freshCommon,
        templateId: props.title?.template?.id ?? "",
    };
    const freshPreviewDto: Partial<TitleDto> = props.preview ? structuredClone(props.preview) : props.title ? structuredClone(props.title) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "title", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate);

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
    }, [props.title]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Titles:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate(props.title ?? previewDto);
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name ?? ""} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row} ${widgetClasses.capitalize}`}>
                <button id="template" onClick={() => onPopup("template", "templateId", true)}>Set Type: {previewDto.template?.name ?? "Please Select"}</button>
            </div>

            <hr/>
            <EditorControls
                editorName="title"
                service={titleService}
                subject={props.title}
                subjectName={props.title?.name ?? ""}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <hr/>
        </div>
    );
}