import { ChangeEvent, useEffect, useState } from "react";

import { removePopup } from "components/widgets/popups";
import { loginService } from "services";
import widgets from "styles/widgets";

export default function Login(): JSX.Element
{
    // State:
    const [state, setState] = useState({
        view: "login",
        username: localStorage.getItem("username") ?? "",
        email: "",
        password: "",
        confirm: "",
        code: "",
        error: "",
    });

    // Set View
    const setView = (view: string, error?: string) => {
        setState({
            ...state,
            view,
            error: error ?? "",
        });
    };

    // Effects:
    useEffect(() => {
        // Login:
        const removeLoginListener = loginService.addListener((event, dto) => removePopup("login"));

        // Error Handling:
        const removeErrorListener = loginService.addErrorListener((event, dto) => {
            switch (event) {
                case "login.error":
                    setView("login_error", dto.message);
                    break;
                case "register.error":
                    setView("register_error", dto.message);
                    break;
                case "password.reset.error":
                    setView("reset_password_error", dto.message);
                    break;
            }
        });
        return () => {
            removeLoginListener();
            removeErrorListener();
        }
    });

    // Input Handling:
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.id]: event.target.value,
        });
    };

    // Actions:
    const login = () => {
        setView("logging_in");
        loginService.login({
            grant_type: "password",
            client_id: process.env.REACT_APP_ACCOUNTS_ID ?? "",
            client_secret: process.env.REACT_APP_ACCOUNTS_SECRET ?? "",
            username: state.username,
            password: state.password,
        });
    };

    const register = () => {
        window.open(process.env.REACT_APP_PORTAL_URL + "/register", "_blank")?.focus();
    };

    // Component:
    const widgetClasses = widgets();
    let component;
    switch (state.view) {
        case "logging_in":
            component = (<>
                <h1>Logging In</h1>
                <p>Summoning soul from a nearest schism rift...</p>
            </>);
            break;

        case "login_error":
            component = (<>
                <h1>Loggin Error</h1>
                <p>An error occurred while logging in: {state.error}</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("login")}>
                        Retry
                    </button>
                </div>
            </>);
            break;

        default:
            component = (<>
                <div className={widgetClasses.row}>
                    <h1>Login</h1>
                </div>

                <div className={widgetClasses.row}>
                    <label>Username</label>
                    <input id="username" value={state.username} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Password</label>
                    <input id="password" value={state.password} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={login}
                        disabled={!state.username || !state.password}
                    >
                        Login with Nephrite
                    </button>
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.info} onClick={register}>
                        Register with Nephrite
                    </button>
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.warning} onClick={() => removePopup("login")}>
                        Close
                    </button>
                </div>
            </>);
    }

    return (
        <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
            {component}
        </div>
    );
}