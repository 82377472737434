import { ChangeEvent } from "react";

import widgets from "styles/widgets";
import { addPopup, removePopup } from "components/widgets/popups";
import { IResource } from "bos/resource.bo";

import ResourceSelector from "components/widgets/resource-selector";
import { UserDto } from "dtos/user.dto";

/**
 * Creates a range of actions for editors to use.
 * @returns A range of editor action functions.
 */
export function useEditorActions<DtoType, RequestDtoType>(
    user: UserDto | undefined, // Optional dto of the user.
    editorName: string,
    resources: Record<string, IResource>,
    requestDto: RequestDtoType,
    previewDto: Partial<DtoType>,
    setRequestDto: (requestDto: RequestDtoType) => void,
    onPreviewUpdate?: (previewDto: Partial<DtoType>) => void,
    onPropertyChange?: (field: string, value: any) => void,
): [
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    (event: ChangeEvent<HTMLInputElement>) => void,
    (id: string, value: string) => void,
    (event: ChangeEvent<HTMLInputElement>) => void,
    (resourceName: string, id: string, required: boolean, index?: number) => void,
    (resourceName: string, dto: Record<string, any>) => void,
] {
    // Styles:
    const widgetClasses = widgets();

    // Properties:
    const setProperty = (field: string, value: any, index?: number, previewField?: string) => {
        const previewValue: any = value;
        if (value && typeof value === "object") {
            value = value.id;
        }
        if (previewField === undefined) {
            previewField = field;
        }
        const requestDtoPlain = structuredClone(requestDto) as Record<string, any>;
        const previewDtoPlain = previewDto as Record<string, any>;

        if (index === undefined) {
            requestDtoPlain[field] = value;
            previewDtoPlain[previewField] = previewValue;
        } else {
            if (!requestDtoPlain[field]) {
                requestDtoPlain[field] = [];
            }
            requestDtoPlain[field][index] = value;
            if (!previewDtoPlain[previewField]) {
                previewDtoPlain[previewField] = [];
            }
            previewDtoPlain[previewField][index] = previewValue;
            console.log(requestDtoPlain[field]);
        }

        setRequestDto(requestDtoPlain as RequestDtoType);
        if (onPreviewUpdate) {
            onPreviewUpdate(previewDtoPlain as Partial<DtoType>);
        }

        if (onPropertyChange) {
            onPropertyChange(field, previewValue);
        }
    };

    const onTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProperty(event.target.id, event.target.value);
    };
    const onNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProperty(event.target.id, parseInt(event.target.value));
    };
    const onRteChange = (id: string, value: string) => {
        setProperty(id, value);
    };
    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProperty(event.target.id, event.target.checked);
    };

    // Popup Resource Selector:
    const onPopup = (resourceName: string, id: string, required: boolean, index?: number) => {
        if (id === undefined) {
            id = resourceName + "Id";
        }
        const resource: IResource = resources[resourceName];
        const popupRef: string = `editor-${editorName}-${resourceName}-${(requestDto as Record<string, any>).id ?? "create"}`;
        const previewField: string = index === undefined ? resourceName : resourceName + "s";
        addPopup(popupRef, (
            <div className={`${widgetClasses.popupList}`}>
                <ResourceSelector
                    resourceNames={[resourceName]}
                    user={user}
                    popupEditor={true}
                    initialNavMenu={"filter"}
                    onSelect={(resourceName, dto) => {
                        removePopup(popupRef);
                        setProperty(id, dto, index, previewField);
                    }}
                    onEmpty={!required ? resourceName => {
                        removePopup(popupRef);
                        setProperty(id, null, index, previewField);
                    } : undefined}
                    onCreate={resourceName => {
                        removePopup(popupRef);
                        if (!resource.editorBuilder) {
                            return;
                        }
                        const editorPopupRef: string = `editor-${editorName}-${resourceName}-create`;
                        addPopup(editorPopupRef, (
                            <div className={`${widgetClasses.popup}`}>
                                {resource.editorBuilder(undefined, createdDto => {
                                    removePopup(editorPopupRef);
                                    setProperty(id, createdDto, index, previewField);
                                }, () => removePopup(editorPopupRef))}
                            </div>
                        ));
                    }}
                    onCancel={() => removePopup(popupRef)}
                />
            </div>
        ));
    };

    // Edit Resource:
    const onEdit = (resourceName: string, dto: Record<string, any>) => {
        const resource: IResource = resources[resourceName];
        const popupName: string = `editor-nested-${resourceName}-${dto.id}`;
        addPopup(popupName, (
            <div className={`${widgetClasses.popup}`}>
                {resource.editorBuilder && resource.editorBuilder(dto, () => removePopup(popupName), () => removePopup(popupName))}
            </div>
        ));
    };

    // Return Actions:
    return [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup, onEdit];
}