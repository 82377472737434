import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Params, useParams } from "react-router-dom";

import { cardEntryService, cardService, loginService } from "services";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import CardRender from "components/widgets/renders/card-render";

const styles = createUseStyles({
    container: {
        color: "white",
    },
});

export default function Render(): JSX.Element {
    // Router:
    const params: Params<string> = useParams();

    // States:
    const [elevated, setElevated] = useState(false);
    const [card, setCard] = useState(undefined as CardDto | undefined);
    const [cardEntry, setCardEntry] = useState(undefined as CardEntryDto | undefined);

    // Effects:
    useEffect(() => {
        const urlParams: Record<string, any> = new URLSearchParams(window.location.search);
        const basicKey: string | null = urlParams.get('basicKey');
        if (elevated || !basicKey) {
            return;
        }

        loginService.basic(basicKey);

        return loginService.addListener((event, dto) => {
            setElevated(dto.user.admin ?? false);
        });
    }, []);

    useEffect(() => {
        if (!elevated || !params.cardEntryId) {
            return;
        }

        cardEntryService.show({ id: params.cardEntryId });

        return cardEntryService.addListener((event, dto) => {
            if (dto && dto.id === params.cardEntryId) {
                setCardEntry(dto);
            }
        });
    }, [elevated, params.cardEntryId]);

    useEffect(() => {
        if (!cardEntry?.card) {
            return;
        }

        cardService.show({ id: cardEntry.card.id });

        return cardService.addListener((event, dto) => {
            if (cardEntry && dto && dto.id === cardEntry.card?.id) {
                setCard(dto);
            }
        });
    }, [cardEntry]);

    // Styles:
    const classes = styles();

    // Return Component:
    return (
        <div className={`${classes.container}`}>
            {(card && cardEntry) && <CardRender card={card} cardEntry={cardEntry} />}
            {(card && cardEntry) && <CardRender card={card} cardEntry={cardEntry} mask={true} />}
            <p>Card Entry ID: {params.cardEntryId}</p>
            <p>Card ID: {card?.id ?? "Loading"}</p>
        </div>
    );
}