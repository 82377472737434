import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { TemplateDto, TemplateListDto, TemplatePartialDto, TemplateQueryDto, TemplateRequestDto } from "dtos/shard/template.dto";

export class TemplateService extends AbstractResourceService<TemplateDto, TemplateRequestDto, TemplatePartialDto, TemplateListDto, TemplateQueryDto>
{
    public templateDtos: Record<string, TemplateDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "template", "Template", "Templates", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}