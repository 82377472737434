import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { removePopup } from "components/widgets/popups";

const styles = createUseStyles({
    item: {
        minWidth: "8em",
        minHeight: "3.5em",
        padding: "0.5em",
        margin: "0.25em",
        flexGrow: 0,
    },
    selected: {
        backgroundColor: variables.colors.average,
        "&:hover": {
            backgroundColor: variables.colors.average,
        },
    },
});

export default function SelectPopup(props: {
    popupRef: string,
    title: string,
    options: string[],
    selected?: string,
    confirmOption?: string,
    warningOption?: string,
    onSelect: (option: string) => void,
}): JSX.Element
{
    // Actions:
    const select = (option: string) => {
        removePopup(props.popupRef);
        props.onSelect(option);
    };

    // Component:
    const classes = styles();
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
            <h1>{props.title}</h1>
            <div className={`${widgetClasses.row}`}>
                {props.options.map(option => (
                    <button
                        key={option}
                        onClick={() => select(option)}
                        className={`${classes.item} ${option === props.selected ? classes.selected : ""} ${option === props.confirmOption ? widgetClasses.confirm : ""} ${option === props.warningOption ? widgetClasses.warning : ""}`}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </div>
    );
}