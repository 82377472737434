import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import variables from "styles/variables";
import widgets from "styles/widgets";

const styles = createUseStyles<any, {
    textAlign?: string,
}>({
    container: {
        height: "12em",
        backgroundColor: "#00000077",
    },
    toolbar: {
        "&.rdw-editor-toolbar": {
            height: 40,
            color: "#FFFFFF",
            border: "none",
            justifyContent: "center",
            backgroundColor: variables.colors.neutral + "77",
        },
    },
    editor: {
        "&.rdw-editor-main": {
            height: "calc(100% - 46px)",
        },
        "& .public-DraftStyleDefault-ltr": {
            margin: "1em",
            textAlign: props => props.textAlign ?? "center",
        },
    },
});

export default function Rte(props: {
    id: string,
    value: string,
    className?: string,
    textAlign?: string,
    onChange: (id: string, value: string) => void,
}): JSX.Element
{
    // States:
    const blocks = convertFromHTML(props.value ?? "");
    const contentState: ContentState = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap);
    const initialEditorState = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(initialEditorState);
    const [html, setHtml] = useState(props.value ?? "");

    // Effects:
    useEffect(() => {
        if (props.value !== html) {
            setEditorState(initialEditorState);
        }
    }, [props.value]);

    // Actions:
    const onEditorStateChange = (newState: EditorState) => {
        setEditorState(newState);
        const html: string = convertToHTML(newState.getCurrentContent());
        setHtml(html);
        props.onChange(props.id, html);
    };

    // Component:
    const widgetClasses = widgets();
    const classes = styles({
        textAlign: props.textAlign,
    });
    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName={`${classes.container} ${props.className ?? ""}`}
            toolbarClassName={classes.toolbar}
            editorClassName={classes.editor}
            toolbar={{
                options: ["inline"],
                inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                },
            }}
        />
    );
}