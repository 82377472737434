import { useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { DeckDto } from "dtos/shard/deck.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import { UserDto } from "dtos/user.dto";

const styles = createUseStyles<any, {
    imageUrl: string,
}>({
    tile: {
        flexDirection: "column",
        justifyContent: "space-between",
        
        backgroundImage: props => props.imageUrl,
    },
    bar: {
        display: "flex",
        width: "100%",
        height: "3em",

        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",

        backgroundColor: variables.colorsDark.background + "77",

        "& > img": {
            width: "2em",
            height: "auto",
        },
    },
    attributes: {
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
    },
});

export default function DeckItem(props: {
    id: string,
    user?: UserDto,
    deck?: DeckDto,
    cardEntry?: CardEntryDto,
    selected: boolean,
    onClick?: (deck?: DeckDto) => void,
}): JSX.Element
{
    // Properties:
    const displayCode: string | undefined = props.cardEntry ? `${props.cardEntry.cardSet.code}-${props.cardEntry.reference.toString().padStart(3, "0")}` : undefined;
    const totalCount: number = props.deck?.deckEntries?.reduce((total, deckEntryDto) => total + deckEntryDto.count, 0) ?? 0;

    // States:
    const [refresh, setRefresh] = useState("");
    
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.deck);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const imageUrl: string = displayCode ? `url(${process.env.REACT_APP_IMAGES_URL}/thumbnail/${displayCode}?refresh=${refresh}${props.user?.socketId ? "&socketId=" + props.user.socketId : ""})` : "url(/images/card/back_small.png)";
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.deck,
        selected: props.selected,
    });
    const classes = styles({
        imageUrl,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container} ${listItemClasses.card}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.deck ? (<>
                    <div className={`${classes.bar} ${listItemClasses.title}`}>
                        <span>{props.deck.name}</span>
                    </div>
                    <div className={`${classes.bar}`}>
                        <div className={`${classes.attributes}`}>
                            <span>Cards: {totalCount}</span>
                        </div>
                    </div>
                </>) : (<>
                    <div className={`${classes.bar} ${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Deck</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}