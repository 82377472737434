import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { ActionTypeDto, ActionTypePartialDto, ActionTypeListDto, ActionTypeQueryDto, ActionTypeRequestDto } from "dtos/shard/action-type.dto";

export class ActionTypeService extends AbstractResourceService<ActionTypeDto, ActionTypeRequestDto, ActionTypePartialDto, ActionTypeListDto, ActionTypeQueryDto>
{
    public actionTypeDtos: Record<string, ActionTypeDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "actionType", "Action Type", "Action Types", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}