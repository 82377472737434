import renderStyles from "styles/render-styles";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import { CardTemplate, templates } from "templates";
import CardRenderFilters from "./card-render-filters";
import ActionRender from "./action-render";
import { UserDto } from "dtos/user.dto";
import { replaceAll } from "utils";

/**
 * Strips basic html tags from the provided html text. Only basic tags provided by wysiwyg editors are supported.
 * @param html The html text to strip element tags out of.
 * @returns The text with html elements removed.
 */
function stripHtml(html: string) {
    let plainText = html;
    let filterElements = ["<strong>", "</strong>", "<u>", "</u>", "<br>", "<i>", "</i>", "<p>", "</p>"];
    filterElements.forEach(filterElement => plainText = replaceAll(plainText, filterElement, ""))
    return plainText;
};

export default function CardRender(props: {
    user?: UserDto,
    card?: Partial<CardDto>,
    cardEntry?: Partial<CardEntryDto>,
    mask?: boolean,
}): JSX.Element
{
    // Properties:
    const displayCode: string | undefined = props.cardEntry ? `${props.cardEntry.cardSet?.code ?? "XXX"}-${props.cardEntry.reference?.toString().padStart(3, "0") ?? "###"}` : undefined;

    // Styles:
    const templateName: string = props.card?.cardType?.template.name ?? "spell";
    const template: CardTemplate = templates[templateName];
    const classes = renderStyles({
        template,
    });

    // Empty Card:
    if (!props.card) {
        return (
            <div className={`${classes.container} ${props.mask ? classes.mask : ""}`}>
                <img src="/images/card/back.png" className={`${classes.background}`} />
            </div>
        );
    }

    // Boxes:
    const boxes: JSX.Element[] = [];
    if (props.card) {
        // Title Box:
        if (props.card.title) {
            boxes.push((
                <div key="title" className={`${classes.box}`} style={{
                    left: (template.innerWidth / 4) - (template.titleOffset / 2),
                    top: template.detailsTop + (template.dividerHeight * 2) - template.titleOffset,
                    height: 0,
                    width: (template.innerWidth / 2) - (template.titleOffset / 2),
                    borderBottomWidth: (template.titleOffset - template.dividerHeight),
                    borderBottomStyle: "solid",
                    borderLeftWidth: (template.titleOffset - template.dividerHeight),
                    borderLeftStyle: "solid",
                    borderLeftColor: "transparent",
                    borderRightWidth: (template.titleOffset - template.dividerHeight),
                    borderRightStyle: "solid",
                    borderRightColor: "transparent",
                    background: "none",
                }} />
            ));
        }

        const cardBoxWidth: number = template.innerWidth - (template.detailsMargin * 2);
        switch (templateName) {
            // Creature Boxes:
            case "creature":
                const actionsBoxTop: number = template.detailsTop + template.dividerHeight;

                // Action Boxes:
                boxes.push((
                    <div key="actions" className={`${classes.box}`} style={{
                        left: template.detailsMargin,
                        top: actionsBoxTop,
                        width: cardBoxWidth,
                        height: template.detailsFrameHeight,
                    }} />
                ));
                if (props.card.actions) {
                    if (props.card.actions.length >= 1) {
                        boxes.push((
                            <div key="actionElementLeft" className={`${classes.box}`} style={{
                                left: template.detailsMargin,
                                top: actionsBoxTop,
                                width: cardBoxWidth / 4,
                                height: template.detailsFrameHeight,
                                background: "linear-gradient(90deg, " + props.card.actions[0].element.color + " 0%, rgba(0, 0, 0, 0) 100%)",
                                opacity: 0.3,
                            }} />
                        ));
                    }
                    if (props.card.actions.length >= 2) {
                        boxes.push((
                            <div key="actionElementRight" className={`${classes.box}`} style={{
                                right: template.detailsMargin,
                                top: actionsBoxTop,
                                width: cardBoxWidth / 4,
                                height: template.detailsFrameHeight,
                                background: "linear-gradient(270deg, " + props.card.actions[1].element.color + " 0%, rgba(0, 0, 0, 0) 100%)",
                                opacity: 0.3,
                            }} />
                        ));
                    }
                }

                // Effect Boxes:
                const effectBoxTop: number = template.detailsBottom - template.dividerHeight - template.detailsFrameHeight;
                boxes.push((
                    <div key="effect" className={`${classes.box}`} style={{
                        left: template.detailsMargin,
                        top: effectBoxTop,
                        width: cardBoxWidth,
                        height: template.detailsFrameHeight,
                    }} />
                ));
                boxes.push((
                    <div key="effectElementLeft" className={`${classes.box}`} style={{
                        left: template.detailsMargin,
                        top: effectBoxTop,
                        width: cardBoxWidth / 4,
                        height: template.detailsFrameHeight,
                        background: "linear-gradient(90deg, " + (props.card.element?.color ?? "#ffffff") + " 0%, rgba(0, 0, 0, 0) 100%)",
                        opacity: 0.3,
                    }} />
                ));
                boxes.push((
                    <div key="effectElementRight" className={`${classes.box}`} style={{
                        right: template.detailsMargin,
                        top: effectBoxTop,
                        width: cardBoxWidth / 4,
                        height: template.detailsFrameHeight,
                        background: "linear-gradient(270deg, " + (props.card.element?.color ?? "#ffffff") + " 0%, rgba(0, 0, 0, 0) 100%)",
                        opacity: 0.3,
                    }} />
                ));
                break;

            // Spell Boxes:
            case "spell":
                const spellBoxTop: number = template.detailsTop + template.dividerHeight;
                boxes.push((
                    <div key="effect" className={`${classes.box}`} style={{
                        left: template.detailsMargin,
                        top: spellBoxTop,
                        width: cardBoxWidth,
                        height: template.detailsBottom - template.detailsTop - template.dividerHeight,
                    }} />
                ));
                boxes.push((
                    <div key="effectElementLeft" className={`${classes.box}`} style={{
                        left: template.detailsMargin,
                        top: spellBoxTop,
                        width: cardBoxWidth / 4,
                        height: template.detailsBottom - template.detailsTop - template.dividerHeight,
                        background: "linear-gradient(90deg, " + (props.card.element?.color ?? "#ffffff") + " 0%, rgba(0, 0, 0, 0) 100%)",
                        opacity: 0.3,
                    }} />
                ));
                boxes.push((
                    <div key="effectElementRight" className={`${classes.box}`} style={{
                        right: template.detailsMargin,
                        top: spellBoxTop,
                        width: cardBoxWidth / 4,
                        height: template.detailsBottom - template.detailsTop - template.dividerHeight,
                        background: "linear-gradient(270deg, " + (props.card.element?.color ?? "#ffffff") + " 0%, rgba(0, 0, 0, 0) 100%)",
                        opacity: 0.3,
                    }} />
                ));
            break;
        }
    }

    // Frames:
    let frames: JSX.Element | undefined;
    if (props.card) {
        const frameRects: JSX.Element[] = [];

        // Top:
        frameRects.push((
            <rect key="top" x={0} y={0} width={template.innerWidth} height={template.dividerHeight} fill="url(#frame-background)" />
        ));

        // Element Box:
        frameRects.push((
            <rect key="element" x={0} y={template.dividerHeight} width={template.elementBoxSize} height={template.elementBoxSize} fill="url(#frame-background)" />
        ));

        // Details Top:
        if (props.card.title) {
            const titleFrameWidth: number = template.innerWidth / 4;
            frameRects.push((
                <rect key="detailsTopLeft" x={0} y={template.detailsTop} width={titleFrameWidth} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
            frameRects.push((
                <rect key="detailsTopRight" x={titleFrameWidth * 3} y={template.detailsTop} width={titleFrameWidth} height={template.dividerHeight} fill="url(#frame-background)" />
            ));

            // Left Angles:
            frameRects.push((
                <rect key="angleTopLeft" x={titleFrameWidth - 4} y={template.detailsTop} width={32} height={template.dividerHeight} transform={"rotate(-45, " + (titleFrameWidth - 4) + ", " + (template.detailsTop + (template.dividerHeight / 2)) + ")"} fill="url(#frame-background)" />
            ));
            frameRects.push((
                <rect key="angleBottomLeft" x={titleFrameWidth - 4} y={template.detailsTop} width={32} height={template.dividerHeight} transform={"rotate(45, " + (titleFrameWidth - 4) + ", " + (template.detailsTop + (template.dividerHeight / 2)) + ")"} fill="url(#frame-background)" />
            ));

            // Right Angles:
            frameRects.push((
                <rect key="angleTopRight" x={(titleFrameWidth * 3) + 4 - 32} y={template.detailsTop} width={32} height={template.dividerHeight} transform={"rotate(-45, " + ((titleFrameWidth * 3) + 4) + ", " + (template.detailsTop + (template.dividerHeight / 2)) + ")"} fill="url(#frame-background)" />
            ));
            frameRects.push((
                <rect key="angleBottomRight" x={(titleFrameWidth * 3) + 4 - 32} y={template.detailsTop} width={32} height={template.dividerHeight} transform={"rotate(45, " + ((titleFrameWidth * 3) + 4) + ", " + (template.detailsTop + (template.dividerHeight / 2)) + ")"} fill="url(#frame-background)" />
            ));

            // Title Center:
            frameRects.push((
                <rect key="titleTop" x={titleFrameWidth + 16} y={template.detailsTop + 22} width={(titleFrameWidth * 2) - 32} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
            frameRects.push((
                <rect key="titleBottom" x={titleFrameWidth + 16} y={template.detailsTop - 22} width={(titleFrameWidth * 2) - 32} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
        } else {
            frameRects.push((
                <rect key="detailsTop" x={0} y={template.detailsTop} width={template.innerWidth} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
        }

        // Creature Inner Frames:
        if (templateName === "creature") {
            frameRects.push((
                <rect key="creatureTop" x={0} y={template.detailsTop + template.detailsFrameHeight + template.dividerHeight} width={template.innerWidth} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
            frameRects.push((
                <rect key="creatureBottom" x={0} y={template.detailsBottom - template.detailsFrameHeight - (template.dividerHeight * 2)} width={template.innerWidth} height={template.dividerHeight} fill="url(#frame-background)" />
            ));
        }

        // Bottom:
        frameRects.push((
            <rect key="bottom" x={0} y={template.detailsBottom - template.dividerHeight} width={template.innerWidth} height={template.dividerHeight} fill="url(#frame-background)" />
        ));

        // Rarity Component:
        let rarityComp: JSX.Element | undefined;
        if (props.cardEntry) {
            const rarityName: string = props.cardEntry.rarity ? replaceAll(props.cardEntry.rarity.name.toLowerCase(), " ", "_") : undefined ?? "common";
            rarityComp = (<>
                <img src={`/images/card/rarities/${rarityName}.svg`} style={{
                    left: 36,
                    bottom: template.detailsBottom - template.detailsTop + template.dividerHeight - 6,
                    width: "13%"
                }} />
                <img src={`/images/card/rarities/${rarityName}.svg`} style={{
                    right: 36,
                    bottom: template.detailsBottom - template.detailsTop + template.dividerHeight - 6,
                    width: "13%",
                    transform: "scale(-1, 1)"
                }} />
            </>);
        }

        // Frame Component:
        const colorFilterName: string = `#shard-card-frame-color-${props.card.element?.name}`;
        frames = (
            <div className={`${classes.framesWrapper}`} style={{
                filter: `url("${colorFilterName}")`,
            }}>
                <div className={`${classes.frames}`}>
                    {rarityComp}
                    <svg xmlns="http://www.w3.org/2000/svg" style={{
                        width: template.innerWidth,
                        height: "100%",
                    }}>
                        <pattern id="frame-background" patternUnits="userSpaceOnUse" width="100%" height="100%">
                            <image href="/images/card/frame.png" x={-template.borderWidth} y={-template.borderHeight} width={template.fullWidth} height={template.fullHeight} />
                        </pattern>
                        {frameRects}
                    </svg>
                </div>
            </div>
        );
    }

    // Text and Icons:
    const textIconComps: JSX.Element[] = [];
    if (props.card) {

        // Element:
        textIconComps.push((
            <div key="elementIcon" className={`${classes.element}`} style={{
                top: (template.dividerHeight / 2) + 12,
                left: 10,
            }}>
                <img src={"/images/card/elements/" + (props.card.element?.name.toLowerCase() ?? "fire") + ".svg"} />
            </div>
        ));
        textIconComps.push((
            <div key="elementText" className={`${classes.textWrapper}`} style={{
                top: template.dividerHeight + template.elementBoxSize + 6,
                left: 0,
                width: template.elementBoxSize,
            }}>
                <span className={`${classes.text} ${classes.center} ${classes.elementText} ${classes.stroke}`}>{props.card.element?.name ?? "Not Set"}</span>
            </div>
        ));

        // Names:
        let subspeciesComp: JSX.Element | undefined;
        if (props.card.subspecies) {
            subspeciesComp = (
                <span className={`${classes.text} ${classes.large} ${classes.stroke}`}>{props.card.subspecies}</span>
            );
        }

        let morphComp: JSX.Element | undefined;
        if (props.card.morph) {
            morphComp = (
                <span className={`${classes.text} ${classes.large} ${classes.stroke}`}>{props.card.morph}</span>
            );
        }

        let scale: number = 1;
        const namesLength: number = (props.card.subspecies ?? "").length + ((props.card.name?.length ?? 0) * 2) + (props.card.morph ?? "").length;
        const namesExcess: number = Math.max(0, namesLength - 36);
        if (namesExcess > 0) {
            scale -= namesExcess * 0.022;
        }
        textIconComps.push((
            <div key="names" className={`${classes.textWrapper}`} style={{
                top: template.dividerHeight,
                right: 8,
                textAlign: "right",
                transform: `scale(${scale}, 1)`,
                transformOrigin: "100% 0%",
            }}>
                {subspeciesComp}
                <span className={`${classes.text} ${classes.nameText} ${classes.stroke}`}>{props.card.name ?? "New Card"}</span>
                {morphComp}
            </div>
        ));

        // Type:
        textIconComps.push((
            <div key="typeText" className={`${classes.textWrapper}`} style={{
                top: template.dividerHeight + 64,
                right: 8,
            }}>
                <span className={`${classes.text} ${classes.large} ${classes.stroke}`}>{props.card.cardType?.name ?? "Not Set"}</span>
            </div>
        ));

        // Title:
        if (props.card.title) {
            textIconComps.push((
                <div key="titleText" className={`${classes.textWrapper}`} style={{
                    top: template.detailsTop + (template.dividerHeight * 2) - template.titleOffset + 2,
                    left: 0,
                    right: 0,
                }}>
                    <span className={`${classes.text} ${classes.titleText} ${classes.center} ${classes.stroke}`}>{props.card.title.name}</span>
                </div>
            ));
        }

        // Creature Stats:
        if (templateName === "creature") {
            const statsTop: number = template.detailsTop + template.dividerHeight;
            const statsHeight: number = template.detailsFrameHeight;
            const statsTitleOffset: number = props.card.title ? (template.titleOffset / 2) - 4 : 0;

            // Health:
            textIconComps.push((
                <div key="health" className={`${classes.textWrapper}`} style={{
                    top: statsTop + (statsHeight / 4) + statsTitleOffset,
                    left: "43%",
                    right: "43%",
                    height: (statsHeight / 2),
                    borderLeft: "1px solid white",
                    borderRight: "1px solid white",
                }}>
                    <span className={`${classes.text} ${classes.healthText} ${classes.number} ${classes.center} ${classes.stroke}`}>{props.card.health ?? 1}</span>
                </div>
            ));

            if (props.card.actions) {
                // Action A:
                if (props.card.actions[0]) {
                    textIconComps.push((
                        <div key="actionA" className={`${classes.textWrapper} ${classes.textVertical}`} style={{
                            top: statsTop + (props.card.title ? statsTitleOffset / 2 : 0),
                            left: 0,
                            width: "calc(43% - 32px)",
                            height: (statsHeight / 2) + 40,
                            paddingTop: 8,
                        }}>
                            <ActionRender action={props.card.actions[0]} template={template} titleOffset={statsTitleOffset} />
                        </div>
                    ));
                }

                // Action B:
                if (props.card.actions[1]) {
                    textIconComps.push((
                        <div key="actionB" className={`${classes.textWrapper} ${classes.textVertical} ${classes.right}`} style={{
                            top: statsTop + (props.card.title ? statsTitleOffset / 2 : 0),
                            left: "57%",
                            width: "calc(43% - 32px)",
                            height: (statsHeight / 2) + 40,
                            paddingTop: 8,
                        }}>
                        <ActionRender action={props.card.actions[1]} template={template} titleOffset={statsTitleOffset} mirror={true} />
                        </div>
                    ));
                }
            }
        }

        // Effect:
        let effectTop: number = template.detailsTop + template.dividerHeight;
        const effectHtml: string = (templateName === "creature" ? props.card.creatureEffect?.effect : props.card.effect) ?? "";
        const effectText: string = stripHtml(effectHtml);
        let effectTextClass: string = effectText.length <= 280 ? classes.medium : "";
        let effectNameComp: JSX.Element | undefined;
        if (templateName === "creature") {
            effectTop += template.detailsFrameHeight + (template.dividerHeight * 4) + template.detailsMargin;
            effectTextClass = `${effectText.length <= 170 ? "" : effectText.length <= 290 ? classes.small : classes.xsmall} ${classes.creatureEffectText}`;
            effectNameComp = (
                <span className={`${classes.text} ${classes.creatureEffectName} ${classes.center} ${classes.stroke}`}>{props.card.creatureEffect?.name ?? ""}</span>
            );
        } else if (props.card.title) {
            effectTop += template.titleOffset;
        }
        textIconComps.push((
            <div key="effectText" className={`${classes.textWrapper} ${classes.textVertical}`} style={{
                top: effectTop,
                left: 0,
                right: 0,
                height: template.detailsBottom - template.dividerHeight - effectTop - 32,
            }}>
                {effectNameComp}
                <span className={`${classes.text} ${classes.effectText} ${effectTextClass} ${classes.center} ${classes.stroke}`} dangerouslySetInnerHTML={{ __html: effectHtml }}></span>
            </div>
        ));

        // Footer:
        textIconComps.push((
            <div key="footer" className={`${classes.textWrapper}`} style={{
                top: template.detailsBottom + template.detailsMargin,
                left: 0,
                width: "calc(100% - 32px)",
                paddingLeft: 16,
                paddingRight: 16,
            }}>
                <span className={`${classes.text} ${classes.stroke}`}>
                    <img src="/images/card/artist.svg" style={{
                        height: template.iconHeight,
                        verticalAlign: "middle",
                    }} />{" "}
                    {props.cardEntry?.artist?.name ?? ""}
                </span>
                <span className={`${classes.text} ${classes.center} ${classes.stroke}`}>
                    shardtcg.com
                </span>
                <span className={`${classes.text} ${classes.right} ${classes.stroke}`}>
                    {displayCode}
                </span>
            </div>
        ));
    }

    // Costs:
    const costEntries: JSX.Element[] = [];
    for (let i: number = 0; i < (props.card.shardCost ?? 0); i++) {
        costEntries.push((
            <img key={"shard-" + i} src={"/images/card/shard.svg"} />
        ));
    }
    for (let i: number = 0; i < (props.card.manaCost ?? 0); i++) {
        costEntries.push((
            <img key={"mana-" + i} src={"/images/card/mana.svg"} />
        ));
    }
    const costsComp: JSX.Element = (
        <div className={`${classes.costs}`} style={{
            top: template.dividerHeight + template.elementBoxSize + 56,
            left: 20,
        }}>
            {costEntries}
        </div>
    );
    
    // Return Component:
    return (
        <div className={`render-ready ${classes.container} ${props.mask ? classes.mask : ""}`}>
            <img src={`${process.env.REACT_APP_IMAGES_URL}/art/${displayCode ? displayCode : "back"}${props.user?.socketId ? "?socketId=" + props.user.socketId : ""}`} className={`${classes.background}`} />
            <div className={`${classes.interface}`}>
                {boxes}
                {frames}
                {textIconComps}
                {costsComp}
            </div>
            <CardRenderFilters element={props.card?.element} />
        </div>
    );
}