import { createUseStyles } from "react-jss";

import { ListItem } from "types/list-item.d";

import variables from "styles/variables";
import widgets from "styles/widgets";

const styles = createUseStyles({
    itemWrapper: {
        display: "flex",
        flexDirection: "column",

        flexGrow: 0,
    },
    item: {
        width: "calc(100% - 2em)",
        padding: "0.5em",
        margin: "0 1em",
    },
    selected: {
        backgroundColor: variables.colors.average,
        "&:hover": {
            backgroundColor: variables.colors.average,
        },
    },
    pseudo: {
        borderStyle: "dashed",
    },
    control: {
        width: "100%",
    },
    addRemove: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        border: 2,
        borderStyle: "solid",
        borderRadius: "0.25em",

        backgroundColor: variables.colors.neutral + "77",
        borderColor: variables.colors.neutral + "77",

        "& $control": {
            minWidth: "3em",
            flexGrow: 0,
        },
    },
    count: {
        textAlign: "center",
        lineHeight: 1.8,
        flexGrow: 1,
    },
});

export default function List(props: {
    items: ListItem[],
    itemWrapperClassName?: string,
    onCreate?: () => void, // An optional function to call when the create item is selected, if not set the create item is hidden.
    onEmpty?: () => void, // An optional function to call when the empty item (often used for filtering by none) is selected, if not set the create item is hidden.
    pseudoBuilder?: (pseudoItem: ListItem) => JSX.Element,
}): JSX.Element
{
    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Create Item:
    let createComp: JSX.Element | undefined;
    if (props.onCreate) {
        if (props.pseudoBuilder) {
            createComp = (
                <div key="create" className={`${classes.itemWrapper} ${props.itemWrapperClassName ?? ""}`}>
                    {props.pseudoBuilder({
                        key: "create",
                        name: "Create",
                        selected: false,
                        action: props.onCreate,
                    })}
                </div>
            );
        } else {
            createComp = (
                <div key="create" className={`${classes.itemWrapper} ${props.itemWrapperClassName ?? ""}`}>
                    <button
                        onClick={() => props.onCreate ? props.onCreate() : {}}
                        className={`${classes.item} ${classes.pseudo}`}
                    >Create</button>
                </div>
            );
        }
    }

    // Empty Item:
    let emptyComp: JSX.Element | undefined;
    if (props.onEmpty) {
        if (props.pseudoBuilder) {
            emptyComp = (
                <div key="empty" className={`${classes.itemWrapper} ${props.itemWrapperClassName ?? ""}`}>
                    {props.pseudoBuilder({
                        key: "empty",
                        name: "Empty",
                        selected: false,
                        action: props.onEmpty,
                    })}
                </div>
            );
        } else {
            emptyComp = (
                <div key="empty" className={`${classes.itemWrapper} ${props.itemWrapperClassName ?? ""}`}>
                    <button
                        onClick={() => props.onEmpty ? props.onEmpty() : {}}
                        className={`${classes.item} ${classes.pseudo}`}
                    >None</button>
                </div>
            );
        }
    }

    // Return Component:
    return (
        <>
            {createComp}
            {emptyComp}
            {props.items.map(item => (
                <div key={item.key} className={`${classes.itemWrapper} ${props.itemWrapperClassName ?? ""}`}>
                    {item.componentBuilder ? item.componentBuilder(item) : (
                        <button
                            onClick={item.action}
                            className={`${classes.item} ${item.selected ? classes.selected : ""}`}
                        >{item.name}</button>
                    )}
                    {item.edit && (
                        <button
                            onClick={() => item.edit!()}
                            className={`${classes.control}`}
                        >Edit</button>
                    )}
                    {(item.add || item.remove) && (
                        <div className={`${classes.addRemove}`}>
                            {item.remove && (<button
                                onClick={() => item.remove!()}
                                className={`${classes.control} ${widgetClasses.warning}`}
                            >-</button>)}
                            <span className={`${classes.count}`}>{item.count ?? ""}</span>
                            {item.add && (<button
                                onClick={() => item.add!()}
                                className={`${classes.control} ${widgetClasses.confirm}`}
                            >+</button>)}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}