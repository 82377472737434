import { createUseStyles } from "react-jss";

import { ActionDto } from "dtos/shard/action.dto";
import { CardTemplate, templates } from "templates";

import renderStyles from "styles/render-styles";
import ActionRender from "../renders/action-render";

const styles = createUseStyles<any, {
    template: CardTemplate,
    action?: Partial<ActionDto>,
}>({
    container: {
        position: "relative",
        width: props => (props.template.innerWidth * 0.43) - 32,
        height: props => props.template.detailsFrameHeight + 32,
    },
    action: {
        width: "100%",
        height: "100%",
        background: props => "linear-gradient(90deg, " + (props.action?.element?.color ?? "#000000") + "48 0%, rgba(0, 0, 0, 0) 100%)",
    },
});

export default function ActionView(props: {
    action?: Partial<ActionDto>,
}): JSX.Element
{
    // Styles:
    const template: CardTemplate = templates["creature"];
    const renderClasses = renderStyles({
        template,
    });
    const classes = styles({
        template,
        action: props.action,
    });

    // Components:
    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.action} ${renderClasses.textWrapper} ${renderClasses.textVertical}`} style={{
                        left: 0,
                        // width: "calc(43% - 32px)",
                        height: (template.detailsFrameHeight / 2) + 40,
                        paddingTop: 8,
                    }}>
                <ActionRender action={props.action} template={template} />
            </div>
        </div>
    );
}