import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";

const styles = createUseStyles({
    controls: {
        flexGrow: 0,
    },
});

export default function Popup(props: {
    title?: string,
    description?: string[],
    component?: JSX.Element,
    className?: string,
    confirmText?: string,
    cancelText?: string,
    confirmAction?: () => void,
    cancelAction?: () => void,
}): JSX.Element
{

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Component:
    return (
        <div className={`${widgetClasses.overlay}`}>
            <div className={`${widgetClasses.popup} ${widgetClasses.form} ${props.className ?? ""}`}>
                {props.title && (<h1>{props.title}</h1>)}
                {props.description?.map(line => (<span key={line}>{line}</span>))}
                {props.component}
                <div className={`${widgetClasses.row} ${classes.controls}`}>
                    {props.confirmText && props.confirmAction && (
                        <button className={widgetClasses.confirm} onClick={props.confirmAction}>
                            {props.confirmText}
                        </button>
                    )}
                    {props.cancelText && props.cancelAction && (
                        <button className={widgetClasses.warning} onClick={props.cancelAction}>
                            {props.cancelText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}