import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { cardEntryService, ttsService } from "services";
import { addPopup, removePopup } from "components/widgets/popups";
import { useResources } from "hooks/resources.hook";
import { IResource } from "bos/resource.bo";
import { UserState } from "states/user.state";
import { DeckDto } from "dtos/shard/deck.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import widgets from "styles/widgets";
import DeckCardManager from "components/widgets/deck-card-manager";
import ResourceSelector from "components/widgets/resource-selector";
import Popup from "components/popups/popup";
import Login from "components/popups/login";

const styles = createUseStyles({
    list: {
        gap: "0.5em",
    },
    selectorSelected: {},
    "@media screen and (max-width: 1280px)": {
        selectorSelected: {
            display: "none",
        },
    },
    "@media screen and (max-width: 720px)": {
        list: {
            minHeight: "calc(100vh - 2em)",
        },
    },
});

export let editGlobal: boolean = false;
export let setEditGlobal: (enabled: boolean) => void = () => {};

export default function Decks(props: {
    user: UserState,
}): JSX.Element
{
    // Router:
    const params: Params<string> = useParams();
    const navigate: NavigateFunction = useNavigate();

    // Resources:
    const resources: Record<string, IResource> = useResources(props.user.dto);
    const currentResource: IResource = resources["deck"];

    // States:
    const [selected, setSelected] = useState(undefined as Record<string, any> | undefined);
    const [cardEntries, setCardEntries] = useState([] as CardEntryDto[]);
    const [edit, setEdit] = useState(params.manage ? true : false);
    editGlobal = edit;
    setEditGlobal = (edit: boolean) => {
        navigate(`/decks${params.selectedId ? "/" + params.selectedId : ""}${edit ? "/manage" : ""}`);
        setEdit(edit);
    };

    // Properties:
    const selectedCardEntry: CardEntryDto | undefined = selected ? cardEntries.find(cardEntry => cardEntry.id === selected.coverCardEntryId) : undefined;

    // Dto from Url:
    if (params.selectedId && !selected) {
        const dtoFromId: Record<string, any> | undefined = currentResource.items.find(item => item.id === params.selectedId);
        if (dtoFromId) {
            setSelected(dtoFromId);
        }
    }

    // Effects:
    useEffect(() => {
        if (!currentResource.items.length) {
            return;
        }
        const requestId: string = uuid();
        cardEntryService.index({
            limit: currentResource.pageSize,
            filter: { id: { in: currentResource.items.map(deckDto => deckDto.coverCardEntryId).join(",") } },
        }, requestId);
        return cardEntryService.addListListener((event, listDto) => {
            if (listDto.requestId === requestId) {
                setCardEntries(listDto.items);
            }
        });
    }, [currentResource.items]);

    // Actions:
    const getItemMetadata = (dto: DeckDto) => {
        return {
            cardEntry: cardEntries.find(cardEntry => cardEntry.id === dto.coverCardEntryId),
        };
    };
    const setSelectedDto = (resource: string, dto: Record<string, any>) => {
        setEditGlobal(false);
        setSelected(dto);
        navigate(`/decks/${dto.id ?? "create"}`);
    };
    const clearSelectedDto = () => {
        setEditGlobal(false);
        setSelected(undefined);
        navigate(`/decks`);
    };
    const onTts = (deckId: string) => {
        addPopup("ttsExport", (
            <Popup
                title="Tabletop Simulator Deck Export"
                description={[
                    `Click "Export" to download this Deck as a Tabletop Simulator Deck Game Object. You can use it by copying it into your TTS "My Saves" folder located at:`,
                    `Linux: ~/.local/share/Tabletop Simulator`,
                    `Mac: ~/Library/Tabletop Simulator/Saves/Saved Objects`,
                    `Windows: %USERPROFILE%/Documents/My Games/Tabletop Simulator/Saves/Saved Objects`,
                ]}
                confirmText="Export"
                confirmAction={() => {
                    ttsService.exportDeck(deckId);
                    removePopup("ttsExport");
                }}
                cancelText="Cancel"
                cancelAction={() => removePopup("ttsExport")}
            />
        ));
    };
    const login = () => {
        addPopup("login", (<Login />));
    };

    // Styles:
    const classes = styles();
    const widgetClasses = widgets();
    const admin: boolean = props.user.dto?.admin ?? false;

    // No User:
    if (!props.user.dto) {
        return (
            <div className={`${widgetClasses.page} ${widgetClasses.center}`}>
                <h2 style={{ width: "100%" }}>You need a Nephrite Account to Create and Manage Decks</h2>
                <p style={{ width: "100%" }}>
                    <button className={`${widgetClasses.confirm}`} onClick={() => login()}>Login or Register</button>
                </p>
            </div>
        );
    }

    // Components:
    let displayComp: JSX.Element | undefined;
    if (selected) {
        displayComp = currentResource.displayBuilder ? currentResource.displayBuilder(
            selected,
            dto => setSelectedDto("deck", dto),
            () => clearSelectedDto(),
        ) : undefined;
    }

    // Return Component:
    return (
        <div className={`${widgetClasses.page}`}>
            {(edit && selected) ? <DeckCardManager
                user={props.user.dto}
                deck={selected as DeckDto}
                cardEntry={selectedCardEntry}
                onClose={() => setEditGlobal(false)}
                onTts={onTts}
            />
            : (<div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                <ResourceSelector
                    className={selected ? classes.selectorSelected : ""}
                    user={props.user.dto}
                    resources={resources}
                    resourceNames={["deck"]}
                    resourceName={params.resourceName}
                    selectedId={params.selectedId}
                    getItemMetadata={dto => getItemMetadata(dto as DeckDto)}
                    onSelect={setSelectedDto}
                    onCreate={(resource) => setSelectedDto(resource, {})}
                />
                {displayComp}
            </div>)}
        </div>
    );
}