import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { RuleDto, RuleListDto, RulePartialDto, RuleQueryDto, RuleRequestDto } from "dtos/shard/rule.dto";

export class RuleService extends AbstractResourceService<RuleDto, RuleRequestDto, RulePartialDto, RuleListDto, RuleQueryDto>
{
    public ruleDtos: Record<string, RuleDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "rule", "Rule", "Rules", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}