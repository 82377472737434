import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { IResourceService } from "bos/resource.bo";
import { ResourceField } from "types/resource-field";
import { ListCommon, QueryCommon } from "dtos/common.dto";
import { ErrorDto } from "dtos/error.dto";

export abstract class AbstractResourceService<
    DtoType, RequestDtoType, PartialDtoType extends Partial<RequestDtoType>, ListDtoType extends ListCommon<DtoType>, QueryDtoType extends QueryCommon
> extends AbstractService<DtoType | undefined> implements IResourceService
{
    public readonly listListeners: ((event: string, data: ListDtoType) => void)[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     * @param name The name of this service's resource.
     * @param singular The display name to use when displaying this resource as a single result.
     * @param singular The display name to use when displaying this resource as multiple results.
     * @param fields The array of fields that items from this resource have.
     */
    public constructor(
        socket: Socket,
        public readonly name: string,
        public readonly singular: string,
        public readonly plural: string,
        public readonly fields: ResourceField[],
    )
    {
        super(socket);

        // Base Events:
        this.createEvent(this.name + ".show");
        this.createEvent(this.name + ".store");
        this.createEvent(this.name + ".update");
        this.createEvent(this.name + ".destroy");
        this.socket.on(this.name + ".error", (errorDto: ErrorDto) => {
            console.error(`[Service] [${this.name + ".error"}] received:`, errorDto);
        });

        // List Event:
        this.socket.on(this.name + ".index", (listDto: ListDtoType) => {
            // console.log(`[${this.singular} Service] Indexed ${listDto.items.length} item(s).`);
            this.listListeners.forEach(listener => listener("index", listDto));
        });
        this.socket.on(this.name + ".index.error", (errorDto: ErrorDto) => {
            console.error(`[${this.singular} Service] Error received:`, errorDto);
        });
    }

    /**
     * Makes an index request.
     * @param query The query dto to index with.
     * @param requestId Optional request id used to identify which component to update with the index response event.
     */
    public async index(query?: QueryDtoType, requestId?: string): Promise<void>
    {
        // console.log(`[${this.singular} Service] Indexing:`, query);
        this.socket.emit(this.name + ".index", {
            ...query,
            requestId,
        });
    }

    /**
     * Makes a modify request.
     * @param partialDto The partial dto to get the id of the card to show from.
     */
    public async show(partialDto: PartialDtoType): Promise<void>
    {
        // console.log(`[${this.singular} Service] Showing:`, partialDto);
        this.socket.emit(this.name + ".show", partialDto);
    }

    /**
     * Makes a create request.
     * @param requestDto The request dto to create with.
     * @param requestId Optional request id used to identify the resource that was created.
     */
    public async create(requestDto: RequestDtoType, requestId?: string): Promise<void>
    {
        console.log(`[${this.singular} Service] Creating:`, requestDto);
        this.socket.emit(this.name + ".store", {
            ...requestDto,
            requestId,
        });
    }

    /**
     * Makes a modify request.
     * @param partialDto The partial dto to modify with.
     */
    public async modify(partialDto: PartialDtoType): Promise<void>
    {
        console.log(`[${this.singular} Service] Modifying:`, partialDto);
        this.socket.emit(this.name + ".modify", partialDto);
    }

    /**
     * Makes a destroy request.
     * @param partialDto The partial dto to get the id of the card to destroy from.
     */
    public async destroy(partialDto: PartialDtoType): Promise<void>
    {
        console.log(`[${this.singular} Service] Destroying:`, partialDto);
        this.socket.emit(this.name + ".destroy", partialDto);
    }

    /**
     * Adds an event listener for list dtos.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addListListener(listener: (event: string, listDto: ListDtoType) => void): () => void
    {
        this.listListeners.push(listener);
        return () => {
            const index: number = this.listListeners.indexOf(listener);
            if (index >= 0) {
                this.listListeners.splice(index, 1);
            }
        };
    }
}