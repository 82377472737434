import { createUseStyles } from "react-jss";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";

import { cardAnimations, toggleCardAnimations, statsPanel, toggleStatsPanel } from "utils";
import { UserState } from "states/user.state";
import widgets from "styles/widgets";
import ResourceSelector from "components/widgets/resource-selector";
import { useState } from "react";
import { IResource } from "bos/resource.bo";
import { useResources } from "hooks/resources.hook";
import NavigatedDisplay from "components/widgets/navigated-display";

const styles = createUseStyles({
    list: {
        gap: "0.5em",
    },
    selectorSelected: {},
    "@media screen and (max-width: 1280px)": {
        selectorSelected: {
            display: "none",
        },
    },
    "@media screen and (max-width: 720px)": {
        list: {
            minHeight: "calc(100vh - 2em)",
        },
    },
});

export default function Cards(props: {
    user: UserState,
}): JSX.Element
{
    // Router:
    const params: Params<string> = useParams();
    const navigate: NavigateFunction = useNavigate();

    // Resources:
    let resourceNames: string[] = [
        "card",
        "cardEntry",
        "action",
        "creatureEffect",
        "cardSet",
        "artist",
        "element",
        "cardType",
        "title",
        "actionEffect",
        "rarity",
    ];
    if (props.user.dto?.admin) {
        resourceNames = [
            ...resourceNames,
            "cardSetType",
            "actionType",
            "template",
        ];
    }

    // Actions:
    const setResource = (resource: string) => {
        navigate(`/cards/${resource}`);
    };
    const setSelectedDto = (resource: string, dto: Record<string, any>) => {
        navigate(`/cards/${resource}/${dto.id ?? "create"}`);
    };

    // Styles:
    const classes = styles();
    const widgetClasses = widgets();
    const admin: boolean = props.user.dto?.admin ?? false;

    // Return Component:
    return (
        <div className={`${widgetClasses.page}`}>
            <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                <ResourceSelector
                    className={params.selectedId ? classes.selectorSelected : ""}
                    user={props.user.dto}
                    resourceNames={resourceNames}
                    resourceName={params.resourceName}
                    selectedId={params.selectedId}
                    onSetResource={setResource}
                    onSelect={setSelectedDto}
                    onCreate={(props.user.dto?.admin ?? false) ? (resource) => setSelectedDto(resource, {}) : undefined}
                />
                <NavigatedDisplay user={props.user.dto} />
            </div>
        </div>
    );
}