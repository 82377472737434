import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { CardTypeDto } from "dtos/shard/card-type.dto";

const styles = createUseStyles<any, {
    cardType?: CardTypeDto,
    selected: boolean,
}>({
    tile: {
        borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.cardType?.template.name === "creature" ? variables.colors.negative + "77" : variables.colors.primary),
        backgroundColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.cardType?.template.name === "creature" ? variables.colors.negative + "77" : variables.colors.primary + "77"),

        "&:hover": {
            borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.cardType?.template.name === "creature" ? variables.colors.negative : variables.colors.primary),
            backgroundColor: (props) => props.selected ? variables.colorsLight.average + "AA" : (props.cardType?.template.name === "creature" ? variables.colors.negative + "AA" : variables.colors.primary + "AA"),
        },
    },
});

export default function CardTypeItem(props: {
    id: string,
    cardType?: CardTypeDto,
    selected: boolean,
    onClick?: (cardType?: CardTypeDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.cardType);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const classes = styles({
        cardType: props.cardType,
        selected: props.selected,
    });
    const listItemClasses = listItemStyles({
        create: !!props.cardType,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.cardType ? (<>
                    <div className={`${widgetClasses.column}`}>
                        <div className={`${listItemClasses.title}`}>
                            <span>{props.cardType.name}</span>
                        </div>
                        <span>{props.cardType.template.name.replace(props.cardType.template.name[0], props.cardType.template.name[0].toUpperCase())} Type</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Card Type</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}