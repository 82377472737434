import { createUseStyles } from "react-jss";
import { v4 as uuid } from "uuid";

import { ResourceField } from "types/resource-field";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { addPopup, removePopup } from "components/widgets/popups";
import ResourceSelector from "../resource-selector";
import { ResourceFilter } from "types/resource-filter";
import { useEffect, useRef } from "react";

const styles = createUseStyles({
    container: {
        "& input": {
            width: "14em",
            flexGrow: 1,
            fontSize: "10pt",
            borderStyle: "solid",
            borderColor: variables.colorsLight.primary + "77",
            backgroundColor: variables.colors.primary + "AA",
            color: variables.colors.foreground,
        },
        "& input[type=\"checkbox\"]": {
            width: "2em",
        },
    },
    minMax: {
        flexGrow: 1,
        gap: "0.5em",

        "& input": {
            width: "4em",
        },
    },
    list: {
        "& button": {
            maxWidth: "40em",
            height: "2em",
            whiteSpace: "nowrap",
            flexGrow: 2,
            fontSize: "10pt",
            textTransform: "capitalize",
        },
    },


    "@media screen and (max-width: 720px)": {
        minMax: {
            flexWrap: "nowrap",
        },
        list: {
            flexWrap: "nowrap",
        },
    },
});

export default function Filter(props: {
    className?: string,
    admin?: boolean,
    fields?: ResourceField[],
    filter: Record<string, any>,
    setFilter: (field: string, operator: string, value?: string | number | boolean | ResourceFilter) => void,
}): JSX.Element
{
    // Refs:
    const firstInputRef = useRef<HTMLInputElement>(null);

    // Effects:
    useEffect(() => {
        if (firstInputRef) {
            firstInputRef.current?.focus();
        }
    }, []);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Field Component:
    const filterComps: JSX.Element[] = [];
    props.fields?.forEach((field, index) => {
        if (field.admin && !props.admin) {
            return;
        }
        let fieldComp: JSX.Element | undefined;
        const filterValue: any = props.filter[field.key];
        switch (field.type) {

            // Checkbox Boolean:
            case "boolean":
                fieldComp = (
                    <div className={`${widgetClasses.row} ${classes.list}`}>
                        <button
                            onClick={() => props.setFilter(field.key, field.many ? "some" : "eq", true)}
                            className={`${(filterValue?.eq === true || filterValue?.some === true) ? widgetClasses.selected : ""}`}
                        >True</button>
                        <button
                            onClick={() => props.setFilter(field.key, field.many ? "some" : "eq", false)}
                            className={`${(filterValue?.eq === false || filterValue?.some === false) ? widgetClasses.selected : ""}`}
                        >False</button>
                        <button onClick={() => props.setFilter(field.key, field.many ? "some" : "eq", undefined)}>
                            Clear
                        </button>
                    </div>
                );
                break;

            // Text Search String:
            case "string":
                fieldComp = (
                    <input
                        id={field.key}
                        type="text"
                        value={filterValue?.like ?? ""}
                        onChange={(event) => props.setFilter(field.key, "like", event.target.value)}
                        ref={index === 0 ? firstInputRef : undefined}
                    />
                );
                break;

            // Numeric Min/Max:
            case "number":
                fieldComp = (<div className={`${widgetClasses.row} ${classes.minMax}`}>
                    <label>Min</label>
                    <input
                        id={field.key}
                        type="number"
                        value={filterValue?.gte ?? ""}
                        onChange={(event) => props.setFilter(field.key, "gte", parseInt(event.target.value))}
                        ref={index === 0 ? firstInputRef : undefined}
                    />
                    <label>Max</label>
                    <input
                        id={field.key}
                        type="number"
                        value={filterValue?.lte ?? ""}
                        onChange={(event) => props.setFilter(field.key, "lte", parseInt(event.target.value))}
                    />
                </div>);
                break;

            // ID Selection List:
            case "list":
                let displayName: string = "Select";
                if (typeof filterValue === "object") {
                    displayName = filterValue.some?.name ?? filterValue.eq?.name;
                }
                const popupRef: string = `filter-${field.key}`;
                fieldComp = (
                    <div className={`${widgetClasses.row} ${classes.list}`}>
                        <button onClick={() => {
                            addPopup(popupRef, (
                                <div className={`${widgetClasses.popupList}`}>
                                    <ResourceSelector
                                        resourceNames={[field.resourceName ?? field.key]}
                                        onSelect={(resource, dto) => {
                                            removePopup(popupRef);
                                            props.setFilter(field.key, field.many ? "some" : "eq", {
                                                id: dto.id,
                                                name: dto.name,
                                                field,
                                            });
                                        }}
                                        onEmpty={field.optional ? (resource) => {
                                            removePopup(popupRef);
                                            props.setFilter(field.key, field.many ? "some" : "eq", {
                                                id: null,
                                                name: "None",
                                                field,
                                            });
                                        } : undefined}
                                        onCancel={() => removePopup(popupRef)}
                                    />
                                </div>
                            ));
                        }}>
                            {displayName}
                        </button>
                        <button onClick={() => props.setFilter(field.key, field.many ? "some" : "eq", undefined)}>
                            Clear
                        </button>
                    </div>
                );
                break;
        }
        if (fieldComp) {
            filterComps.push((
                <div key={field.key} className={`${widgetClasses.row} ${props.filter[field.key] ? "active" : ""}`}>
                    <span>{field.name}</span>
                    {fieldComp}
                </div>
            ));
        }
    });

    // Return Component:
    return (
        <div className={`${classes.container} ${props.className ?? ""}`}>
            {filterComps}
        </div>
    );
}