import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { CardCommon, CardDto, CardRequestDto } from "dtos/shard/card.dto";
import { UserDto } from "dtos/user.dto";

import variables from "styles/variables";
import widgets from "styles/widgets";
import Rte from "../rte";
import { cardService } from "services";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
});

export default function CardEditor(props: {
    user?: UserDto, // Optional dto of the user.
    card?: Partial<CardDto>,
    preview?: Partial<CardDto>,
    onPreviewUpdate?: (previewDto: Partial<CardDto>) => void,
    onClose?: () => void,
    onSave?: (card: CardDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshDto: Partial<CardDto> | undefined = props.card ?? props.preview;
    const freshCommon: CardCommon = {
        name: freshDto?.name ?? "",
        subspecies: freshDto?.subspecies,
        morph: freshDto?.morph,
        shardCost: freshDto?.shardCost ?? 0,
        manaCost: freshDto?.manaCost ?? 0,
        health: freshDto?.health,
        effect: freshDto?.effect,
        lore: freshDto?.lore,
    };
    const freshRequestDto: CardRequestDto = {
        id: freshDto?.id,
        ...freshCommon,
        elementId: freshDto?.element?.id ?? "",
        cardTypeId: freshDto?.cardType?.id ?? "",
        titleId: freshDto?.title?.id,
        actionIds: freshDto?.actions?.map(action => action.id),
        creatureEffectId: freshDto?.creatureEffect?.id,
    };
    const freshPreviewDto: Partial<CardDto> = props.preview ? structuredClone(props.preview) : props.card ? structuredClone(props.card) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup, onEdit] = useEditorActions(props.user, "card", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate, (field, value) => {
        // Template Update:
        if (field === "cardTypeId") {
            setCardTemplate(value?.template.name);
        }
    });

    // States:
    const [cardTemplate, setCardTemplate] = useState(props.card?.cardType?.template.name ?? previewDto.cardType?.template.name);

    // Properties:
    if (!cardTemplate && previewDto.cardType?.template) {
        setCardTemplate(previewDto.cardType?.template.name);
    }
    const displayName: string = props.card ? [props.card.subspecies, props.card.name, props.card.morph].join(" ") : "";

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
        setCardTemplate(props.card?.cardType?.template.name);
    }, [props.card]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Actions:
    const reset = () => {
        setRequestDto(freshRequestDto);
        setCardTemplate(props.card?.cardType?.template.name);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate((props.card ?? previewDto) as CardDto);
        }
    };

    // Components:
    let typedComponents: JSX.Element | undefined;
    if (cardTemplate === "creature") {
        typedComponents = (
            <>
                <hr/>
                <h4>Creature</h4>
                <div className={`${widgetClasses.row}`}>
                    <label>Health</label>
                    <input id="health" type="number" value={requestDto.health ?? 1} onChange={onNumberChange} />
                </div>
                <div className={`${widgetClasses.row}`}>
                    <button id="actionA" onClick={() => onPopup("action", "actionIds", true, 0)}>Action A: {previewDto.actions ? previewDto.actions[0]?.name ?? "Please Select" : "Please Select"}</button>
                    <button id="actionB" onClick={() => onPopup("action", "actionIds", true, 1)}>Action B: {previewDto.actions ? previewDto.actions[1]?.name ?? "Please Select" : "Please Select"}</button>
                </div>
                <div className={`${widgetClasses.row}`}>
                    <button id="creatureEffect" onClick={() => onPopup("creatureEffect", "creatureEffectId", true)}>Creature Effect: {previewDto.creatureEffect?.name ?? "Please Select"}</button>
                    {previewDto.creatureEffect && <button id="creatureEffectEdit" onClick={() => onEdit("creatureEffect", previewDto.creatureEffect!)} className={`side-button`}>Edit</button>}
                </div>
            </>
        );
    } else if (cardTemplate === "spell") {
        typedComponents = (
            <>
                <hr/>
                <h4>Spell Effect</h4>
                <Rte id="effect" value={requestDto.effect ?? ""} onChange={onRteChange} />
            </>
        );
    }
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Subspecies</label>
                <input id="subspecies" type="text" value={requestDto.subspecies ?? ""} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Morph</label>
                <input id="morph" type="text" value={requestDto.morph ?? ""} onChange={onTextChange} />
            </div>

            <hr/>
            <h4>Attributes</h4>
            <div className={`${widgetClasses.row}`}>
                <button id="element" onClick={() => onPopup("element", "elementId", true)}>Element: {previewDto.element?.name ?? "Please Select"}</button>
                <button id="cardType" onClick={() => onPopup("cardType", "cardTypeId", true)}>Type: {previewDto.cardType?.name ?? "Please Select"}</button>
            </div>
            <div className={`${widgetClasses.row}`}>
                <button id="title" onClick={() => onPopup("title", "titleId", false)}>Title: {previewDto.title?.name ?? "None"}</button>
            </div>

            <hr/>
            <h4>Costs</h4>
            <div className={`${widgetClasses.row}`}>
                <label>Shard Cost</label>
                <input id="shardCost" type="number" value={requestDto.shardCost ?? 0} onChange={onNumberChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Mana Cost</label>
                <input id="manaCost" type="number" value={requestDto.manaCost ?? 0} onChange={onNumberChange} />
            </div>

            {typedComponents}

            <hr/>
            <h4>Lore</h4>
            <textarea id="lore" value={requestDto.lore ?? ""} onChange={onTextChange} />

            <hr/>
            <EditorControls
                editorName="card"
                service={cardService}
                subject={props.card}
                subjectName={displayName}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />

            <hr/>
        </div>
    );
}