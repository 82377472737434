import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { ActionDto, ActionListDto, ActionPartialDto, ActionQueryDto, ActionRequestDto } from "dtos/shard/action.dto";

export class ActionService extends AbstractResourceService<ActionDto, ActionRequestDto, ActionPartialDto, ActionListDto, ActionQueryDto>
{
    public actionDtos: Record<string, ActionDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "action", "Action", "Actions", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "power",
                name: "Power",
                type: "number",
            },
            {
                key: "elementId",
                name: "Element",
                type: "list",
                resourceName: "element",
            },
            {
                key: "actionTypeId",
                name: "Type",
                type: "list",
                resourceName: "actionType",
            },
            {
                key: "actionEffectId",
                name: "Effect",
                type: "list",
                resourceName: "actionEffect",
                optional: true,
            },
        ]);
    }
}