import { createUseStyles } from "react-jss";

import { RuleDto } from "dtos/shard/rule.dto";

import widgets from "styles/widgets";
import { RuleCategoryDto } from "dtos/shard/rule-category.dto";
import { addPopup, removePopup } from "components/widgets/popups";
import RuleEditor from "components/widgets/editors/rule-editor";
import SelectPopup from "components/popups/select-popup";
import { ruleService } from "services";

const styles = createUseStyles({
    content: {
        width: "calc(100% - 8em)",
        marginRight: "2em",
        flexGrow: 1,
    },
    header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        gap: "0.5em",

        "& > h1": {
            flexGrow: 1,
            textAlign: "center",
        },
        "& > button": {
            flexGrow: 0,
            flexBasis: 1,
            minWidth: "10em",
            height: "2em",
            marginTop: "0.5em",
        },
    },

    "@media screen and (max-width: 720px)": {
        container: {
            position: "relative",
            height: "auto",
        },
    },
});

export default function Rule(props: {
    rule?: RuleDto,
    ruleCategory?: RuleCategoryDto,
    ref?: React.MutableRefObject<any>,
    admin: boolean,
}): JSX.Element
{
    // Actions:
    const editRule = () => {
        if (!props.rule || !props.ruleCategory) {
            return;
        }
        const popupRef: string = `rules-edit-${props.rule.id}`;
        addPopup(popupRef, (
            <div className={`${widgetClasses.popup} ${widgetClasses.popupLarge} ${widgetClasses.popupWide}`}>
                <h1>Edit {props.rule.name} - {props.ruleCategory.name}</h1>
                <RuleEditor
                    rule={props.rule}
                    preview={props.rule}
                    onSave={() => removePopup(popupRef)}
                    onClose={() => removePopup(popupRef)}
                />
            </div>
        ));
    };
    const deleteRule = () => {
        if (!props.rule || !props.ruleCategory) {
            return;
        }
        const popupRef: string = `rules-delete-${props.rule.id}`;
        addPopup(popupRef, (
            <SelectPopup
                popupRef={popupRef}
                title={`Delete ${props.rule.name} - ${props.ruleCategory.name}?`}
                options={["Delete", "Cancel"]}
                warningOption={"Delete"}
                onSelect={(option: string) => {
                    if (option === "Delete") {
                        ruleService.destroy(props.rule!);
                    }
                    removePopup(popupRef);
                }}
            />
        ));
    };

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Components:
    let content: JSX.Element = (
        <h1>Select a Category and Rule</h1>
    );
    if (props.rule) {
        content = (<>
            <div className={`${classes.header}`}>
                <h1>{props.rule.name}</h1>
                {props.admin ? (<>
                    <button onClick={() => editRule()}>Edit Rule</button>
                    <button onClick={() => deleteRule()} className={`${widgetClasses.warning}`}>Delete Rule</button>
                </>) : ""}
            </div>
            <div dangerouslySetInnerHTML={{ __html: props.rule.description}} className={`${classes.content}`} />
        </>);
    }

    // Return Component:
    return (
        <div className={`${widgetClasses.column} ${widgetClasses.grow}`}>
            {content}
        </div>
    );
}