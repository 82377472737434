import { ElementDto } from "dtos/shard/element.dto";

export default function CardRenderFilters(props: {
    element?: ElementDto,
}): JSX.Element
{
    // Color Filter:
    let colorFilter: JSX.Element | undefined;
    if (props.element) {
        let c: any = props.element.color.substring(1).split("")
        if(c.length == 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = "0x" + c.join("")
        var colors = [(c >> 16) & 255, (c >> 8) & 255, c & 255]
        colorFilter = (
            <filter id={"shard-card-frame-color-" + props.element.name}>
                <feColorMatrix in="SourceGraphic" result="colored" type="matrix" values={
                    (colors[0] / 255) + " 0 0 0 0 " + " 0 "
                    + (colors[1] / 255) + " 0 0 0 "
                    + " 0 0 " + (colors[2] / 255) + " 0 0  "
                    + " 0 0 0 1 0"
                }/>
            </filter>
        )
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 0, height: 0} }>

            <filter id="shard-card-text-stroke-filter">
                <feMorphology in="SourceAlpha" result="dilated" operator="dilate" radius="2" />
                <feFlood floodColor="#000000" floodOpacity="1" result="flooded" />
                <feComposite in="flooded" in2="dilated" operator="in" result="outline" />

                <feMerge>
                    <feMergeNode in="outline" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>

            <filter id="shard-card-element-filter">
                <feMorphology in="SourceAlpha" result="dilated" operator="dilate" radius="1" />
                <feFlood floodColor="#000000" floodOpacity="1" result="flooded" />
                <feComposite in="flooded" in2="dilated" operator="in" result="outline" />

                <feMerge>
                    <feMergeNode in="outline" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>

            <filter id="shard-card-frame-filter">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
                <feSpecularLighting in="blur" surfaceScale="3" specularConstant="0.5" specularExponent="10" result="specOut" lightingColor="white">
                    <fePointLight x="-5000" y="-10000" z="20000" />
                </feSpecularLighting>
                <feComposite in="specOut" in2="SourceAlpha" operator="in" result="specOut2" />
                <feComposite in="SourceGraphic" in2="specOut2" operator="arithmetic" k1="0" k2="1" k3="1" k4="0" result="litPaint" />

                <feMorphology in="SourceAlpha" result="dilated" operator="dilate" radius="2" />
                <feFlood floodColor="#000000" floodOpacity="1" result="flooded" />
                <feComposite in="flooded" in2="dilated" operator="in" result="outline" />

                <feMerge>
                    <feMergeNode in="outline" />
                    <feMergeNode in="litPaint" />
                </feMerge>
            </filter>

            {colorFilter}
        </svg>
    );
}