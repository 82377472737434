import { createUseStyles } from "react-jss";
import { Route, Routes } from "react-router-dom";

import widgets from "styles/widgets";
import { UserState } from "states/user.state";

import Home from "components/pages/home";
import Void from "components/pages/void";
import Rules from "components/pages/rules";
import Cards from "components/pages/cards";
import Decks from "components/pages/decks";
import Store from "components/pages/store";
import Popups from "./widgets/popups";
import Notifications from "./widgets/notifications";

const styles = createUseStyles({
    content: {
        display: "flex",
        marginLeft: "2em",
        marginRight: "2em",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        flexGrow: 1,
    },
});

export default function Content(props: {
    user: UserState,
}): JSX.Element
{
    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <div className={`${classes.content}`}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/rules" element={<Rules user={props.user} />} />
                <Route path="/cards/:resourceName?/:selectedId?" element={<Cards user={props.user} />} />
                <Route path="/decks/:selectedId?/:manage?" element={<Decks user={props.user} />} />
                <Route path="/store" element={<Store />} />
                <Route path="*" element={<Void />} />
            </Routes>
            <Popups />
            <Notifications user={props.user} />
        </div>
    );
}