import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { TitleDto, TitleListDto, TitlePartialDto, TitleQueryDto, TitleRequestDto } from "dtos/shard/title.dto";

export class TitleService extends AbstractResourceService<TitleDto, TitleRequestDto, TitlePartialDto, TitleListDto, TitleQueryDto>
{
    public titleDtos: Record<string, TitleDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "title", "Title", "Titles", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "description",
                name: "Description",
                type: "string",
            },
            {
                key: "templateId",
                name: "Template",
                type: "list",
                resourceName: "template",
            },
        ]);
    }
}