import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CreatureEffectDto, CreatureEffectListDto, CreatureEffectPartialDto, CreatureEffectQueryDto, CreatureEffectRequestDto } from "dtos/shard/creature-effect.dto";

export class CreatureEffectService extends AbstractResourceService<CreatureEffectDto, CreatureEffectRequestDto, CreatureEffectPartialDto, CreatureEffectListDto, CreatureEffectQueryDto>
{
    public creatureEffectDtos: Record<string, CreatureEffectDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "creatureEffect", "Creature Effect", "Creature Effects", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "effect",
                name: "Effect",
                type: "string",
            },
        ]);
    }
}