import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CardEntryDto, CardEntryListDto, CardEntryPartialDto, CardEntryQueryDto, CardEntryRequestDto } from "dtos/shard/card-entry.dto";
import { ResourceFilter } from "types/resource-filter";

export class CardEntryService extends AbstractResourceService<CardEntryDto, CardEntryRequestDto, CardEntryPartialDto, CardEntryListDto, CardEntryQueryDto>
{
    public cardEntryDtos: Record<string, CardEntryDto> = {};
    public readonly renderListeners: ((event: string, data: CardEntryDto) => void)[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "cardEntry", "Card Entry", "Card Entries", [
            {
                key: "reference",
                name: "Reference",
                type: "number",
            },
            {
                key: "normalCount",
                name: "Normal Count",
                type: "number",
            },
            {
                key: "foilCount",
                name: "Foil Count",
                type: "number",
            },
            {
                key: "cardId",
                name: "Card",
                type: "list",
                resourceName: "card",
            },
            {
                key: "cardSetId",
                name: "Card Set",
                type: "list",
                resourceName: "cardSet",
                sortField: "code",
            },
            {
                key: "rarityId",
                name: "Rarity",
                type: "list",
                resourceName: "rarity",
            },
            {
                key: "artistId",
                name: "Artist",
                type: "list",
                resourceName: "artist",
            },
            {
                key: "secret",
                name: "Secret",
                type: "boolean",
                filterBuilder: (filter: Record<string, any>, operator: string, value?: string | number | boolean | ResourceFilter) => filter.cardSet = {
                    ...filter.cardSet,
                    secret: value,
                },
                hideSort: true,
                admin: true,
            },
        ]);

        // Render Event:
        this.socket.on("cardEntry.render", (cardEntryDto: CardEntryDto) => {
            this.renderListeners.forEach(listener => listener("cardEntry.render", cardEntryDto));
        });
    }

    /**
     * Adds a render event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addRenderListener(listener: (event: string, dto: CardEntryDto) => void): () => void
    {
        // Add Listener:
        this.renderListeners.push(listener);

        // Remove Listeners:
        return () => {
            const index: number = this.renderListeners.indexOf(listener);
            if (index >= 0) {
                this.renderListeners.splice(index, 1);
            }
        };
    }
}