import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { cardSetService } from "services";
import { CardSetCommon, CardSetDto, CardSetRequestDto } from "dtos/shard/card-set.dto";
import { UserDto } from "dtos/user.dto";

import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
});

export default function CardSetEditor(props: {
    user?: UserDto, // Optional dto of the user.
    cardSet?: Partial<CardSetDto>,
    preview?: Partial<CardSetDto>,
    onPreviewUpdate?: (previewDto: Partial<CardSetDto>) => void,
    onClose?: () => void,
    onSave?:(cardSet: CardSetDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: CardSetCommon = {
        name: props.cardSet?.name ?? "",
        code: props.cardSet?.code ?? "",
        secret: props.cardSet?.secret ?? false,
    };
    const freshRequestDto: CardSetRequestDto = {
        id: props.cardSet?.id,
        ...freshCommon,
        cardSetTypeId: props.cardSet?.cardSetType?.id ?? "",
    };
    const freshPreviewDto: Partial<CardSetDto> = props.preview ? structuredClone(props.preview) : props.cardSet ? structuredClone(props.cardSet) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "cardSet", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate);

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
    }, [props.cardSet]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // CardSets:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate(props.cardSet ?? previewDto);
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name ?? ""} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Code</label>
                <input id="code" type="text" value={requestDto.code ?? ""} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row} ${widgetClasses.capitalize}`}>
                <button id="cardSetType" onClick={() => onPopup("cardSetType", "cardSetTypeId", true)}>Set Type: {previewDto.cardSetType?.name ?? "Please Select"}</button>
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Secret</label>
                <input id="secret" type="checkbox" checked={requestDto.secret ?? ""} onChange={onCheckboxChange} />
            </div>

            <hr/>
            <EditorControls
                editorName="cardSet"
                service={cardSetService}
                subject={props.cardSet}
                subjectName={props.cardSet?.name ?? ""}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <hr/>
        </div>
    );
}