import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { CardDto } from "dtos/shard/card.dto";
import { useEffect } from "react";
import { removeNotification } from "components/widgets/notifications";

const styles = createUseStyles({
    container: {
        width: "16em",
        height: "auto",
        padding: "1em",
        textAlign: "center",

        borderRadius: "0.25em",
        backgroundColor: variables.colors.neutral + "AA",
    },
});

export default function CardNotification(props: {
    notificationId: string,
    card: CardDto,
    new?: boolean,
}): JSX.Element
{
    // Effects:
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            removeNotification(props.notificationId);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, []);

    // Properties:
    const displayName: string = [props.card.subspecies, props.card.name, props.card.morph].join(" ");

    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <div className={`${classes.container}`} onClick={() => removeNotification(props.notificationId)}>
            The Card: {displayName} has been {props.new ? "created" : "updated"}!
        </div>
    );
}