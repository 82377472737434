import { createUseStyles } from "react-jss";

import variables from "styles/variables";

const styles = createUseStyles({
    container: {
        maxWidth: "40em",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        textAlign: "center",
        overflowX: "auto",
        flexGrow: 0,
        gap: "0.25em",

        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "0.25em",
        borderColor: variables.colorsLight.foreground + "00",
        backgroundColor: variables.colors.neutral + "77",
    },
    button: {
        fontSize: "10pt",
        fontWeight: "normal",
        minWidth: "2em",
        maxWidth: "2em",
        height: "2em",
        padding: 0,
        textAlign: "center",
        flexGrow: 0,

        color: variables.colorsLight.foreground,
        borderColor: variables.colorsLight.secondary + "77",
        backgroundColor: variables.colors.secondary + "AA",

        "&:hover": {
            backgroundColor: variables.colors.hover + "AA",
        },
        "&.$selected": {
            borderColor: variables.colorsLight.average + "77",
            backgroundColor: variables.colors.average + "AA",
            "&:hover": {
                backgroundColor: variables.colors.average,
            },
        },
    },
    selected: {
        backgroundColor: variables.colors.average,
        "&:hover": {
            backgroundColor: variables.colors.average,
        },
    },

    "@media screen and (max-width: 720px)": {
        container: {
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
});

export default function Pages(props: {
    className?: string,
    pageTotal: number,
    pageCurrent: number,
    setPage: (page: number) => void,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Return Component:
    const buttons: JSX.Element[] = [];
    for (let i = 1; i <= (props.pageTotal ?? 0); i++) {
        buttons.push((
            <button
                key={i}
                className={`${classes.button} ${i === props.pageCurrent ? classes.selected : ""}`}
                onClick={() => props.setPage(i)}
            >
                {i}
            </button>
        ));
    }
    return (
        <div className={`${classes.container} ${props.className ?? ""}`}>
            {buttons}
        </div>
    );
}