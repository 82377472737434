import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import variables from "styles/variables";
import { userService } from "services";
import Content from "components/content";

import widgets from "styles/widgets";
import Header from "components/header/header";
import Render from "components/pages/render";
import Fullscreen from "components/pages/fullscreen";
import { highQuality } from "utils";

/**
 * The global quality setting.
 */
export let quality: string = "High";

/**
 * Sets the global quality setting.
 */
export let setQuality: (quality: string) => void = () => {};

const styles = createUseStyles({
    app: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        overflowX: "hidden",
        overflowY: "auto",
        flexDirection: "column",
        alignItems: "normal",
        justifyContent: "start",

        backgroundImage: `
            linear-gradient(
                0deg,
                rgba(33, 18, 67, 0.5),
                rgba(33, 18, 67, 0.25),
                rgba(33, 18, 67, 0.5)
            ),
            url("/images/background.png")
        `,
        color: variables.colors.foreground,
        textShadow: `2px 2px #000000AA`,
    },
    bob: {
        animation: highQuality() ? "$bob-animation" : "none",
        animationDuration: 20000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "ease-in-out",
        animationDirection:  "alternate",
        animationFillMode: "both",
    },
    "@keyframes bob-animation": {
        "0%": {
            backgroundPositionX: "-10vh",
            backgroundPositionY: "-5vh",
        },
        "25%": {
            backgroundPositionY: "5vh",
        },
        "50%": {
            backgroundPositionY: "-5vh",
        },
        "100%": {
            backgroundPositionX: "10vh",
            backgroundPositionY: "5vh",
        },
    },
});

export default function App(): JSX.Element {
    // States:
    const [userState, setUserState] = useState(userService.getUserState());
    const [compQuality, setCompQuality] = useState(localStorage.getItem("quality") ?? "High");
    quality = compQuality;
    setQuality = (quality) => {
        setCompQuality(quality);
        localStorage.setItem("quality", quality);
        window.location.reload();
    };

    // Effects:
    useEffect(() => userService.addListener(() => {
        setUserState(userService.getUserState());
    }));
    useEffect(() => userService.addListener((event, dto) => setUserState(userService.getUserState())));

    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/render/:cardEntryId?" element={<Render />} />
                <Route path="/fullscreen/:cardEntryIds?" element={<Fullscreen userState={userState} />} />
                <Route path="*" element={
                    <div className={`${classes.app} ${classes.bob} ${widgetClasses.gap}`}>
                        <Header user={userState} />
                        <Content user={userState} />
                    </div>
                } />
            </Routes>
        </BrowserRouter>
    );
}