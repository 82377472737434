import { highQuality } from "utils";

const variables = {
    colors: {
        background: "#000000",
        foreground: "#F8FAFC",
        positive: "#15803D",
        average: "#CA8A04",
        negative: "#B91C1C",
        primary: "#1D4ED8",
        secondary: "#0369A1",
        neutral: "#990099",
        hover: "#14B8A6",
    },
    colorsLight: {
        background: "#64748B",
        foreground: "#FFFFFF",
        positive: "#4ADE80",
        average: "#EAB308",
        negative: "#EF4444",
        primary: "#3B82F6",
        secondary: "#0EA5E9",
        neutral: "#DD66DD",
        hover: "#99F6E4",
    },
    colorsDark: {
        background: "#0F172A",
        foreground: "#E2E8F0",
        positive: "#14532D",
        average: "#713F12",
        negative: "#7F1D1D",
        primary: "#1E3A8A",
        secondary: "#EAB308",
        neutral: "#260026",
        hover: "#134E4A",
    },
    box: function() {
        return {
            background: this.colors.background + "77",
            boxShadow: highQuality() ?  `0 0 10px 10px ${this.colors.background + "77"}` : "none",
        };
    },
};

export default variables;