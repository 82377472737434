import { createUseStyles } from "react-jss";

import { DeckDto, DeckEntryDto } from "dtos/shard/deck.dto";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";

import variables from "styles/variables";
import widgets from "styles/widgets";
import ResourceSelector from "./resource-selector";
import DeckEntryList from "./deck-entry-list";
import { addPopup, removePopup } from "./popups";
import Popup from "components/popups/popup";
import CardDisplay from "./displays/card-display";
import { addNotification, removeNotification } from "./notifications";
import { deckService } from "services";
import { UserDto } from "dtos/user.dto";

const styles = createUseStyles({
    header: {
        flexGrow: 0,
        alignItems: "center",

        "& button": {
            minWidth: "16em",
            flexGrow: 0,
            flexBasis: 1,
        },
    },
    list: {
        gap: "0.5em",
        overflowX: "hidden",
    },

    "@media screen and (max-width: 720px)": {
        list: {
            minHeight: "calc(100vh - 2em)",
        },
    },
});

export default function DeckCardManager(props: {
    user?: UserDto,
    deck: DeckDto,
    cardEntry?: CardEntryDto,
    onClose: () => void,
    onTts?: (deckId: string) => void,
}): JSX.Element
{
    // Properties:
    const displayCode: string | undefined = props.cardEntry ? `${props.cardEntry.cardSet.code}-${props.cardEntry.reference.toString().padStart(3, "0")}` : undefined;
    const totalCount: number = props.deck.deckEntries.reduce((total, deckEntryDto) => total + deckEntryDto.count, 0);
    const uniqueCount: number = new Set(props.deck.deckEntries.filter(deckEntryDto => deckEntryDto.cardId)).size;
    const creatureCount: number = 0;
    const spellCount: number = 0;
    const elementCount: number = 0;
    const typeCount: number = 0;

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Actions:
    const getItemCount = (dto: Record<string, any>) => {
        const cardDto: CardDto = dto as CardDto;
        const cardEntryDto: CardEntryDto = cardDto.cardEntries[0]; // TODO: Add additional prop for card entry selection.
        return props.deck.deckEntries.find(deckEntry => deckEntry.cardEntryId === cardEntryDto.id)?.count ?? 0;
    };

    const preview = (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => {
        switch (resourceName) {
            case "card":
                const cardDto: CardDto = dto as CardDto;
                const cardEntryDto: CardEntryDto | undefined = secondaryDto ? secondaryDto as CardEntryDto : cardDto.cardEntries[0];
                const inDeck: boolean = !!props.deck.deckEntries.find(deckEntry => deckEntry.cardEntryId === cardEntryDto.id);
                const displayName: string = [cardDto.subspecies, cardDto.name, cardDto.morph].join(" ");
                addPopup("deck-builder-card-preview", (
                    <Popup
                        component={(
                            <div className={`${widgetClasses.row}`}>
                                <CardDisplay
                                    user={props.user}
                                    card={dto}
                                    cardEntry={cardEntryDto}
                                    stats={false}
                                    onClose={() => removePopup("deck-builder-card-preview")}
                                />
                                <div className={`${widgetClasses.column}`}>
                                    <h1>{[cardDto.subspecies, cardDto.name, cardDto.morph].join(" ")}</h1>
                                    <div className={`${widgetClasses.column} ${widgetClasses.left}`}>
                                        <span>Element: {cardDto.element.name}</span>
                                        <span>Type: {cardDto.cardType.name}</span>
                                        <span>Title: {cardDto.title?.name ?? "None"}</span>
                                        <hr />
                                        <h3>Effect:</h3>
                                        <span dangerouslySetInnerHTML={{ __html: cardDto.effect ?? cardDto.creatureEffect?.effect ?? "<p>None</p>" }}></span>
                                    </div>
                                </div>
                            </div>
                        )}
                        className={`${widgetClasses.popupList}`}
                        confirmText={inDeck ? "Set as Deck Cover Card" : undefined}
                        confirmAction={inDeck ? () => {
                            props.deck.coverCardEntryId = cardEntryDto.id;
                            deckService.modify(props.deck);
                            addNotification("deck-builder-cover-card-set", (
                                <div key="deck-builder-limit" className={`${widgetClasses.notification} ${widgetClasses.confirm}`} onClick={() => removeNotification("deck-builder-cover-card-set")}>
                                    Deck Cover Card set to: {displayName}
                                </div>
                            ));
                        } : undefined}
                    />
                ));
                break;
        }
    };

    const addToDeck = (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => {
        let cardDto: CardDto | undefined;
        let cardEntryDto: CardEntryDto | undefined;

        // Parse Dtos:
        switch (resourceName) {
            case "card":
                cardDto = dto as CardDto;
                cardEntryDto = secondaryDto ? secondaryDto as CardEntryDto : cardDto.cardEntries[0];
                break;
        }
        if (!cardDto || !cardEntryDto) {
            console.error("[Deck Card Manager] Unable to add card and/or card entry for dtos:", dto, secondaryDto);
            return;
        }
        const displayName: string = [cardDto.subspecies, cardDto.name, cardDto.morph].join(" ");

        // Check Count:
        const cardCount: number = props.deck.deckEntries
            .filter(entry => entry.cardId === cardDto!.id)
            .reduce((count, entry) => count + entry.count, 0);
        if (cardCount >= 5) {
            addNotification("deck-builder-limit", (
                <div key="deck-builder-limit" className={`${widgetClasses.notification} ${widgetClasses.warning}`} onClick={() => removeNotification("deck-builder-limit")}>
                    5 card limit reached for {displayName}.
                </div>
            ));
            return;
        }

        // Modify Deck:
        const deckEntryDto: DeckEntryDto | undefined = props.deck.deckEntries.find(entry => entry.cardEntryId === cardEntryDto!.id);
        if (deckEntryDto) {
            deckEntryDto.count++;
        } else {
            props.deck.deckEntries.push({
                cardEntryId: cardEntryDto.id,
                cardId: cardDto.id,
                count: 1,
            });
        }
        if (!props.deck.coverCardEntryId) {
            props.deck.coverCardEntryId = cardEntryDto.id;
        }
        deckService.modify(props.deck);
    };

    const removeFromDeck = (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => {
        let cardDto: CardDto | undefined;
        let cardEntryDto: CardEntryDto | undefined;

        // Parse Dtos:
        switch (resourceName) {
            case "card":
                cardDto = dto as CardDto;
                cardEntryDto = secondaryDto ? secondaryDto as CardEntryDto : cardDto.cardEntries[0];
                break;
        }
        if (!cardDto || !cardEntryDto) {
            console.error("[Deck Card Manager] Unable to remove card and/or card entry for dtos:", dto, secondaryDto);
            return;
        }

        // Modify Deck:
        const index: number = props.deck.deckEntries.findIndex(entry => entry.cardEntryId === cardEntryDto!.id);
        if (index < 0) {
            return;
        }
        let deckEntryDto: DeckEntryDto = props.deck.deckEntries[index];
        deckEntryDto.count--;
        if (deckEntryDto.count <= 0) {
            props.deck.deckEntries.splice(index, 1);
        }
        deckService.modify(props.deck);
    };

    // Return Component:
    return (
        <div className={`${widgetClasses.column}`}>
            <div className={`${widgetClasses.row} ${classes.header}`}>
                <h2>Editing Deck: {props.deck.name}</h2>
                <div className={`${widgetClasses.column}`}>
                    <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                        <span>Total Cards: {totalCount}</span>
                        <span>Unique Cards: {uniqueCount}</span>
                    </div>
                    {/* <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                        <span>Creatures: {creatureCount}</span>
                        <span>Spells: {spellCount}</span>
                    </div>
                    <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                        <span>Elements: {elementCount}</span>
                        <span>Types: {typeCount}</span>
                    </div> */}
                </div>
                <button className={`${widgetClasses.warning}`} onClick={() => props.onClose()}>
                    Close Card Manager
                </button>
            </div>
            <hr />
            <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                <DeckEntryList
                    deck={props.deck}
                    cardEntry={props.cardEntry}
                    onSelect={preview}
                    onAdd={addToDeck}
                    onRemove={removeFromDeck}
                    onTts={props.onTts ? () => props.onTts!(props.deck.id) : undefined}
                />
                <ResourceSelector
                    className={""}
                    resourceNames={["card"]}
                    resourceName={"card"}
                    selectedId={undefined}
                    getItemCount={getItemCount}
                    onSelect={preview}
                    onAdd={addToDeck}
                    onRemove={removeFromDeck}
                />
            </div>
        </div>
    );
}