import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";
import { UserDto } from "dtos/user.dto";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import CardView from "../views/card-view";
import { useEffect, useState } from "react";
import { cardService } from "services";
import CardEntryEditor from "../editors/card-entry-editor";
import displayStyles from "styles/display-styles";
import { cardAnimations, toggleCardAnimations, statsPanel, toggleStatsPanel } from "utils";

const styles = createUseStyles({
    card: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: "1em",

        flexGrow: 1,
    },

    "@media screen and (max-width: 1280px)": {
        card: {
            "& > div": {
                height: "100vh",
            },
            flexGrow: 1,
        },
    },
});

export default function CardEntryDisplay(props: {
    user?: UserDto, // Optional dto of the user.
    className?: string, // Optional css class name to use.
    cardEntry?: Partial<CardEntryDto>, // The card entry to display.
    onSave?:(cardEntry: CardEntryDto) => void, // A function to call when created or updated.
    onClose?: () => void, // A function to call when the close button is clicked, if not set the close button is hidden.
}): JSX.Element {
    const navigate = useNavigate();

    // States:
    const [stats, setStats] = useState(statsPanel());
    const [animations, setAnimations] = useState(cardAnimations());
    const [card, setCard] = useState(undefined as CardDto | undefined);
    const [cardEntryPreview, setCardEntryPreview] = useState(props.cardEntry);

    // Effects:
    useEffect(() => {
        setCardEntryPreview(props.cardEntry);
        if (props.cardEntry && props.cardEntry.card) {
            const removeListener = cardService.addListener((event, dto) => {
                if (dto && dto.id === props.cardEntry?.card?.id) {
                    setCard(dto);
                }
            });
            cardService.show(props.cardEntry.card);
            return removeListener;
        } else {
            setCard(undefined);
        }
    }, [props.cardEntry]);

    // Actions:
    const downloadImage = async (type: string) => {
        if (!props.cardEntry || !props.cardEntry.cardSet || !props.cardEntry.reference) {
            console.log(`[Card Display] Cannot download due to missing Card Entry.`);
            return;
        }
        const displayCode: string = `${props.cardEntry.cardSet.code}-${props.cardEntry.reference.toString().padStart(3, "0")}`;
        const url: string = `${process.env.REACT_APP_IMAGES_URL}/${type}/${displayCode}`;
        console.log(`[Card Display] Downloading: ${url}`);
        const imageBuffer: Buffer = await axios.get(url, {
            responseType: "arraybuffer",
        }).then(response => response.data);
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([imageBuffer]));
        link.download = `${displayCode}-${type}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const fullscreen = () => {
        if (!cardEntryPreview) {
            return;
        }
        console.log(`[Card Entry Display] Navigating to fullscreen display...`);
        navigate(`/fullscreen/${cardEntryPreview.id}`);
    }

    // Styles:
    const widgetClasses = widgets();
    const displayClasses = displayStyles();
    const classes = styles();

    // Components:
    let statsComp: JSX.Element | undefined;
    if (stats) {
        // Card Stats (Editor for Admins):
        if (props.user?.admin) {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${displayClasses.editor}`}>
                        <h3>Editor:</h3>
                        <CardEntryEditor
                            user={props.user}
                            cardEntry={props.cardEntry}
                            preview={cardEntryPreview}
                            onPreviewUpdate={setCardEntryPreview}
                            onSave={props.onSave}
                            onClose={props.onClose}
                            onCardChange={setCard}
                        />
                    </div>
                </div>
            );
        } else {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <ul>
                        <li>Set: {props.cardEntry?.cardSet?.name ?? "Unavailable"}</li>
                        <li>Normal Count: {props.cardEntry?.normalCount ?? 1}</li>
                        <li>Foil Count: {props.cardEntry?.foilCount ?? 0}</li>
                        <li>Rarity: {props.cardEntry?.rarity?.name ?? "Unavailable"}</li>
                        <li>Artist: {props.cardEntry?.artist?.name ?? "None"}</li>
                    </ul>
                </div>
            );
        }
    }
    
    // Return Component:
    return (
        <div className={`${displayClasses.container} ${props.className ?? ""}`}>
            <div className={`${displayClasses.controls}`}>
                <div className={`${displayClasses.button} ${cardAnimations() ? displayClasses.buttonActive : ""}`} onClick={() => setAnimations(toggleCardAnimations())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconAnimation}`}></div>
                </div>
                <div className={`${displayClasses.button} ${cardEntryPreview ? "" : displayClasses.buttonDisabled}`} onClick={fullscreen}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconFullscreen}`}></div>
                </div>
                <div className={`${displayClasses.button} ${statsPanel() ? displayClasses.buttonActive : ""}`} onClick={() => setStats(toggleStatsPanel())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconStats}`}></div>
                </div>
                <div className={`${displayClasses.button}`} onClick={() => downloadImage("full")}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconDownload}`}></div>
                </div>
                <div className={`${displayClasses.button}`} onClick={() => downloadImage("cutout")}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconCutout}`}></div>
                </div>
                {props.onClose && <div className={`${displayClasses.button}`} onClick={() => props.onClose!()}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconClose}`}></div>
                </div>}
            </div>
            <div className={`${displayClasses.view}`}>
                <div className={`${classes.card}`}>
                    <CardView user={props.user} card={card} cardEntry={cardEntryPreview} animation={animations} />
                </div>
                {statsComp}
            </div>
        </div>
    );
}