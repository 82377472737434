import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";
import { UserDto } from "dtos/user.dto";
import displayStyles from "styles/display-styles";
import { DeckDto } from "dtos/shard/deck.dto";

import DeckEditor from "../editors/deck-editor";
import { setEditGlobal, editGlobal } from "components/pages/decks";
import DeckView from "../views/deck-view";

const styles = createUseStyles({
    container: {
        "& > button": {
            padding: "0.5em",
            width: "100%",
        },
    },
});

export default function DeckDisplay(props: {
    user?: UserDto, // Optional dto of the user.
    className?: string, // Optional css class name to use.
    deck?: Partial<DeckDto>, // The card set dto to display, if undefined a blank deck will be displayed instead.
    onSave?: (deck: DeckDto) => void, // A function to call when created or updated.
    onClose?: () => void, // A function to call when the close button is clicked, if not set the close button is hidden.
}): JSX.Element
{
    // Properties:
    const editable: boolean = !!props.user && (props.user?.admin || !props.deck?.userId || props.deck?.userId === props.user.id);

    // States:
    const [deckPreview, setDeckPreview] = useState(props.deck);

    // Effects:
    useEffect(() => {
        setDeckPreview(props.deck);
    }, [props.deck]);

    // Styles:]
    const widgetClasses = widgets();
    const displayClasses = displayStyles();
    const classes = styles();

    // Components:
    let statsComp: JSX.Element | undefined;

    // Deck Stats (Editor for Owners and Admins):
    if (editable) {
        statsComp = (
            <div className={`${displayClasses.stats}`}>
                <div className={`${displayClasses.editor}`}>
                    <DeckEditor
                        user={props.user!}
                        deck={props.deck}
                        preview={deckPreview}
                        onPreviewUpdate={setDeckPreview}
                        onSave={props.onSave}
                        onClose={props.onClose}
                    />
                </div>
            </div>
        );
    } else {
        statsComp = (
            <div className={`${displayClasses.stats}`}>
                <div className={`${widgetClasses.column}`}>
                    <h3>Stats:</h3>
                    <ul>
                        <li>Name: {props.deck?.name}</li>
                    </ul>
                </div>
            </div>
        );
    }
    
    // Return Component:
    return (
        <div className={`${displayClasses.container} ${classes.container} ${props.className ?? ""}`}>
            <DeckView deck={deckPreview} />
            {editable && props.deck?.id && <button onClick={() => setEditGlobal(true)} className={`${widgetClasses.confirm}`}>
                Manage Cards
            </button>}
            {statsComp}
        </div>
    );
}