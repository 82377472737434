import { useEffect } from "react";
import widgets from "styles/widgets";

export default function Store(): JSX.Element
{
    // Spread Shop:
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://shop.spreadshirt.co.uk/shopfiles/shopclient/shopclient.nocache.js';
        document.body.appendChild(script);
    }, []);

    // Component:
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.page} ${widgetClasses.narrow}`}>
            <div className={`${widgetClasses.section}`}>
                <h1>Shard TCG Store</h1>
                <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                    <div className={`${widgetClasses.column}`}>
                        <h2>Booster Packs</h2>
                        <p>Each Booster Pack contains 18 cards which can be used to expand the effectiveness of your deck. 3 packs alone is also enough to get started creating a new deck.</p>
                        <p>Booster Packs always include 2 rares and 1 rare/ultra rare/legendary. Now also available as holofoil shinies!</p>
                        <h3>Buy Booster Packs Here:</h3>
                        <a href="https://www.thegamecrafter.com/games/permalink/4AA3306E-9488-11EB-A5B9-F08DBA630D99">Booster Set 01: Origins</a>
                    </div>
                    <div className={`${widgetClasses.column}`}>
                        <h2>Starter Decks</h2>
                        <p>Coming Soon! Starter Decks contain a handpicked selection of 50 cards from the Booster Pack sets for you to get started playing Shard TCG straight away.</p>
                    </div>
                    <div className={`${widgetClasses.column}`}>
                        <h2>Structure Decks</h2>
                        <p>Structure Decks contain an exclusive selection of 50 cards where some cards are not available in Booster Packs at the time of release.</p>
                        <p>Each Structure deck includes 9 holofoil shinies!</p>
                        <p>Structure Decks are more advanced and follow a strict theme but can also be upgraded and customised using Booster Packs.</p>
                        <h3>Buy Structure Decks Here:</h3>
                        <a href="https://www.thegamecrafter.com/games/shard-tcg-angel-structure-deck-containing-9-foils-">Angel Structure Deck</a>
                        <a href="https://www.thegamecrafter.com/games/shard-tcg-demon-structure-deck-containing-9-foils-">Demon Structure Deck</a>
                    </div>
                </div>
            </div>
            <div className={`${widgetClasses.section}`}>
                <h1>Merch</h1>
                <div id="myShop">
                    <a href="https://shop.spreadshirt.co.uk/shardtcg" target="_blank">Shard TCG Merch</a>
                </div>
            </div>
        </div>
    );
}