import { createUseStyles } from "react-jss";

import { ResourceField } from "types/resource-field";

import widgets from "styles/widgets";

const styles = createUseStyles({
    container: {
        "& button": {
            fontSize: "10pt",
            height: "2em",
            minWidth: "10em",
            maxWidth: "10em",
            flexGrow: 1,
            flexBasis: "10em",
        },
    },
});

export default function Sort(props: {
    className?: string,
    fields?: ResourceField[],
    sort: Record<string, string | undefined>,
    setSort: (field: string, direction: string) => void,
}): JSX.Element
{
    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Return Component:
    return (
        <div className={`${classes.container} ${props.className ?? ""}`}>
            {props.fields?.filter(field => !field.many && !field.hideSort).map(field => (
                <div key={field.key} className={`${widgetClasses.row} ${props.sort[field.key] ? "active" : ""}`}>
                    <span>{field.name}</span>
                    <button
                        onClick={() => props.setSort(field.key, "asc")}
                        className={`${props.sort[field.key] === "asc" ? widgetClasses.selected : ""}`}
                    >Ascending</button>
                    <button
                        onClick={() => props.setSort(field.key, "desc")}
                        className={`${props.sort[field.key] === "desc" ? widgetClasses.selected : ""}`}
                    >Descending</button>
                </div>
            ))}
        </div>
    );
}