import { highQuality } from "utils";

/**
 * Creates mouse tile functionality.
 * @returns A mouse move and mouse leave function for binding to components to tilt.
 */
export function useMouseTilt(): [
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
] {
    // Actions:
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element: HTMLDivElement = event.currentTarget.children[0] as HTMLDivElement;
        element.style.transform = "";
        element.style.animation = "";
    };
    const mouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!highQuality()) {
            mouseLeave(event);
            return;
        }
        const element: HTMLDivElement = event.currentTarget.children[0] as HTMLDivElement;
        const bounds: DOMRect = element.getBoundingClientRect();
        const threshold: number = 10;
        const horizontal = (event.clientX - bounds.left) / element.clientWidth;
        const vertical = (event.clientY - bounds.top) / element.clientHeight;
        const rotateX = (horizontal * threshold - threshold / 2).toFixed(2);
        const rotateY = (threshold / 2 - vertical * threshold).toFixed(2);
        element.style.transform = `
            perspective(${element.clientWidth}px)
            rotateX(${rotateY}deg)
            rotateY(${rotateX}deg)
            scale3d(1, 1, 1)
        `;
        element.style.animation = "none";
    };

    // Return Actions:
    return [mouseMove, mouseLeave];
}