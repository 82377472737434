import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { TitleDto } from "dtos/shard/title.dto";

const styles = createUseStyles<any, {
    title?: TitleDto,
    selected: boolean,
}>({
    tile: {
        borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.title?.template.name === "creature" ? variables.colors.negative + "77" : variables.colors.primary),
        backgroundColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.title?.template.name === "creature" ? variables.colors.negative + "77" : variables.colors.primary + "77"),

        "&:hover": {
            borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : (props.title?.template.name === "creature" ? variables.colors.negative : variables.colors.primary),
            backgroundColor: (props) => props.selected ? variables.colorsLight.average + "AA" : (props.title?.template.name === "creature" ? variables.colors.negative + "AA" : variables.colors.primary + "AA"),
        },
    },
});

export default function TitleItem(props: {
    id: string,
    title?: TitleDto,
    selected: boolean,
    onClick?: (title?: TitleDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.title);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.title,
        selected: props.selected,
    });
    const classes = styles({
        title: props.title,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.title ? (<>
                    <div className={`${widgetClasses.column}`}>
                        <div className={`${listItemClasses.title}`}>
                            <span>{props.title.name}</span>
                        </div>
                        <span>{props.title.template.name.replace(props.title.template.name[0], props.title.template.name[0].toUpperCase())} Title</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Title</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}