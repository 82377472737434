import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";
import { UserDto } from "dtos/user.dto";
import displayStyles from "styles/display-styles";
import { ActionTypeDto } from "dtos/shard/action-type.dto";

import ActionTypeView from "../views/action-type-view";
import ActionTypeEditor from "../editors/action-type-editor";
import { cardAnimations, toggleCardAnimations, statsPanel, toggleStatsPanel } from "utils";

const styles = createUseStyles({});

export default function ActionTypeDisplay(props: {
    user?: UserDto, // Optional dto of the user.
    className?: string, // Optional css class name to use.
    actionType?: Partial<ActionTypeDto>, // The actionType dto to display, if undefined a blank actionType will be displayed instead.
    onSave?: (actionType: ActionTypeDto) => void, // A function to call when created or updated.
    onClose?: () => void, // A function to call when the close button is clicked, if not set the close button is hidden.
}): JSX.Element
{
    // States:
    const [stats, setStats] = useState(statsPanel());
    const [animations, setAnimations] = useState(cardAnimations());
    const [actionTypePreview, setActionTypePreview] = useState(props.actionType);

    // Effects:
    useEffect(() => {
        setActionTypePreview(props.actionType);
    }, [props.actionType]);

    // Styles:
    const widgetClasses = widgets();
    const displayClasses = displayStyles();
    const classes = styles();

    // Components:
    let statsComp: JSX.Element | undefined;
    if (stats) {
        // ActionType Stats (Editor for Admins):
        if (props.user?.admin) {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${displayClasses.editor}`}>
                        <h3>Editor:</h3>
                        <ActionTypeEditor
                            user={props.user}
                            actionType={props.actionType}
                            preview={actionTypePreview}
                            onPreviewUpdate={setActionTypePreview}
                            onSave={props.onSave}
                            onClose={props.onClose}
                        />
                    </div>
                </div>
            );
        } else {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${widgetClasses.column}`}>
                        <h3>Stats:</h3>
                        <ul>
                            <li>Name: {props.actionType?.name}</li>
                        </ul>
                    </div>
                </div>
            );
        }
    }
    
    // Return Component:
    return (
        <div className={`${displayClasses.container} ${props.className ?? ""}`}>
            <div className={`${displayClasses.controls}`}>
                <div className={`${displayClasses.button} ${cardAnimations() ? displayClasses.buttonActive : ""}`} onClick={() => setAnimations(toggleCardAnimations())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconAnimation}`}></div>
                </div>
                <div className={`${displayClasses.button} ${statsPanel() ? displayClasses.buttonActive : ""}`} onClick={() => setStats(toggleStatsPanel())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconStats}`}></div>
                </div>
                {props.onClose && <div className={`${displayClasses.button}`} onClick={() => props.onClose!()}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconClose}`}></div>
                </div>}
            </div>
            <ActionTypeView actionType={actionTypePreview} animation={animations} />
            {statsComp}
        </div>
    );
}