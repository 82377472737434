import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { StatusDto } from "dtos/status.dto";
import { FileUploadDto } from "dtos/file-upload.dto";
import { ErrorDto } from "dtos/error.dto";

export class ImagesService extends AbstractService<StatusDto | ErrorDto | undefined>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Upload Events:
        this.socket.on("image.upload", async (dto: StatusDto) => {
            this.listeners.forEach(listener => listener("image.upload", dto));

            // Force Image Reloads:
            const artUrl: string = `/images/card/art/${dto.name}.png`;
            await fetch(artUrl, {
                cache: "reload",
                mode: "no-cors",
            });
            document.body.querySelectorAll(`img[src='${artUrl}']`).forEach(img => (img as HTMLImageElement).src = artUrl);

            const thumbnailUrl: string = `/images/card/thumbnail/${dto.name}.png`;
            await fetch(thumbnailUrl, {
                cache: "reload",
                mode: "no-cors",
            });
            document.body.querySelectorAll(`img[src='${thumbnailUrl}']`).forEach(img => (img as HTMLImageElement).src = thumbnailUrl);
        });
        this.socket.on("image.upload.error", (dto: ErrorDto) => {
            this.listeners.forEach(listener => listener("image.upload.error", dto));
            console.error("[Image Service] File upload error:", dto.message);
        });
    }

    /**
     * Uploads a file to the server.
     * @param name The name of the image to upload.
     * @param role The role of the image (art, source, etc).
     * @param file The image file to parse.
     * @param requestId The request id for reacting to success events.
     */
    public async upload(name: string, role: string, file: File, requestId?: string): Promise<void>
    {
        console.log("[Image Service] Uploading file...");

        // Read File to Blob:
        const blob: string | ArrayBuffer | null = await new Promise((resolve, reject) => {
            const reader: FileReader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

        console.log(file)

        this.socket.emit("image.upload", {
            name,
            role,
            blob,
            mime: file.type,
            requestId,
        } as FileUploadDto);
    }
}