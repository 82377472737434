import { io } from "socket.io-client"

import { AbstractService } from "services/abstract.service";

export class ShardGateway extends AbstractService<void>
{
    public status: string = "connecting"; // The status of the accounts connection.

    /**
     * Constructor
     */
    public constructor()
    {
        console.log(`[Shard Gateway] Connecting to server at ${process.env.REACT_APP_API_URL ?? ""}...`);
        super(io(process.env.REACT_APP_API_URL ?? "", {
            transports: ["websocket"],
        }));

        // Connection:
        this.socket.once("connect", () => {
            console.log("[Shard Gateway] Connected to server.");
            this.status = "connected";
            this.listeners.forEach(listener => listener("connect"));
        });

        this.socket.on("connect_error", (err) => {
            console.log(`[Shard Gateway] Connection error: ${err.message}`);
            this.status = "error";
            this.listeners.forEach(listener => listener("connect_error"));
        });

        this.socket.on("disconnect", (err) => {
            console.log("[Shard Gateway] Disconnected from server.");
            this.status = "disconnected";
            this.listeners.forEach(listener => listener("disconnect"));
        });
    }

    /**
     * Forces a disconnect, can be used to logout.
     */
    public disconnect(): void
    {
        console.log("[Shard Gateway] Disconnecting from server...");
        this.socket.disconnect();
        this.socket.connect();
    }
}