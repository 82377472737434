import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { ElementDto } from "dtos/shard/element.dto";

const styles = createUseStyles<any, {
    element?: Partial<ElementDto>,
}>({
    container: {
        display: "flex",
        minWidth: "20em",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    elementWrapper: {
        position: "relative",
        width: "12em",
        height: "12em",
    },
    element: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        borderStyle: "outset",
        borderRadius: "50%",
        borderColor: (props) => props.element ? props.element.color : variables.colorsLight.foreground,
        backgroundColor: (props) => props.element ? props.element.color + "DD" : variables.colors.neutral,

        "& img": {
            width: "8em",
        },
    },

    tilt: {
        animation: "$tilt-animation",
        animationDuration: 6000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "linear",
        animationDirection:  "normal",
        animationFillMode: "both",
    },
    "@keyframes tilt-animation": {
        "0%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(-10deg)
            `,
        },
        "13%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(-15deg)
            `,
        },
        "25%": {
            transform: `
                perspective(2000px)
                rotateX(10deg)
                rotateY(-10deg)
            `,
        },
        "38%": {
            transform: `
                perspective(2000px)
                rotateX(15deg)
                rotateY(0deg)
            `,
        },
        "50%": {
            transform: `
                perspective(2000px)
                rotateX(10deg)
                rotateY(10deg)
            `,
        },
        "63%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(15deg)
            `,
        },
        "75%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(10deg)
            `,
        },
        "88%": {
            transform: `
                perspective(2000px)
                rotateX(-15deg)
                rotateY(0deg)
            `,
        },
        "100%": {
            transform: `
                perspective(2000px)
                rotateX(-10deg)
                rotateY(-10deg)
            `,
        },
    },
});

export default function ElementView(props: {
    element?: Partial<ElementDto>,
    animation?: boolean,
}): JSX.Element
{
    // Styles:
    const widgetClasses = widgets();
    const classes = styles({
        element: props.element,
    });

    // Actions:
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.element?.name ?? "New Element"}</h3>
            <div
                onMouseMove={props.animation ? mouseMove : () => {}}
                onMouseLeave={props.animation ? mouseLeave : () => {}}
                className={`${classes.elementWrapper} ${widgetClasses.mouseTilt}`}
            >
                <div className={`${classes.element} ${props.animation ? classes.tilt : ""}`}>
                    {props.element && <img src={`/images/card/elements/${props.element.name?.toLowerCase()}.svg`} />}
                </div>
            </div>
        </div>
    );
}