import { createUseStyles } from "react-jss";

import { TabItem } from "types/tab-item.d";

import variables from "styles/variables";
import widgets from "styles/widgets";

const styles = createUseStyles({
    container: {
        width: "calc(100% - 2em)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "1em",

        "& > button": {
            minWidth: "8em",
            minHeight: "3.5em",
            flexGrow: 0,
        },
    },
    selected: {
        backgroundColor: variables.colors.average,
        "&:hover": {
            backgroundColor: variables.colors.average,
        },
    },
});

export default function Tabs(props: {
    tabs: TabItem[],
    selected?: string,
}): JSX.Element
{
    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <div className={`${classes.container}`}>
            {props.tabs.map(tab => (
                <button key={tab.key} onClick={tab.action} className={tab.key === props.selected ? classes.selected : ""}>
                    {tab.name}
                </button>
            ))}
        </div>
    );
}