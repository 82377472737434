import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CardDto, CardListDto, CardPartialDto, CardQueryDto, CardRequestDto } from "dtos/shard/card.dto";
import { ResourceFilter } from "types/resource-filter";

export class CardService extends AbstractResourceService<CardDto, CardRequestDto, CardPartialDto, CardListDto, CardQueryDto>
{
    public cardDtos: Record<string, CardDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "card", "Card", "Cards", [
            {
                key: "name",
                name: "Name/Species",
                type: "string",
                sortPriority: -100,
            },
            {
                key: "subspecies",
                name: "Subspecies",
                type: "string",
                sortPriority: -100,
            },
            {
                key: "morph",
                name: "Morph",
                type: "string",
                sortPriority: -100,
            },
            {
                key: "shardCost",
                name: "Shard Cost",
                type: "number",
            },
            {
                key: "manaCost",
                name: "Mana Cost",
                type: "number",
            },
            {
                key: "health",
                name: "Health",
                type: "number",
            },
            {
                key: "cardType",
                name: "Template",
                type: "list",
                resourceName: "template",
                filterBuilder: (filter: Record<string, any>, operator: string, value?: string | number | boolean | ResourceFilter) => filter.cardType = {
                    ...filter.cardType,
                    templateId: {
                        eq: (value as ResourceFilter).id,
                    },
                },
                sortPriority: 100,
                sortBuilder: (sort: Record<string, any>, direction: string) => sort.cardType = {
                    ...sort.cardType,
                    template: {
                        name: direction,
                    },
                },
            },
            {
                key: "elementId",
                name: "Element",
                type: "list",
                resourceName: "element",
            },
            {
                key: "cardTypeId",
                name: "Type",
                type: "list",
                resourceName: "cardType",
            },
            {
                key: "titleId",
                name: "Title",
                type: "list",
                resourceName: "title",
                optional: true,
            },
            {
                key: "actions",
                name: "Actions",
                type: "list",
                resourceName: "action",
                join: "actionId",
                many: true,
            },
            {
                key: "creatureEffectId",
                name: "Creature Effect",
                type: "list",
                resourceName: "creatureEffect",
            },
            {
                key: "effect",
                name: "Spell Effect",
                type: "string",
                sortPriority: -100,
            },
            {
                key: "secret",
                name: "Secret",
                type: "boolean",
                filterBuilder: (filter: Record<string, any>, operator: string, value?: string | number | boolean | ResourceFilter) => filter.cardEntries = {
                    ...filter.cardEntries,
                    some: {
                        cardSet: {
                            secret: value,
                        },
                    },
                },
                hideSort: true,
                admin: true,
            },
        ]);
    }
}