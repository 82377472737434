import { ResourceField } from "../types/resource-field";
import { ListItem } from "../types/list-item";
import { CommonId, ListCommon, QueryCommon } from "dtos/common.dto";
import { PaginationDto } from "dtos/pagination.dto";
import { AbstractResourceService } from "services/abstract-resource.service";
import { ResourceFilter } from "../types/resource-filter";
import { ttsService } from "services";

export interface IResourceService
{
    readonly name: string;
    readonly singular: string;
    readonly plural: string;
    readonly fields: ResourceField[];

    index(query?: QueryCommon, requestId?: string): Promise<void>;
    show(dto?: any): Promise<void>;
    addListener(listener: (event: string, dto: any) => void) : () => void;
}

export interface IResource
{
    readonly service: IResourceService;
    readonly requestId: string;
    selected?: Record<string, any>;
    page: number;
    pageSize: number;
    filter: Record<string, Record<string, undefined | number | string | ResourceFilter>>;
    sort: Record<string, string | undefined>;
    items: Record<string, any>[];
    pagination?: PaginationDto;
    readonly setSelected: (item: Record<string, any>) => void;
    readonly setPage: (page: number) => void;
    readonly setPageSize: (pageSize: number) => void;
    readonly setFilter: (filter: Record<string, Record<string, undefined | number | string | ResourceFilter>>) => void;
    readonly setSort: (sort: Record<string, string | undefined>) => void;
    readonly itemBuilder: (item: ListItem) => JSX.Element;
    readonly displayBuilder?: (dto: Record<string, any>, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => JSX.Element;
    readonly editorBuilder?: (dto?: Record<string, any>, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => JSX.Element;
    readonly relationServices: IResourceService[];
    readonly ttsExport?: (requestId?: string) => void;

    index(): Promise<void>;
    reset(): void;
}

export class ResourceBo<
    DtoType extends CommonId | undefined,
    RequestDtoType,
    PartialDtoType extends Partial<RequestDtoType>,
    ListDtoType extends ListCommon<NonNullable<DtoType>>,
    QueryDtoType extends QueryCommon
> implements IResource
{
    public selected?: Record<string, any>;
    public page: number;
    public pageSize: number;
    public filter: Record<string, Record<string, undefined | number | string | ResourceFilter>>;
    public sort: Record<string, string | undefined>;
    public items: Record<string, any>[] = [];
    public pagination?: PaginationDto;
    public readonly setSelected: (item: Record<string, any>) => void;
    public readonly setPage: (page: number) => void;
    public readonly setPageSize: (pageSize: number) => void;
    public readonly setFilter: (filter: Record<string, Record<string, undefined | number | string | boolean | ResourceFilter>>) => void;
    public readonly setSort: (sort: Record<string, string | undefined>) => void;
    public readonly ttsExport?: (requestId?: string) => void;

    /**
     * Constructor
     * @param service The service used by this resource.
     * @param requestId The request id used to identify index events for this resource.
     * @param defaultFilter The default filter to set.
     * @param defaultSort The default sort to set.
     * @param selectedState The state of the currently selected item in this resource.
     * @param pageState The state of the current page of items selected for this resource.
     * @param pageSizeState The state of the current page size for this resource.
     * @param filterState The state of the filter last set for this resource.
     * @param sortState The state of the sort last set for this resource.
     * @param itemBuilder A function to create a list item component for items in this resource.
     * @param displayBuilder A function to create a display component for items in this resource.
     * @param editorBuilder A function to create a editor component for items in this resource.
     * @param relationServices An optional array of other resources that nested objects through relations may be updated on.
     * @param enableTtsExport Set to true to enalbe tts export, defaults to false.
     */
    public constructor(
        public readonly service: AbstractResourceService<DtoType, RequestDtoType, PartialDtoType, ListDtoType, QueryDtoType>,
        public readonly requestId: string,
        private readonly defaultFilter: Record<string, any>,
        private readonly defaultSort: Record<string, string | undefined>,
        selectedState: [Record<string, any> | undefined, React.Dispatch<React.SetStateAction<any | undefined>>],
        pageState: [number, React.Dispatch<React.SetStateAction<any | undefined>>],
        pageSizeState: [number, React.Dispatch<React.SetStateAction<any | undefined>>],
        filterState: [Record<string, any>, React.Dispatch<React.SetStateAction<any | undefined>>],
        sortState: [Record<string, string | undefined>, React.Dispatch<React.SetStateAction<any | undefined>>],
        public readonly itemBuilder: (item: ListItem) => JSX.Element,
        public readonly displayBuilder?: (dto: Record<string, any>, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => JSX.Element,
        public readonly editorBuilder?: (dto?: Record<string, any>, onSave?: (dto: Record<string, any>) => void, onClose?: () => void) => JSX.Element,
        public readonly relationServices: IResourceService[] = [],
        enableTtsExport: boolean = false,
    )
    {
        [this.selected, this.setSelected] = selectedState;
        [this.page, this.setPage] = pageState;
        [this.pageSize, this.setPageSize] = pageSizeState;
        [this.filter, this.setFilter] = filterState;
        [this.sort, this.setSort] = sortState;

        if (enableTtsExport) {
            this.ttsExport = (requestId?: string) => {
                ttsService.export(this.service.name, this.createQuery(), requestId);
            };
        }
    }

    /**
     * Creates a query based on this resource's filter, sort, etc.
     * @returns A query using this resoruce's filter, sort, etc for indexing and other requests.
     */
    public createQuery(): QueryDtoType
    {
        // Parse Filter:
        const filter: Record<string, any> = {};
        Object.keys(this.filter).forEach(field => {
            if (!filter[field]) {
                filter[field] = {};
            }
            Object.keys(this.filter[field]).forEach(operator => {
                let value: undefined | string | number | ResourceFilter = this.filter[field][operator];
                const resourceField: ResourceField | undefined = this.service.fields.find(serviceField => serviceField.key == field);

                // Filter Builder:
                if (resourceField?.filterBuilder) {
                    resourceField.filterBuilder(filter, operator, value);
                    return;
                }

                // Primitive Property:
                if (typeof value !== "object") {
                    filter[field][operator] = value;
                    return;
                }

                // Resource Filter Field:
                if (value.field.many) {
                    filter[field][operator] = { [value.field.join ?? "id"]: value.id };
                    return;
                }
                filter[field][operator] = value.id;
            });
        });

        // Parse Sort:
        const sort: Record<string, any> = {};
        Object.keys(this.sort)
            .sort((a, b) => {
                const resourceFieldA: ResourceField | undefined = this.service.fields.find(serviceField => serviceField.key == a);
                const resourceFieldB: ResourceField | undefined = this.service.fields.find(serviceField => serviceField.key == b);
                return (resourceFieldB?.sortPriority ?? 0) - (resourceFieldA?.sortPriority ?? 0);
            })
            .forEach(field => {
                const direction: string | undefined = this.sort[field];
                if (!direction) {
                    return;
                }
                const resourceField: ResourceField | undefined = this.service.fields.find(serviceField => serviceField.key == field);

                // Sort Bulder:
                if (resourceField?.sortBuilder) {
                    resourceField.sortBuilder(sort, direction);
                    return;
                }

                // Primitive Property:
                if (!resourceField?.resourceName) {
                    sort[field] = direction;
                    return;
                }

                // Resource Sort Field:
                sort[resourceField.resourceName] = {
                    [resourceField.sortField ?? "name"]: direction,
                };
            });

        // Create Index Request:
        return {
            limit: this.pageSize,
            page: this.page,
            filter,
            sort,
        } as QueryDtoType;
    }

    /**
     * Performs an index request using this resource's service and states.
     */
    public async index(): Promise<void>
    {
        // Create Index Request:
        return this.service.index(this.createQuery(), this.requestId);
    }

    /**
     * Resets all filtering, sorting, etc on this resource.
     */
    public reset(): void
    {
        this.setPage(1);
        this.setPageSize(50);
        this.setFilter({});
        this.setFilter(this.defaultFilter);
        this.setSort(this.defaultSort);
    }
}