import { useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";

export type PopupEntry = {
    reference: string,
    component: JSX.Element,
};

/**
 * The global popups.
 */
let popups: PopupEntry[] = [];

/**
 * Sets the current array of active popups.
 */
let setPopups: (popups: PopupEntry[]) => void = () => {};

/**
 * Adds a global popup to display.
 * @param reference The unique popup reference name, used to remove or replace popups (the replaced popup will become the latest popup).
 * @param component The component to use for the popup.
 */
export function addPopup(reference: string, component: JSX.Element): void
{
    removePopup(reference);
    popups.push({
        reference,
        component,
    });
    setPopups(popups);
}

/**
 * Removes a global popup.
 * @param reference The reference name of the popup to remove.
 * @return Returns true if a popup was found by the provided reference and removed.
 */
export function removePopup(reference: string): boolean
{
    const index: number = popups.findIndex(popup => popup.reference === reference);
    if (index < 0) {
        return false;
    }
    popups.splice(index, 1);
    setPopups(popups);
    return true;
}

const styles = createUseStyles({
    
});

export default function Popups(props: {}): JSX.Element
{
    // States:
    const [state, setState] = useState({ popups });
    setPopups = (popups) => setState({ popups });

    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <>
            {state.popups.map(entry => (
                <div key={entry.reference} className={`${widgetClasses.overlay}`}>{entry.component}</div>
            ))}
        </>
    );
}