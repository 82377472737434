import { createUseStyles } from "react-jss";

import { ActionEffectDto } from "dtos/shard/action-effect.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
});

export default function ActionEffectView(props: {
    actionEffect?: Partial<ActionEffectDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.actionEffect?.name ?? "New Action Effect"}</h3>
        </div>
    );
}