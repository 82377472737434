import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { RarityDto, RarityListDto, RarityPartialDto, RarityQueryDto, RarityRequestDto } from "dtos/shard/rarity.dto";

export class RarityService extends AbstractResourceService<RarityDto, RarityRequestDto, RarityPartialDto, RarityListDto, RarityQueryDto>
{
    public rarityDtos: Record<string, RarityDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "rarity", "Rarity", "Rarities", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}