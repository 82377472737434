import { createUseStyles } from "react-jss";

import variables from "styles/variables";

export default createUseStyles<any, {
    create: boolean,
    selected: boolean,
}>({
    container: {
        display: "flex",
        minWidth: "12em",
        maxWidth: "12em",
        minHeight: "6em",
        maxHeight: "6em",
        flexGrow: 0,

        flexDirection: "column",
        justifyContent: "start",

        "& span": {
            fontSize: "10pt",
            width: "auto",
            textAlign: "center",
        },
        "& img": {
            width: "1.5em",
            height: "auto",
            marginRight: "0.25em",
        },
        "&:hover": {
            zIndex: 1,
        },
    },
    card: {
        minHeight: "16.32em",
        maxHeight: "16.32em",

        "& span": {
            fontSize: "12pt",
            width: "auto",
            textAlign: "center",
        },
    },
    tile: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: "100%",
        flexGrow: 1,
        overflow: "hidden",

        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        borderWidth: 1,
        borderRadius: "0.5em",
        backgroundSize: "cover",
        borderStyle: (props) => props.create ? "outset" : "dashed",
        borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : variables.colors.neutral,
        backgroundColor: (props) => props.selected ? variables.colorsLight.average + "77" : variables.colors.neutral + "77",

        "&:hover": {
            borderColor: (props) => props.selected ? variables.colorsLight.average + "77" : variables.colors.hover,
            backgroundColor: (props) => props.selected ? variables.colorsLight.average + "AA" : variables.colors.hover + "77",
        },
        "& > div": {
            padding: "0.5em",
        },
    },
    title: {
        display: "flex",
        height: "auto",

        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",

        "& span": {
            fontSize: "14pt",
            fontWeight: "bold",
        },
    },
});