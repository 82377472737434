import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { RuleCategoryDto, RuleCategoryListDto, RuleCategoryPartialDto, RuleCategoryQueryDto, RuleCategoryRequestDto } from "dtos/shard/rule-category.dto";

export class RuleCategoryService extends AbstractResourceService<RuleCategoryDto, RuleCategoryRequestDto, RuleCategoryPartialDto, RuleCategoryListDto, RuleCategoryQueryDto>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "ruleCategory", "Rule Category", "Rule Categories", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}