import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { CreatureEffectDto } from "dtos/shard/creature-effect.dto";

export default function CreatureEffectItem(props: {
    id: string,
    creatureEffect?: CreatureEffectDto,
    selected: boolean,
    onClick?: (creatureEffect?: CreatureEffectDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.creatureEffect);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.creatureEffect,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${widgetClasses.mouseTilt}`}>
                {props.creatureEffect ? (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.creatureEffect.name}</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Creature Effect</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}