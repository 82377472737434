import { useState } from "react";

/**
 * Creates and manages a preview and request dto.
 * @returns The preview and request dto with update functions for both.
 */
export function useEditorPreview<DtoType, RequestDtoType>(
    freshPreviewDto: Partial<DtoType>, // The initial preview dto to start with or the parent component dto to use when onPreviewUpdate is set.
    freshRequestDto: RequestDtoType, // The initial request dto to start with.
    onPreviewUpdate?: (previewDto: Partial<DtoType>) => void, // Optional preview update to call for when a parent container's preview state should be used instead.
): [
    Partial<DtoType>,
    RequestDtoType,
    (previewDto: Partial<DtoType>) => void,
    (requestDto: RequestDtoType) => void,
] {
    // States:
    const [previewDto, setPreviewDto] = useState(freshPreviewDto);
    const [requestDto, setRequestDto] = useState(freshRequestDto);
    
    // Actions:
    const previewUpdate = (previewDto: Partial<DtoType>) => {
        if (onPreviewUpdate) {
            onPreviewUpdate(previewDto);
            return;
        }
        setPreviewDto(previewDto);
    };

    // Return Actions:
    return [onPreviewUpdate ? freshPreviewDto : previewDto, requestDto, previewUpdate, setRequestDto];
}