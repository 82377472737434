import { createUseStyles } from "react-jss";

import { TitleDto } from "dtos/shard/title.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
    title: {
        textTransform: "capitalize",
    },
});

export default function TitleView(props: {
    title?: Partial<TitleDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.title?.name ?? "New Title"}</h3>
            <p className={`${classes.title}`}>{props.title?.template?.name ?? "New"} Title</p>
        </div>
    );
}