import { useState } from "react";
import { createUseStyles } from "react-jss";

import { CommonId, ListCommon } from "dtos/common.dto";
import { PaginationDto } from "dtos/pagination.dto";
import { IResource } from "bos/resource.bo";
import { ResourceField } from "types/resource-field";
import { TabItem } from "types/tab-item";

import variables from "styles/variables";
import widgets from "styles/widgets";
import Sort from "./navigator/sort";
import Limit from "./navigator/limit";
import Pages from "./navigator/pages";
import Filter from "./navigator/filter";
import Resources from "./navigator/resources";

const styles = createUseStyles({
    container: {
        width: "auto",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "nowrap",
        overflowY: "hidden",
        gap: 0,

        transition: "height 1s ease-in-out",
    },
    navigation: {
        width: "auto",
        flexWrap: "wrap",
        gap: "0.25em",

        "& > button": {
            fontWeight: "normal",
            height: "2.5em",
            whiteSpace: "nowrap",
            flexGrow: 0,
            flexBasis: 1,

            borderColor: variables.colorsLight.foreground + "77",
        },
        "& > $resourcesButton": {
            minWidth: "auto",
        },
    },
    resourcesButton: {},
    menu: {
        width: "auto",
        marginTop: "1em",
        flexWrap: "wrap",
        justifyContent: "start",
        gap: "0.5em",

        fontSize: "10pt",

        "& > div": {
            width: "auto",
            height: "2em",
            borderWidth: 1,
            flexGrow: 0,
            flexWrap: "nowrap",
            textAlign: "center",

            borderStyle: "solid",
            borderRadius: "0.25em",
            borderColor: variables.colorsLight.primary + "77",
            backgroundColor: variables.colors.primary + "AA",
        },
        "& > div:hover": {
            borderColor: variables.colorsLight.hover + "77",
            backgroundColor: variables.colors.hover + "AA",
        },
        "& > div.active": {
            borderColor: variables.colorsLight.average + "77",
            backgroundColor: variables.colors.average + "AA",
        },
        "& > div > span": {
            verticalAlign: "middle",
            minWidth: "10em",
            maxWidth: "10em",
        },
    },
});

export default function Navigator(props: {
    admin?: boolean,
    resource: IResource,
    list?: ListCommon<CommonId>,
    pagination?: PaginationDto,
    selectedType?: string,
    initialMenu?: string,
    types?: TabItem[],
    resources?: IResource[],
    fields?: ResourceField[],
    filter?: Record<string, any>,
    sort?: Record<string, string | undefined>,
    setResource?: (resource: string) => void,
    setPage?: (page: number) => void,
    setPageSize?: (size: number) => void,
    setFilter?: (field: string, operator: string, value?: string | number | boolean | Record<string, any>) => void,
    setSort?: (field: string, direction: string) => void,
    reset?: () => void,
    onTts?: () => void,
    onCancel?: () => void,
}): JSX.Element
{
    // States:
    const [menuState, setMenuState] = useState(props.initialMenu ?? "");

    // Actions:
    const resourceSelection = () => {
        if (menuState === "resource") {
            setMenuState("");
            return;
        }
        if (props.resources && props.setResource) {
            setMenuState("resource");
        }
    };
    const toggleMenu = (menu: string) => {
        if (menuState === menu) {
            setMenuState("");
            return;
        }
        setMenuState(menu);
    };

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Menu Components:
    let menuComp: JSX.Element | JSX.Element[] | undefined;
    switch(menuState) {
        // Resource Menu:
        case "resource":
            menuComp = (<Resources
                className={`${widgetClasses.row} ${classes.menu}`}
                resources={props.resources ?? []}
                resource={props.resource}
                setResource={props.setResource ?? (() => {})}
            />);
            break;

        // Limit Menu:
        case "limit":
            menuComp = (<Limit
                className={`${widgetClasses.row} ${classes.menu}`}
                limit={props.pagination?.itemsPerPage ?? 50}
                setLimit={props.setPageSize ?? (() => {})}
            />);
            break;

        // Filter Menu:
        case "filter":
            menuComp = (<Filter
                className={`${widgetClasses.row} ${classes.menu}`}
                admin={props.admin}
                fields={props.fields}
                filter={props.filter!}
                setFilter={props.setFilter!}
            />);
            break;

        // Sort Menu:
        case "sort":
            menuComp = (<Sort
                className={`${widgetClasses.row} ${classes.menu}`}
                fields={props.fields}
                sort={props.sort!}
                setSort={props.setSort!}
            />);
            break;
    }

    // Return Component:
    const itemTotal: number = props.pagination?.itemsTotal ?? 0;
    return (
        <div className={`${classes.container}`}>
            <div className={`${widgetClasses.row} ${classes.navigation}`}>
                <button onClick={resourceSelection} className={`${menuState === "resource" ? widgetClasses.selected : ""} ${classes.resourcesButton}`}>
                    {itemTotal} {itemTotal === 1 ? props.resource.service.singular : props.resource.service.plural}
                </button>
                { props.setPage && (<>
                    <button onClick={() => toggleMenu("limit")} className={`${menuState === "limit" ? widgetClasses.selected : ""}`}>
                        Showing: {props.pagination?.itemsPerPage ?? 0}
                    </button>
                    <Pages
                        pageTotal={props.pagination?.pageTotal ?? 0}
                        pageCurrent={props.pagination?.pageCurrent ?? 0}
                        setPage={props.setPage}
                    />
                </>)}
                {props.fields && props.filter && props.setFilter && (
                    <button onClick={() => toggleMenu("filter")} className={`${menuState === "filter" ? widgetClasses.selected : ""}`}>
                        Filter
                    </button>
                )}
                {props.fields && props.sort && props.setSort && (
                    <button onClick={() => toggleMenu("sort")} className={`${menuState === "sort" ? widgetClasses.selected : ""}`}>
                        Sort
                    </button>
                )}
                {props.reset && (
                    <button onClick={() => props.reset!()}>
                        Reset
                    </button>
                )}
                {props.onTts && (
                    <button onClick={() => props.onTts!()}>
                        TTS
                    </button>
                )}
                {props.onCancel && (
                    <button onClick={() => props.onCancel!()} className={widgetClasses.warning}>
                        Cancel
                    </button>
                )}
            </div>
            {menuComp}
        </div>
    );
}