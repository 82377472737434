import { Link } from "react-router-dom";

import widgets from "styles/widgets";

export default function Home(): JSX.Element
{
    // Component:
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.page} ${widgetClasses.narrow}`}>
            <div className={`${widgetClasses.section}`}>
                <h1>Shard TCG - Available Now!</h1>
                <img src="/images/banner.png" />
                <p>Summon fierce, mysterious and grand Creatures from a vast menagerie to devour your opponent!</p>
                <p>Shard is a Trading Card Game that focuses on casting Spells and summoning Creatures for battle with a wide range of play styles and themes from mischievous Imps and proud Dragons to foul Undead and all consuming swarms of Insects!</p>
                <p>Check out the Cards that are available so far on the <Link to="/cards">Cards Page</Link>.</p>
            </div>
            <div className={`${widgetClasses.section}`}>
                <h2>Physical Packs and Merch Available Now</h2>
                <p>Physical Booster Packs of Shard TCG are now available for purchase in the <Link to="/store">Store</Link> along with other merch! Starter and Structure Decks will be available soon also.</p>
            </div>
            <div className={`${widgetClasses.section}`}>
                <h2>Tabletop Simulator and Discord</h2>
                <p>You can play Shard TCG using <a href="https://store.steampowered.com/app/286160/Tabletop_Simulator/">Tabletop Simulator</a> with our <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2309808896">free Workshop Mod</a>. We also have an active and ever growing community on <a href="https://discord.gg/cSJKUCGhxW">Discord</a>. You can also <Link to="decks/manager">Build Decks</Link> here on this site (you will need to whip up a Nephrite Account) which can be exported into Tabletop Simulator as well!</p>
            </div>
            <div className={`${widgetClasses.section}`}>
                <h2>How to Play</h2>
                <p>Check out the <Link to="/rules">Rules Page</Link> for an in depth guide into how the game works, we also have many videos on how to play on our Youtube Channel:</p>
                <div className={`video-wrapper`}>
                    <iframe src="https://www.youtube.com/embed/qUBop6908sM"></iframe>
                </div>
            </div>
        </div>
    );
}