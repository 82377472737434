import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CardSetDto, CardSetListDto, CardSetPartialDto, CardSetQueryDto, CardSetRequestDto } from "dtos/shard/card-set.dto";

export class CardSetService extends AbstractResourceService<CardSetDto, CardSetRequestDto, CardSetPartialDto, CardSetListDto, CardSetQueryDto>
{
    public cardSetDtos: Record<string, CardSetDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "cardSet", "Card Set", "Card Sets", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
            {
                key: "code",
                name: "Code",
                type: "string",
            },
            {
                key: "secret",
                name: "Secret",
                type: "boolean",
                hideSort: true,
                admin: true,
            },
        ]);
    }
}