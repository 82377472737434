import widgets from "styles/widgets";

export default function Limit(props: {
    className?: string,
    limit: number,
    setLimit: (size: number) => void,
}): JSX.Element
{
    // Limits:
    const limits: number[] = [10, 20, 50, 100, 200, 500];

    // Styles:
    const widgetClasses = widgets();

    // Return Component:
    return (
        <div className={`${props.className ?? ""}`}>
            {limits.map(limit => (
                <button
                    key={limit}
                    onClick={() => props.setLimit(limit)}
                    className={`${limit === props.limit ? widgetClasses.selected : ""}`}
                >{limit}</button>
            ))}
        </div>
    );
}