import { createUseStyles } from "react-jss";

import { CreatureEffectDto } from "dtos/shard/creature-effect.dto";
import { CardTemplate, templates } from "templates";

import renderStyles from "styles/render-styles";
import { replaceAll } from "utils";

const styles = createUseStyles<any, {
    template: CardTemplate,
    creatureEffect?: Partial<CreatureEffectDto>,
}>({
    container: {
        display: "flex",
        width: props => props.template.innerWidth - 32,
        minHeight: props => props.template.detailsFrameHeight,
        maxHeight: props => props.template.detailsFrameHeight,
        padding: "0 16px",
        borderTopWidth: props => props.template.dividerHeight,
        borderBottomWidth: props => props.template.dividerHeight,
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 0,
        flexBasis: 0,
        lineHeight: 1.6,
        overflow: "visible",

        "& p": {
            margin: 0,
        },
    },
});

export default function CreatureEffectView(props: {
    creatureEffect?: Partial<CreatureEffectDto>,
}): JSX.Element
{
    // Styles:
    const template: CardTemplate = templates["creature"];
    const renderClasses = renderStyles({
        template,
    });
    const classes = styles({
        template,
        creatureEffect: props.creatureEffect,
    });

    // Properties:
    const effectHtml: string = props.creatureEffect?.effect ?? "";
    const effectText: string = stripHtml(effectHtml);
    const effectTextClass: string = `${effectText.length <= 170 ? "" : renderClasses.small} ${renderClasses.creatureEffectText}`;

    // Components:
    return (
        <div className={`${classes.container}`}>
            <span className={`${renderClasses.text} ${renderClasses.actionText} ${renderClasses.center} ${renderClasses.stroke}`}>
                {props.creatureEffect?.name ?? "New Creature Effect"}
            </span>
            <span
                className={`${renderClasses.text}${renderClasses.effectText} ${effectTextClass} ${renderClasses.center} ${renderClasses.stroke}`}
                dangerouslySetInnerHTML={{ __html: effectHtml }}
            ></span>
        </div>
    );
}

/**
 * Strips basic html tags from the provided html text. Only basic tags provided by wysiwyg editors are supported.
 * @param html The html text to strip element tags out of.
 * @returns The text with html elements removed.
 */
function stripHtml(html: string) {
    let plainText = html;
    let filterElements = ["<strong>", "</strong>", "<u>", "</u>", "<br>", "<i>", "</i>", "<p>", "</p>"];
    filterElements.forEach(filterElement => plainText = replaceAll(plainText, filterElement, ""))
    return plainText;
};