import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { ListItem } from "types/list-item.d";
import { IResource } from "bos/resource.bo";

import { useResources } from "hooks/resources.hook";
import widgets from "styles/widgets";
import Navigator from "components/widgets/navigator";
import List from "components/widgets/list";
import { addPopup, removePopup } from "./popups";
import Popup from "components/popups/popup";
import { UserDto } from "dtos/user.dto";

const styles = createUseStyles({
    list: {
        gap: "0.5em",
        overflowX: "hidden",
    },
    "@media screen and (max-width: 720px)": {
        list: {
            minHeight: "calc(100vh - 2em)",
        },
    },
});

export default function ResourceSelector(props: {
    className?: string, // Optional css class name to use.
    user?: UserDto, // Optional dto of the user.
    resources?: Record<string, IResource>, // An optional resources map to pass in, if omitted a new resource map is made by this component instead.
    resourceNames: string[], // An array of resource names to use in this selector.
    resourceName?: string, // An optional resource to set as selected.
    selectedId?: string, // An optional id of a selected dto.
    popupEditor?: boolean, // If true and admin is true, edit buttons will be dispalyed in the list that will open up a popup editor for each item when clicked.
    initialNavMenu?: string, // Sets the initial navigation menu to display.
    getItemCount?: (dto: Record<string, any>) => number | undefined, // An optional function to call when creating list items for returning an item count.
    getItemMetadata?: (dto: Record<string, any>) => Record<string, any> | undefined, // An optional function to call when creating list items that can return optional metadata.
    onSetResource?: (resource: string) => void, // An optional function to call when a resource is selected in the navigator.
    onSelect: (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => void, // An optional function to call when an item is selected, the resource name and dto are passed.
    onAdd?: (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => void, // An optional function to call when the add button is clicked on a list item, if undefined the add button is hidden.
    onRemove?: (resourceName: string, dto: Record<string, any>, secondaryDto?: Record<string, any>) => void, // An optional function to call when the remove button is clicked on a list item, if undefined the remove button is hidden.
    onCreate?: (resourceName: string) => void, // An optional function to call when the create pseudo item is selected.
    onEmpty?: (resourceName: string) => void, // An optional function to call when the empty pseudo item is selected.
    onCancel?: () => void, // An optional function to call when cancel button in the navigator is clicked, the Cancel button is hidden if this is not set.
}): JSX.Element
{
    // Resources:
    const internalResources: Record<string, IResource> = useResources(props.user);
    const resources: Record<string, IResource> = props.resources ?? internalResources;

    // States:
    const [resource, setResource] = useState(props.resourceName ?? props.resourceNames[0] ?? "");
    const currentResource: IResource = resources[resource];
    const selectedId: string | undefined = currentResource.selected?.id ?? props.selectedId;

    // Effects:
    useEffect(() => {
        currentResource?.index();
    }, [props.user, resource, currentResource?.page, currentResource?.pageSize, currentResource?.filter, currentResource?.sort]);
    useEffect(() => {
        setResource(resource);
    }, [currentResource]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Invalid Resource:
    if (!currentResource) {
        return (
            <div className={`${widgetClasses.panel}`}>
                <span>Loading...</span>
            </div>
        );
    }

    // Actions:
    const setSelectedResource = (resourceName: string) => {
        setResource(resourceName);
        if (props.onSetResource) {
            props.onSetResource(resourceName);
        }
    }
    const setFilterField = (field: string, operator: string, value?: string | number | boolean | Record<string, any>) => {
        let currentField: Record<string, any> | undefined = currentResource.filter[field];
        const currentValue: string | number | boolean | Record<string, any> | undefined = currentField ? currentField[operator] : undefined;

        // Disable on Empty String:
        if (value === "") {
            value = undefined;
        }

        // Filter Value Unchanged:
        if (typeof value === "object") {
            if (typeof currentValue === "object" && value.id == currentValue.id) {
                return;
            }
        } else if (value === currentValue) {
            return;
        }

        // Force Page 1:
        currentResource.setPage(1);

        // Set Filter Value:
        if (value !== undefined) {
            if (!currentField) {
                currentField = {};
            }
            currentField[operator] = value;
            currentResource.setFilter({
                ...currentResource.filter,
                [field]: currentField,
            });
            return;
        }

        // Clear Filter Value
        if (currentField) {
            delete currentField[operator];

            // Clear Single Operator:
            if (Object.keys(currentField).length) {
                currentResource.setFilter({
                    ...currentResource.filter,
                    [field]: currentField,
                });
                return;
            }

            // Clear All Operators:
            delete currentResource.filter[field];
            currentResource.setFilter({ ...currentResource.filter });
        }
    };
    const setSortField = (field: string, direction: string) => {
        currentResource.setSort({
            ...currentResource.sort,
            [field]: currentResource.sort[field] !== direction ? direction : undefined,
        });
    };
    const reset = () => {
        currentResource.reset();
    };

    const ttsExport = () => {
        addPopup("ttsExport", (
            <Popup
                title="Tabletop Simulator Cards Export"
                description={[
                    `Click "Export" to download a card deck object for Tabletop Simulator with one of each Card based on the current filter. You can use it by copying it into your TTS "My Saves" folder located at:`,
                    `Linux: ~/.local/share/Tabletop Simulator`,
                    `Mac: ~/Library/Tabletop Simulator/Saves/Saved Objects`,
                    `Windows: %USERPROFILE%/Documents/My Games/Tabletop Simulator/Saves/Saved Objects`,
                ]}
                confirmText="Export"
                confirmAction={() => {
                    currentResource.ttsExport!();
                    removePopup("ttsExport");
                }}
                cancelText="Cancel"
                cancelAction={() => removePopup("ttsExport")}
            />
        ));
    };

    // Items:
    let items: ListItem[] = currentResource.items.map(dto => ({
        key: dto.id,
        name: dto.name,
        selected: dto.id === selectedId,
        count: props.getItemCount ? props.getItemCount(dto) : undefined,
        props: dto,
        metadata: props.getItemMetadata ? props.getItemMetadata(dto) : undefined,
        action: () => {
            currentResource.setSelected(dto);
            props.onSelect(resource, dto);
        },
        edit: props.user?.admin && props.popupEditor && currentResource.editorBuilder ? () => {
            const popupName: string = `selector-nested-${currentResource.service.name}-${dto.id}`;
            addPopup(popupName, (
                <div className={`${widgetClasses.popup}`}>
                    {currentResource.editorBuilder!(dto, () => removePopup(popupName), () => removePopup(popupName))}
                </div>
            ));
        } : undefined,
        add: props.onAdd ? () => {
            props.onAdd!(resource, dto);
        } : undefined,
        remove: props.onRemove ? () => {
            props.onRemove!(resource, dto);
        } : undefined,
        componentBuilder: currentResource.itemBuilder,
    }));

    // Component:
    return (
        <div className={`${widgetClasses.column} ${widgetClasses.gap} ${props.className ?? ""}`}>
            <div className={`${widgetClasses.panel}`}>
                <Navigator
                    admin={props.user?.admin}
                    resource={currentResource}
                    pagination={currentResource.pagination}
                    initialMenu={props.initialNavMenu}
                    resources={props.resourceNames.map(resourceName => resources[resourceName])}
                    fields={currentResource.service.fields}
                    sort={currentResource.sort}
                    filter={currentResource.filter}
                    setResource={setSelectedResource}
                    setPage={currentResource.setPage}
                    setPageSize={currentResource.setPageSize}
                    setFilter={setFilterField}
                    setSort={setSortField}
                    reset={reset}
                    onTts={currentResource.ttsExport ? ttsExport : undefined}
                    onCancel={props.onCancel}
                />
            </div>
            <div className={`${widgetClasses.grid} ${widgetClasses.background} ${classes.list}`}>
                <List
                    items={items}
                    onCreate={props.onCreate ? () => props.onCreate!(resource) : undefined}
                    onEmpty={props.onEmpty ? () => props.onEmpty!(resource) : undefined}
                    pseudoBuilder={currentResource.itemBuilder}
                />
            </div>
        </div>
    );
}