import { useState, useRef, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { v4 as uuid } from "uuid";

import { ruleCategoryService, ruleService } from "services";
import { useServiceListener } from "hooks/service-listener.hook";
import { TabItem } from "types/tab-item";
import { ListItem } from "types/list-item";
import { RuleCategoryDto } from "dtos/shard/rule-category.dto";
import { RuleDto } from "dtos/shard/rule.dto";

import { UserState } from "states/user.state";
import widgets from "styles/widgets";
import Tabs from "components/widgets/tabs";
import List from "components/widgets/list";
import Rule from "components/panels/rule";
import RuleEditor from "components/widgets/editors/rule-editor";
import { addPopup, removePopup } from "components/widgets/popups";
import { useResources } from "hooks/resources.hook";

const styles = createUseStyles({
    container: {
        "& button": {
            width: "100%",
        },
    },
    itemWrapper: {
        width: "calc(100% - 32px)",
    },
});

export default function Rules(props: {
    user: UserState,
}): JSX.Element
{
    // Resources:
    const resources = useResources(props.user.dto, (resourceName: string, firstItem: Record<string, any>) => {
        switch (resourceName) {
            case "ruleCategory":
                const category: RuleCategoryDto = firstItem as RuleCategoryDto;
                setSelectedCategory(category as RuleCategoryDto);
                break;
            case "rule":
                setSelectedRule(firstItem as RuleDto);
                break;
        }
    });
    const ruleCategoryResource = resources["ruleCategory"];
    const ruleResource = resources["rule"];
    if (!ruleCategoryResource.items.length) {
        ruleCategoryResource.index();
    }
    
    // States:
    const [selectedCategory, setSelectedCategory] = useState(undefined as RuleCategoryDto | undefined);
    const [selectedRule, setSelectedRule] = useState(undefined as RuleDto | undefined);
    const ruleRef: React.MutableRefObject<any> = useRef(null);

    // Effects:
    useEffect(() => {
        if (!selectedCategory) {
            return;
        }
        const filter = { ruleCategoryId: { eq: selectedCategory.id } };
        ruleResource.setFilter(filter);
    }, [selectedCategory?.id]);
    useEffect(() => {
        ruleResource.index();
    }, [ruleResource.filter]);
    useEffect(() => {
    }, [ruleResource.items.length]);

    // Actions:
    const createRule = () => {
        if (!selectedCategory) {
            return;
        }
        const newRule: Partial<RuleDto> = { ruleCategoryId: selectedCategory.id };
        addPopup("rules-create", (
            <div className={`${widgetClasses.popup} ${widgetClasses.popupLarge} ${widgetClasses.popupWide}`}>
                <h1>Create New Rule for {selectedCategory.name}</h1>
                <RuleEditor
                    rule={newRule}
                    preview={newRule}
                    onSave={() => removePopup("rules-create")}
                    onClose={() => removePopup("rules-create")}
                />
            </div>
        ));
    };

    // Properties:
    const tabs: TabItem[] = ruleCategoryResource.items.map(category => ({
        key: category.id,
        name: category.name,
        action: () => {
            setSelectedCategory(category as RuleCategoryDto);
        },
    }));
    const items: ListItem[] = ruleResource.items.map(rule => ({
        key: rule.id,
        name: rule.name + (props.user.dto?.admin ? ` [${rule.position}]` : ""),
        selected: selectedRule?.id === rule.id,
        action: () => {
            setSelectedRule(rule as RuleDto);
            ruleRef.current?.scrollIntoView();
        },
    }));

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Component:
    return (
        <div className={`${widgetClasses.page} ${classes.container}`}>
            <div className={`${widgetClasses.panel}`}>
                <Tabs tabs={tabs} selected={selectedCategory?.id} />
            </div>
            <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                <div className={`${widgetClasses.panel} ${widgetClasses.sidebar}`}>
                    <div className={`${widgetClasses.widget}`}>
                        <List
                            items={items}
                            itemWrapperClassName={`${classes.itemWrapper}`}
                            onCreate={(props.user.dto?.admin ?? false) ? () => createRule() : undefined}
                        />
                    </div>
                </div>
                <div className={`${widgetClasses.panel} ${widgetClasses.grow}`} ref={ruleRef}>
                    <div className={`${widgetClasses.widget}`}>
                        <Rule rule={selectedRule} ruleCategory={selectedCategory} admin={props.user.dto?.admin ?? false} />
                    </div>
                </div>
            </div>
        </div>
    );
}