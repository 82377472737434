import { createUseStyles } from "react-jss";

import { ArtistDto } from "dtos/shard/artist.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
});

export default function ArtistView(props: {
    artist?: Partial<ArtistDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.artist?.name ?? "New Artist"}</h3>
        </div>
    );
}