import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { actionEffectService, actionService, actionTypeService, artistService, cardEntryService, cardService, cardSetService, cardSetTypeService, cardTypeService, creatureEffectService, deckService, elementService, imagesService, rarityService, ruleCategoryService, ruleService, templateService, titleService } from "services";
import { UserState } from "states/user.state";
import { CardDto } from "dtos/shard/card.dto";
import { DeckDto } from "dtos/shard/deck.dto";
import { ErrorDto } from "dtos/error.dto";

import CardNotification from "./notifications/card-notification";
import ResourceNotification from "./notifications/resource-notification";

export type NotificationEntry = {
    reference: string,
    component: JSX.Element,
};

/**
 * The global notifications.
 */
let notifications: NotificationEntry[] = [];

/**
 * Sets the current array of active notifications.
 */
let setNotifications: (notifications: NotificationEntry[]) => void = () => {};

/**
 * Adds a global notification to display.
 * @param reference The unique notification reference name, used to remove or replace notifications (the replaced notification will become the latest notification).
 * @param component The component to use for the notification.
 */
export function addNotification(reference: string, component: JSX.Element): void
{
    removeNotification(reference);
    notifications.push({
        reference,
        component,
    });
    setNotifications(notifications);
}

/**
 * Removes a global notification.
 * @param reference The reference name of the notification to remove.
 * @return Returns true if a notification was found by the provided reference and removed.
 */
export function removeNotification(reference: string): boolean
{
    const index: number = notifications.findIndex(notification => notification.reference === reference);
    if (index < 0) {
        return false;
    }
    notifications.splice(index, 1);
    setNotifications(notifications);
    return true;
}

const styles = createUseStyles({
    container: {
        display: "flex",
        position: "absolute",
        top: 0,
        right: 0,
        width: "16em",
        gap: "1em",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
    },
});

export default function Notifications(props: {
    user: UserState,
}): JSX.Element
{
    // States:
    const [state, setState] = useState({ notifications });
    setNotifications = (notifications) => setState({ notifications });

    // Public Notification Listeners:
    useEffect(() => {
        const removeListeners: (() => void)[] = [];

        // Public Card Events:
        removeListeners.push(cardService.addListener((event: string, cardDto: CardDto | undefined) => {
            if (!cardDto || (event !== "card.store" && event !== "card.update")) {
                return;
            }
            const notificationId: string = "card-" + cardDto.id;
            addNotification(notificationId, (
                <CardNotification key={notificationId} notificationId={notificationId} card={cardDto} new={event === "card.store"} />
            ));
        }));

        // User Deck Events:
        removeListeners.push(deckService.addListener((event: string, deckDto: DeckDto | undefined) => {
            if (!deckDto || (event !== "deck.store" && event !== "deck.update")) {
                return;
            }
            const notificationId: string = "deck-" + deckDto.id;
            addNotification(notificationId, (
                <div
                    key={notificationId}
                    className={`${widgetClasses.notification} ${widgetClasses.confirm}`}
                    onClick={() => removeNotification(notificationId)}
                >
                    Deck Updated: {deckDto.name}
                </div>
            ));
        }));

        return () => removeListeners.forEach(removeListener => removeListener());
    }, [props.user]);

    // Admin Notification Listeners:
    useEffect(() => {
        if (!props.user.dto?.admin) {
            return;
        }
        const notify = (event: string, dto: any | ErrorDto) => {
            const eventParts: string[] = event.split(".");
            if (!dto || eventParts.length < 2) {
                return;
            }
            if (eventParts[1] === "show") {
                return;
            }
            
            const notificationId: string = `resource-${eventParts[0]}-${dto.id}`;
            const error: boolean = eventParts.length >= 3 && eventParts[2] === "error";
            addNotification(notificationId, (
                <ResourceNotification
                    key={notificationId}
                    notificationId={notificationId}
                    resourceName={eventParts[0]}
                    actionName={eventParts[1]}
                    dto={!error ? dto : undefined}
                    errorDto={error ? dto : undefined}
                />
            ));
        };

        const removeListeners: (() => void)[] = [];
        removeListeners.push(cardEntryService.addRenderListener(notify));
        removeListeners.push(cardEntryService.addListener(notify, notify));
        removeListeners.push(cardSetService.addListener(notify, notify));
        removeListeners.push(cardSetTypeService.addListener(notify, notify));
        removeListeners.push(artistService.addListener(notify, notify));
        removeListeners.push(rarityService.addListener(notify, notify));
        removeListeners.push(cardTypeService.addListener(notify, notify));
        removeListeners.push(templateService.addListener(notify, notify));
        removeListeners.push(elementService.addListener(notify, notify));
        removeListeners.push(titleService.addListener(notify, notify));
        removeListeners.push(actionService.addListener(notify, notify));
        removeListeners.push(actionTypeService.addListener(notify, notify));
        removeListeners.push(actionEffectService.addListener(notify, notify));
        removeListeners.push(creatureEffectService.addListener(notify, notify));
        removeListeners.push(ruleService.addListener(notify, notify));
        removeListeners.push(ruleCategoryService.addListener(notify, notify));
        removeListeners.push(imagesService.addListener(notify, notify));
        return () => removeListeners.forEach(removeListener => removeListener());
    }, [props.user]);
    
    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <div className={`${classes.container}`}>
            {state.notifications.map(entry => entry.component)}
        </div>
    );
}