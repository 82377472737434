import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { ArtistDto, ArtistListDto, ArtistPartialDto, ArtistQueryDto, ArtistRequestDto } from "dtos/shard/artist.dto";

export class ArtistService extends AbstractResourceService<ArtistDto, ArtistRequestDto, ArtistPartialDto, ArtistListDto, ArtistQueryDto>
{
    public artistDtos: Record<string, ArtistDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "artist", "Artist", "Artists", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}