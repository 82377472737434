import { createUseStyles } from "react-jss";

import { addPopup, removePopup } from "../popups";
import { DeckDto } from "dtos/shard/deck.dto";
import { ttsService } from "services";

import Popup from "components/popups/popup";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import widgets from "styles/widgets";

const styles = createUseStyles({
    container: {
        width: "100%",
        minWidth: "20em",
        textAlign: "center",

        "& > button": {
            padding: "0.5em",
            width: "100%",
        },
    },
});

export default function DeckView(props: {
    deck?: Partial<DeckDto>,
    cardEntry?: CardEntryDto,
}): JSX.Element
{
    // Properties:
    const displayCode: string | undefined = props.cardEntry ? `${props.cardEntry.cardSet.code}-${props.cardEntry.reference.toString().padStart(3, "0")}` : undefined;
    const totalCount: number = props.deck?.deckEntries?.reduce((total, deckEntryDto) => total + deckEntryDto.count, 0) ?? 0;
    const uniqueCount: number = props.deck?.deckEntries ? new Set(props.deck.deckEntries.filter(deckEntryDto => deckEntryDto.cardEntryId.split("@")[0])).size : 0;

    // Actions:
    const ttsExport = () => {
        if (!props.deck?.id) {
            return;
        }
        addPopup("ttsExport", (
            <Popup
                title="Tabletop Simulator Deck Export"
                description={[
                    `Click "Export" to download this Deck as a Tabletop Simulator Deck Game Object. You can use it by copying it into your TTS "My Saves" folder located at:`,
                    `Linux: ~/.local/share/Tabletop Simulator`,
                    `Mac: ~/Library/Tabletop Simulator/Saves/Saved Objects`,
                    `Windows: %USERPROFILE%/Documents/My Games/Tabletop Simulator/Saves/Saved Objects`,
                ]}
                confirmText="Export"
                confirmAction={() => {
                    ttsService.exportDeck(props.deck!.id!);
                    removePopup("ttsExport");
                }}
                cancelText="Cancel"
                cancelAction={() => removePopup("ttsExport")}
            />
        ));
    };

    // Styles:
    const classes = styles();
    const widgetClasses = widgets();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h1>{props.deck?.name ?? "New Deck"}</h1>
            <div className={`${widgetClasses.row} ${widgetClasses.gap}`}>
                <p>Total Cards: {totalCount}</p>
                <p>Unique Cards: {uniqueCount}</p>
            </div>
            {props.deck?.id && <button onClick={() => ttsExport()}>
                Export as Tabletop Simulator Deck
            </button>}
        </div>
    );
}