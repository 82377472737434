import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { ErrorDto } from "dtos/error.dto";
import { CardEntryQueryDto } from "dtos/shard/card-entry.dto";
import { QueryCommon } from "dtos/common.dto";

export class TtsService extends AbstractService<Record<string, any> | ErrorDto | undefined>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Upload Events:
        const resourceNames: string[] = ["card", "cardEntry", "cardSet", "deck"];
        resourceNames.forEach(resourceName => {
            this.socket.on(`tts.${resourceName}`, async (dto: Record<string, any>) => {
                this.listeners.forEach(listener => listener(`tts.${resourceName}`, dto));
                
                // Download TTS:
                const link: HTMLAnchorElement = document.createElement("a");
                link.href = URL.createObjectURL(new Blob([JSON.stringify(dto, undefined, 4)], { type: "application/json" }));
                link.download = `${dto.nickname ?? "Shard TCG Deck"}.json`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
            this.socket.on(`tts.${resourceName}.error`, (dto: ErrorDto) => {
                this.listeners.forEach(listener => listener(`tts.${resourceName}.error`, dto));
                console.error(`[TTS Service] Generation from ${resourceName} query error:`, dto.message);
            });
        });
    }

    /**
     * Generates a tts deck from a resource query.
     * @param resourceName The name of the resource to export a query of to a tts deck.
     * @param queryDto The query dto to generate a deck from.
     * @param requestId The request id for reacting to success events.
     */
    public async export(resourceName: string, queryDto: QueryCommon, requestId?: string): Promise<void>
    {
        console.log(`[TTS Service] Generating TTS Deck from ${resourceName} query...`);

        this.socket.emit("tts." + resourceName, {
            ...queryDto,
            requestId,
        } as CardEntryQueryDto);
    }

    /**
     * Generates a tts deck from a card set.
     * @param cardSetId The id of the card set to generate from.
     * @param requestId The request id for reacting to success events.
     */
    public async exportCardSet(cardSetId: string, requestId?: string): Promise<void>
    {
        console.log(`[TTS Service] Generating TTS Deck from Card Set: ${cardSetId}...`);

        this.socket.emit("tts.cardSet", {
            id: cardSetId,
            requestId,
        });
    }

    /**
     * Generates a tts deck from a deck.
     * @param deckId The id of the deck to generate from.
     * @param requestId The request id for reacting to success events.
     */
    public async exportDeck(deckId: string, requestId?: string): Promise<void>
    {
        console.log(`[TTS Service] Generating TTS Deck from Deck: ${deckId}...`);

        this.socket.emit("tts.deck", {
            id: deckId,
            requestId,
        });
    }
}