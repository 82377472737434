import { CommonId, ListCommon, QueryCommon } from "dtos/common.dto";
import { useEffect, useState } from "react";

import { AbstractResourceService } from "services/abstract-resource.service";
import { PaginationDto } from "dtos/pagination.dto";
import { IResourceService, ResourceBo } from "bos/resource.bo";

/**
 * A service listener with a full list of dtos nad support for incremental change.
 * @param service The service to listen on.
 * @param firstItem An optional function to call on the first item received from a list.
 * @param resource An optional resource to refresh and use the request id of.
 * @param requestId The request id to listen for if the resource is undefined.
 * @param relationServices An optional array of other resources that nested objects through relations may be updated on.
 * @returns The latest list of items received from the service.
 */
export function useServiceListener<
    DtoType extends CommonId | undefined, RequestDtoType, PartialDtoType extends Partial<RequestDtoType>, ListDtoType extends ListCommon<NonNullable<DtoType>>, QueryDtoType extends QueryCommon
>(
    service: AbstractResourceService<DtoType, RequestDtoType, PartialDtoType, ListDtoType, QueryDtoType>,
    firstItem?: (item: DtoType) => void,
    resource?: ResourceBo<DtoType, RequestDtoType, PartialDtoType, ListDtoType, QueryDtoType>,
    requestId?: string,
    relationServices: IResourceService[] = [],
): [NonNullable<DtoType>[], PaginationDto | undefined]
{
    // States:
    const [items, setItems] = useState([] as NonNullable<DtoType>[]);
    const [pagination, setPagination] = useState(undefined as PaginationDto | undefined);

    // Effects:
    useEffect(() => {
        const removeListeners: (() => void)[] = [];

        // Update Items on Index Event:
        removeListeners.push(service.addListListener((event, listDto) => {
            if (listDto.requestId === (resource?.requestId ?? requestId)) {
                setItems(listDto.items);
                setPagination(listDto.pagination);
                if (firstItem && listDto.items.length) {
                    firstItem(listDto.items[0]);
                }
            }
        }));

        // Reindex on Single Resource Update:
        removeListeners.push(service.addListener((event, dto) => {
            if (resource) {
                resource.index();
            }
        }));

        // Reindex on Related Resource Updates:
        relationServices.forEach(relationService => removeListeners.push(relationService.addListener((event, dto) => {
            if (resource) {
                resource.index();
            }
        })));

        // Remove Listeners:
        return () => removeListeners.forEach(removeListener => removeListener());
    }, [requestId, resource]);

    // Return State:
    return [items, pagination];
}