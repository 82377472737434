import { useEffect, useState } from "react";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";

import { IResource } from "bos/resource.bo";
import { useResources } from "hooks/resources.hook";
import { UserDto } from "dtos/user.dto";

export default function NavigatedDisplay(props: {
    user?: UserDto,
}): JSX.Element | null
{
    // Router:
    const params: Params<string> = useParams();
    const navigate: NavigateFunction = useNavigate();

    // Resources:
    const resources: Record<string, IResource> = useResources(props.user);
    const currentResource: IResource = resources[params.resourceName ?? "card"];

    // States:
    const [displayItem, setDisplayItem] = useState(undefined as Record<string, any> | undefined);

    // Effects:
    useEffect(() => {
        // Clear:
        if (!params.resourceName || !params.selectedId) {
            setDisplayItem(undefined);
            return;
        }

        // Create:
        if (params.selectedId === "create") {
            setDisplayItem({});
            return;
        }

        // Listen:
        const eventListeners: (() => void)[] = [];
        eventListeners.push(currentResource.service.addListener((eventName, dto) => {
            if (dto?.id === params.selectedId) {
                // Item Destroyed:
                if (eventName.includes(".destroy")) {
                    setDisplayItem(undefined);
                    return;
                }

                // Item Shown or Updated:
                setDisplayItem(dto);
            }
        }));

        // Relation Events:
        currentResource.relationServices.forEach(relationService => eventListeners.push(
            relationService.addListener((eventName, dto) => {
                if (!displayItem || !displayItem[relationService.name]) {
                    return;
                }
                const updatedItem = structuredClone(displayItem);
                if (Array.isArray(updatedItem[relationService.name])) {
                    updatedItem[relationService.name].forEach((entry: any, index: number) => {
                        if (entry.id === dto.id) {
                            updatedItem[relationService.name][index] = dto;
                        }
                    });
                    return;
                }
                if (updatedItem[relationService.name].id === dto.id) {
                    updatedItem[relationService.name] = dto;
                }
                setDisplayItem(updatedItem);
            }))
        );

        // Request:
        currentResource.service.show({ id: params.selectedId });

        return () => eventListeners.forEach(eventListener => eventListener());
    }, [props.user, params.resourceName, params.selectedId]);

    // Properties:
    const admin: boolean = props.user?.admin ?? false;

    // Actions:
    const setSelectedId = (id: string) => {
        navigate(`/cards/${params.resourceName}/${id}`);
    };
    const clearSelectedId = () => {
        navigate(`/cards/${params.resourceName}`);
    };

    // Components:
    let displayComp: JSX.Element | null = null;
    if (displayItem && params.resourceName) {
        displayComp = currentResource.displayBuilder ? currentResource.displayBuilder(
            displayItem,
            dto => setSelectedId(dto.id),
            () => clearSelectedId(),
        ) : null;
    }

    // Return Component:
    return displayComp;
}