import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { v4 as uuid } from "uuid";

import { AbstractResourceService } from "services/abstract-resource.service";
import { ListCommon, QueryCommon } from "dtos/common.dto";

import widgets from "styles/widgets";
import { addPopup, removePopup } from "./popups";
import SelectPopup from "components/popups/select-popup";

const styles = createUseStyles({
    
});

export default function EditorControls<
    DtoType, RequestDtoType, PartialDtoType extends Partial<RequestDtoType>, ListDtoType extends ListCommon<DtoType>, QueryDtoType extends QueryCommon
>(props: {
    editorName: string,
    service: AbstractResourceService<DtoType, RequestDtoType, PartialDtoType, ListDtoType, QueryDtoType>,
    subject?: Partial<DtoType>,
    subjectName: string,
    requestDto: RequestDtoType,
    onReset: () => void,
    onSave?: (dto: DtoType) => void,
    onClose?: () => void,
}): JSX.Element
{
    // States:
    const [requestId, setRequestId] = useState(uuid());
    
    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Effects:
    useEffect(() => {
        // Trigger on save when an event dto with this editor's request id is received:
        return props.service.addListener((event, dto) => {
            if (props.onSave && (dto as Record<string, any>).requestId === requestId) {
                props.onSave(dto as DtoType);
            }
        });
    }, [requestId]);

    // Actions:
    const save = () => {
        if ((props.requestDto as Record<string, any>).id) {
            props.service.modify(props.requestDto as unknown as PartialDtoType);
        } else {
            props.service.create(props.requestDto, requestId);
        }
    };
    const deleteCheck = () => {
        const popupRef: string = `editor-${props.editorName}-delete-${(props.subject as Record<string, any>).id}`;
        addPopup(popupRef, (
            <SelectPopup
                popupRef={popupRef}
                title={`Delete ${props.subjectName}?`}
                options={["Delete", "Cancel"]}
                warningOption={"Delete"}
                onSelect={(option: string) => {
                    if (props.subject && option === "Delete") {
                        props.service.destroy(props.subject as unknown as PartialDtoType);
                    }
                    removePopup(popupRef);
                    if (props.onClose) {
                        props.onClose();
                    }
                }}
            />
        ));
    };

    // Component:
    return (
        <div className={`${widgetClasses.row}`}>
            <button id="save" onClick={save} className={`${widgetClasses.confirm}`}>{(props.requestDto as Record<string, any>).id ? "Update" : "Create"}</button>
            <button id="reset" onClick={props.onReset}>Reset</button>
            {props.subject && <button id="delete" onClick={deleteCheck} className={`${widgetClasses.warning}`}>Delete</button>}
            {props.onClose && <button id="close" onClick={() => props.onClose!()} className={`${widgetClasses.warning}`}>Close</button>}
        </div>
    );
}