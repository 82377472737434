import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { ActionEffectDto, ActionEffectPartialDto, ActionEffectListDto, ActionEffectQueryDto, ActionEffectRequestDto } from "dtos/shard/action-effect.dto";

export class ActionEffectService extends AbstractResourceService<ActionEffectDto, ActionEffectRequestDto, ActionEffectPartialDto, ActionEffectListDto, ActionEffectQueryDto>
{
    public actionEffectDtos: Record<string, ActionEffectDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "actionEffect", "Action Effect", "Action Effects", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}