import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import ReactDropzone from "react-dropzone";
import { v4 as uuid } from "uuid";

import { cardEntryService, imagesService } from "services";
import { CardEntryCommon, CardEntryDto, CardEntryRequestDto } from "dtos/shard/card-entry.dto";
import { CardDto, CardNoRelationsDto } from "dtos/shard/card.dto";
import { UserDto } from "dtos/user.dto";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";
import { StatusDto } from "dtos/status.dto";
import { ErrorDto } from "dtos/error.dto";

type UploadState = {
    name: string,
    role: string,
    file: File,
    requestId: string,
};

const styles = createUseStyles({
    container: {
        width: "100%",
    },
    dropzone: {
        width: "calc(100% - 2em)",
        height: "auto",
        margin: "1em 1em",
        textAlign: "center",

        backgroundColor: variables.colors.neutral + "AA",
        boxShadow: `0 0 10px 10px ${variables.colors.neutral + "AA"}`,
    },
});

export default function CardEntryEditor(props: {
    user?: UserDto, // Optional dto of the user.
    cardEntry?: Partial<CardEntryDto>,
    preview?: Partial<CardEntryDto>,
    onPreviewUpdate?: (previewDto: Partial<CardEntryDto>) => void,
    onCardChange?: (cardDto: CardDto) => void,
    onClose?: () => void,
    onSave?:(cardEntry: CardEntryDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: CardEntryCommon = {
        reference: props.cardEntry?.reference ?? 0,
        normalCount: props.cardEntry?.normalCount ?? 1,
        foilCount: props.cardEntry?.foilCount ?? 0,
    };
    const freshPreviewDto: Partial<CardEntryDto> = props.preview ? structuredClone(props.preview) : props.cardEntry ? structuredClone(props.cardEntry) : {
        id: undefined,
        ...freshCommon,
    };
    const freshRequestDto: CardEntryRequestDto = {
        id: props.cardEntry?.id,
        ...freshCommon,
        cardId: props.cardEntry?.card?.id ?? "",
        cardSetId: props.cardEntry?.cardSet?.id ?? "",
        rarityId: props.cardEntry?.rarity?.id ?? "",
        artistId: props.cardEntry?.artist?.id ?? "",
    };

    // States:
    const [artImageUpload, setArtImageUpload] = useState(undefined as UploadState | undefined);
    const [artSourceUpload, setArtSourceUpload] = useState(undefined as UploadState | undefined);

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "cardEntry", resources, requestDto, previewDto, setRequestDto, previewUpdate, (field, value) => {
        // Card Update:
        if (field === "cardId" && props.onCardChange) {
            props.onCardChange(value as CardDto);
        }
    });

    // Properties:
    const previewCard: CardNoRelationsDto | undefined = props.cardEntry?.card ?? previewDto.card;
    const cardDisplayName: string | undefined = previewCard ? [previewCard.subspecies, previewCard.name, previewCard.morph].join(" ") : undefined;
    const displayCode: string = props.cardEntry ? `${props.cardEntry.cardSet?.code ?? "XXX"}-${props.cardEntry.reference?.toString().padStart(3, "0") ?? "###"}` : "";

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
        setArtImageUpload(undefined);
        setArtSourceUpload(undefined);
    }, [props.cardEntry]);

    // Upload Success:
    useEffect(() => imagesService.addListener((event: string, dto: StatusDto | ErrorDto | undefined) => {
        if (!dto || (event !== "image.upload")) {
            return;
        }
        if (artImageUpload && dto.requestId === artImageUpload.requestId) {
            setArtImageUpload(undefined);
        }
        if (artSourceUpload && dto.requestId === artSourceUpload.requestId) {
            setArtSourceUpload(undefined);
        }
    }), [artImageUpload, artSourceUpload]);

    // Start Uploads from States:
    useEffect(() => {
        if (artImageUpload) {
            imagesService.upload(artImageUpload.name, artImageUpload.role, artImageUpload.file, artImageUpload.requestId);
        }
        if (artSourceUpload) {
            imagesService.upload(artSourceUpload.name, artSourceUpload.role, artSourceUpload.file, artSourceUpload.requestId);
        }
    }, [artImageUpload, artSourceUpload]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Actions:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (previewUpdate) {
            previewUpdate(props.cardEntry ?? previewDto);
        }
    };

    const imageUpload = <T extends File>(files: T[], role: string) => {
        if (!displayCode || !files.length) {
            return;
        }
        if (role === "art") {
            setArtImageUpload({
                name: displayCode,
                role,
                file: files[0],
                requestId: uuid(),
            });
        } else if (role === "source") {
            setArtSourceUpload({
                name: displayCode,
                role,
                file: files[0],
                requestId: uuid(),
            });
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <button id="card" onClick={() => onPopup("card", "cardId", true)}>Card: {cardDisplayName ?? "Please Select"}</button>
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Reference</label>
                <input id="reference" type="number" value={requestDto.reference} onChange={onNumberChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <button id="cardSet" onClick={() => onPopup("cardSet", "cardSetId", true)}>Set: {previewDto.cardSet?.name ?? "Please Select"}</button>
            </div>

            <hr/>
            <h4>Counts</h4>
            <div className={`${widgetClasses.row}`}>
                <label>Normal Count</label>
                <input id="normalCount" type="number" value={requestDto.normalCount ?? 1} onChange={onNumberChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Foil Count</label>
                <input id="foilCount" type="number" value={requestDto.foilCount ?? 0} onChange={onNumberChange} />
            </div>

            <hr/>
            <h4>Attributes</h4>
            <div className={`${widgetClasses.row}`}>
                <button id="rarity" onClick={() => onPopup("rarity", "rarityId", true)}>Rarity: {previewDto.rarity?.name ?? "Please Select"}</button>
                <button id="artist" onClick={() => onPopup("artist", "artistId", true)}>Artist: {previewDto.artist?.name ?? "Please Select"}</button>
            </div>

            <hr/>
            <EditorControls
                editorName="cardEntry"
                service={cardEntryService}
                subject={props.cardEntry}
                subjectName={displayCode}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <div className={`${widgetClasses.row}`}>
                <label>ID</label>
                <input id="id" type="text" readOnly={true} value={props.cardEntry?.id ?? "New Entry"} />
            </div>
            <hr/>

            <ReactDropzone onDrop={(accepted, rejections, event) => imageUpload(accepted, "art")}>
                {({getRootProps}) => {
                    return (
                        <div className={`${classes.dropzone}`} {...getRootProps()}>
                            <p>{artImageUpload ? "Art Image: Uploading..." : "Art Image: Drop Here To Upload"}</p>
                        </div>
                    )
                }}
            </ReactDropzone>
            <ReactDropzone onDrop={(accepted, rejections, event) => imageUpload(accepted, "source")}>
                {({getRootProps}) => {
                    return (
                        <div className={`${classes.dropzone}`} {...getRootProps()}>
                            <p>{artSourceUpload ? "Art Source: Uploading..." : "Art Source: Drop Here To Upload"}</p>
                        </div>
                    )
                }}
            </ReactDropzone>
            <hr/>
        </div>
    );
}