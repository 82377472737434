import renderStyles from "styles/render-styles";
import { CardTemplate } from "templates";
import { ActionDto } from "dtos/shard/action.dto";

export default function ActionRender(props: {
    action?: Partial<ActionDto>,
    mirror?: boolean,
    template: CardTemplate,
    titleOffset?: number
}): JSX.Element
{
    // Styles:
    const template: CardTemplate = props.template;
    const classes = renderStyles({
        template: props.template,
    });

    // Text and Icons:
    const actionComps: JSX.Element[] = [];

    // Name:
    let scale: number = 1;
    const nameLength: number = props.action?.name?.length ?? 0;
    const nameExcess: number = Math.max(0, nameLength - 14);
    if (nameExcess > 0) {
        scale -= nameExcess * 0.044;
    }
    actionComps.push((
        <span key="name" className={`${classes.text} ${classes.actionText} ${classes.stroke}`} style={{
            transform: `scale(${scale}, 1)`,
            transformOrigin: `${props.mirror ? 100 : 0}% 0%`,
            textAlign: props.mirror ? "right" : "left",
            whiteSpace: "nowrap",
        }}>{props.action?.name ?? "New Action"}</span>
    ));

    // Type and Effect:
    const typeEffect: string = (props.action?.actionType?.name ?? "Type") + (props.action?.actionEffect ? ", " + props.action.actionEffect.name : "");
    const typeEffectExcess: number = Math.max(0, typeEffect.length - 16);
    scale = 1;
    if (typeEffectExcess > 0) {
        scale -= typeEffectExcess * 0.044;
    }
    actionComps.push((
        <span key="typeEffect" className={`${classes.text} ${classes.stroke}`} style={{
            transform: `scale(${scale}, 1)`,
            transformOrigin: `${props.mirror ? 100 : 0}% 0%`,
            textAlign: props.mirror ? "right" : "left",
            whiteSpace: "nowrap",
        }}>{typeEffect}</span>
    ));

    // Element:
    const elementIconComp: JSX.Element = (
        <img src={`/images/card/elements/${props.action?.element?.name.toLowerCase() ?? "fire"}.svg`} style={{
            height: template.iconHeight,
            paddingLeft: props.mirror ? 6 : 0,
            paddingRight: props.mirror ? 0 : 6,
        }} />
    );
    actionComps.push((
        <span key="element" className={`${classes.text} ${classes.stroke}`} style={{
            textAlign: props.mirror ? "right" : "left",
        }}>
            {!props.mirror ? elementIconComp : ""}
            {props.action?.element?.name ?? "Element"}
            {props.mirror ? elementIconComp : ""}
        </span>
    ));

    // Power and Type Icon:
    const typeIconComp: JSX.Element = (
        <img src={`/images/card/${props.action?.actionType?.name.toLowerCase() ?? "attack"}.svg`} style={{
            height: 36,
            paddingLeft: 8,
            paddingRight: 8,
        }} />
    );
    actionComps.push((
        <div key="power" className={`${classes.textWrapper}`} style={{
            top: props.titleOffset ? props.titleOffset / 2 : 0,
            left: props.mirror ? 0 : undefined,
            right: props.mirror ? undefined : 0,
            height: `calc(100% - 32px)`,
        }}>
            <span className={`${classes.text} ${classes.powerText} ${classes.number} ${classes.stroke}`}style={{
                alignSelf: "center",
            }} >
                {!props.mirror ? typeIconComp : ""}
                {props.action?.power ?? 1}
                {props.mirror ? typeIconComp : ""}
            </span>
        </div>
    ));
    
    // Return Component:
    return (
        <>
            {actionComps}
        </>
    );
}