import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { RarityDto } from "dtos/shard/rarity.dto";
import { replaceAll } from "utils";

export default function RarityItem(props: {
    id: string,
    rarity?: RarityDto,
    selected: boolean,
    onClick?: (rarity?: RarityDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.rarity);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.rarity,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${widgetClasses.mouseTilt}`}>
                {props.rarity ? (<>
                    <div className={`${listItemClasses.title}`}>
                        <img src={`/images/card/rarities/${replaceAll(props.rarity.name.toLowerCase(), " ", "_")}.svg`} />
                        <span>{props.rarity.name}</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Rarity</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}