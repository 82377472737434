import { Socket } from "socket.io-client"

import { AbstractResourceService } from "services/abstract-resource.service";
import { CardSetTypeDto, CardSetTypeListDto, CardSetTypePartialDto, CardSetTypeQueryDto, CardSetTypeRequestDto } from "dtos/shard/card-set-type.dto";

export class CardSetTypeService extends AbstractResourceService<CardSetTypeDto, CardSetTypeRequestDto, CardSetTypePartialDto, CardSetTypeListDto, CardSetTypeQueryDto>
{
    public cardSetTypeDtos: Record<string, CardSetTypeDto> = {};

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket, "cardSetType", "Card Set Type", "Card Set Types", [
            {
                key: "name",
                name: "Name",
                type: "string",
            },
        ]);
    }
}