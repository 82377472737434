import { createUseStyles } from "react-jss";

import { CardTemplate } from "templates";

export default createUseStyles<any, {
    template: CardTemplate,
}>({
    container: {
        position: "relative",
        display: "block",
        width: (props) => props.template.innerWidth,
        height: (props) => props.template.innerHeight,
        padding: (props) => `${props.template.borderHeight}px ${props.template.borderWidth}px`,
        overflow: "hidden",

        fontFamily: "Metamorphous",
        textAlign: "left",
        textShadow: "none",

        "& > img": {
            width: (props) => props.template.fullWidth,
            height: (props) => props.template.fullHeight,
        },
    },
    mask: {
        maskImage: "url(/images/card/mask.png)",
    },
    background: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: -1,

        backgroundColor: "black",
    },

    interface: {
        position: "relative",
        width: "100%",
        height: "100%",
    },

    box: {
        position: "absolute",
        background: "rgba(0, 0, 0, 0.75)",
        borderColor: "rgba(0, 0, 0, 0.75)",
    },

    framesWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    frames: {
        position: "absolute",
        width: "100%",
        height: "100%",
        filter: "url(#shard-card-frame-filter)",

        "& svg, img": {
            position: "absolute",
        },
    },

    textWrapper: {
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        gap: 8,
    },
    textVertical: {
        flexDirection: "column",
        justifyContent: "center",
        gap: 0,
        padding: 16,

        "& > $textWrapper": {
            padding: 16,
        },
    },
    text: {
        width: "100%",
        lineHeight: 1.6,
        fontSize: 16,
        alignSelf: "baseline",

        "& p": {
            margin: 0,
            marginTop: "0.75rem",
            textWrap: "balance",

            "&:first-child": {
                marginTop: 0,
            },
        },

        "& > img": {
            verticalAlign: "baseline",
        },
    },
    xsmall: {
        fontSize: 12,
        lineHeight: 1.2,
    },
    small: {
        fontSize: 14,
        lineHeight: 1.4,
    },
    medium: {
        fontSize: 20,
    },
    large: {
        fontSize: 28,
    },
    center: {
        textAlign: "center",
    },
    right: {
        textAlign: "right",
    },
    number: {
        fontFamily: "Black Castle MF",
    },
    stroke: {
        filter: "url(#shard-card-text-stroke-filter)",
    },

    element: {
        position: "absolute",
        width: 80,
        height: 80,
        filter: "url(#shard-card-element-filter)",

        "& img": {
            width: "100%",
            height: "auto",
        },
    },
    elementText: {
        fontSize: 18,
        fontWeight: "bold",
    },
    nameText: {
        fontSize: 46,
    },
    titleText: {
        fontSize: 22,
        fontWeight: "bold",
    },
    actionText: {
        fontSize: 20,
        fontWeight: "bold",
    },
    powerText: {
        fontSize: 46,
    },
    effectText: {
        alignSelf: "center",
    },
    healthText: {
        fontSize: 70,
        alignSelf: "center",
    },
    creatureEffectName: {
        fontSize: 20,
        fontWeight: "bold",
    },
    creatureEffectText: {
        "& p": {
            marginTop: "0.25rem",

            "&:first-child": {
                marginTop: 0,
            },
        },
    },
    costs: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 8,

        filter: "url(#shard-card-element-filter)",

        "& > img": {
            width: 60,
            height: 60,
        },
    },
});