import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import widgets from "styles/widgets";
import { UserDto } from "dtos/user.dto";
import displayStyles from "styles/display-styles";
import { CreatureEffectDto } from "dtos/shard/creature-effect.dto";

import CreatureEffectView from "../views/creature-effect-view";
import CreatureEffectEditor from "../editors/creature-effect-editor";
import { statsPanel, toggleStatsPanel } from "utils";

const styles = createUseStyles({});

export default function CreatureEffectDisplay(props: {
    user?: UserDto, // Optional dto of the user.
    className?: string, // Optional css class name to use.
    creatureEffect?: Partial<CreatureEffectDto>, // The creatureEffect dto to display, if undefined a blank creatureEffect will be displayed instead.
    onSave?: (creatureEffect: CreatureEffectDto) => void, // A function to call when created or updated.
    onClose?: () => void, // A function to call when the close button is clicked, if not set the close button is hidden.
}): JSX.Element
{
    // States:
    const [stats, setStats] = useState(statsPanel());
    const [creatureEffectPreview, setCreatureEffectPreview] = useState(props.creatureEffect);

    // Effects:
    useEffect(() => {
        setCreatureEffectPreview(props.creatureEffect);
    }, [props.creatureEffect]);

    // Styles:
    const widgetClasses = widgets();
    const displayClasses = displayStyles();
    const classes = styles();

    // Components:
    let statsComp: JSX.Element | undefined;
    if (stats) {
        // CreatureEffect Stats (Editor for Admins):
        if (props.user?.admin) {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${displayClasses.editor}`}>
                        <h3>Editor:</h3>
                        <CreatureEffectEditor
                            user={props.user}
                            creatureEffect={props.creatureEffect}
                            preview={creatureEffectPreview}
                            onPreviewUpdate={setCreatureEffectPreview}
                            onSave={props.onSave}
                            onClose={props.onClose}
                        />
                    </div>
                </div>
            );
        } else {
            statsComp = (
                <div className={`${displayClasses.stats}`}>
                    <div className={`${widgetClasses.column}`}>
                        <h3>Stats:</h3>
                        <ul>
                            <li>Name: {props.creatureEffect?.name}</li>
                            <li>Effect: {props.creatureEffect?.effect}</li>
                        </ul>
                    </div>
                </div>
            );
        }
    }
    
    // Return Component:
    return (
        <div className={`${displayClasses.container} ${props.className ?? ""}`}>
            <div className={`${displayClasses.controls}`}>
                <div className={`${displayClasses.button} ${statsPanel() ? displayClasses.buttonActive : ""}`} onClick={() => setStats(toggleStatsPanel())}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconStats}`}></div>
                </div>
                {props.onClose && <div className={`${displayClasses.button}`} onClick={() => props.onClose!()}>
                    <div className={`${displayClasses.icon} ${displayClasses.iconClose}`}></div>
                </div>}
            </div>
            <CreatureEffectView creatureEffect={creatureEffectPreview} />
            {statsComp}
        </div>
    );
}