import { createUseStyles } from "react-jss";

import variables from "./variables";
import { highQuality } from "utils";

export default createUseStyles({
    container: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        flexGrow: 0,
        gap: "1em",
    },
    view: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        flexGrow: 0,
        gap: "1em",
    },
    controls: {
        display: "flex",
        flexDirection: "row",
        gap: "1em",
    },
    stats: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: "1em",

        flexGrow: 1,
    },
    editor: {
        width: 610,
        paddingRight: "1em",
        overflowY: "auto",
        overflowX: "hidden",

        flexGrow: 3,
        flexBasis: 3,
    },

    button: {
        width: "2em",
        height: "2em",
        padding: "1em",
        borderWidth: 1,

        borderStyle: "outset",
        borderRadius: "50%",
        borderColor: variables.colorsLight.neutral + (highQuality() ? "77" : ""),
        backgroundColor: variables.colors.neutral + (highQuality() ? "77" : ""),

        "&:hover": {
            borderColor: variables.colorsLight.hover + (highQuality() ? "77" : ""),
            backgroundColor: variables.colors.hover + (highQuality() ? "77" : ""),
        },
    },
    buttonActive: {
        borderColor: variables.colorsLight.average + (highQuality() ? "77" : ""),
        backgroundColor: variables.colors.average + (highQuality() ? "77" : ""),

        "&:hover": {
            borderColor: variables.colorsLight.average + (highQuality() ? "AA" : ""),
            backgroundColor: variables.colors.average + (highQuality() ? "AA" : ""),
        },
    },
    buttonDisabled: {
        borderColor: variables.colorsLight.negative + (highQuality() ? "77" : ""),
        backgroundColor: variables.colors.negative + (highQuality() ? "77" : ""),

        "&:hover": {
            borderColor: variables.colorsLight.negative + (highQuality() ? "77" : ""),
            backgroundColor: variables.colors.negative + (highQuality() ? "77" : ""),
        },
    },
    icon: {
        width: "100%",
        height: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    },
    iconFullscreen: {
        backgroundImage: "url(/images/icons/fullscreen.svg)",
    },
    iconStats: {
        backgroundImage: "url(/images/icons/stats.svg)",
    },
    iconDownload: {
        backgroundImage: "url(/images/icons/download.svg)",
    },
    iconCutout: {
        backgroundImage: "url(/images/icons/cutout.svg)",
    },
    iconAnimation: {
        backgroundImage: "url(/images/icons/animation.svg)",
    },
    iconClose: {
        backgroundImage: "url(/images/icons/close.svg)",
    },

    "@media screen and (max-width: 1280px)": {
        view: {
            flexDirection: "column",
        },
        editor: {
            overflow: "visible",
        },
    },
});