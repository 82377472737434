import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { creatureEffectService } from "services";
import { CreatureEffectCommon, CreatureEffectDto, CreatureEffectRequestDto } from "dtos/shard/creature-effect.dto";
import { UserDto } from "dtos/user.dto";

import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";
import Rte from "../rte";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
});

export default function CreatureEffectEditor(props: {
    user?: UserDto, // Optional dto of the user.
    creatureEffect?: Partial<CreatureEffectDto>,
    preview?: Partial<CreatureEffectDto>,
    onPreviewUpdate?: (previewDto: Partial<CreatureEffectDto>) => void,
    onClose?: () => void,
    onSave?:(creatureEffect: CreatureEffectDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: CreatureEffectCommon = {
        name: props.creatureEffect?.name ?? "",
        effect: props.creatureEffect?.effect ?? "",
    };
    const freshRequestDto: CreatureEffectRequestDto = {
        id: props.creatureEffect?.id,
        ...freshCommon,
    };
    const freshPreviewDto: Partial<CreatureEffectDto> = props.preview ? structuredClone(props.preview) : props.creatureEffect ? structuredClone(props.creatureEffect) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "creatureEffect", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate);

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
    }, [props.creatureEffect]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // CreatureEffects:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate(props.creatureEffect ?? previewDto);
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name ?? ""} onChange={onTextChange} />
            </div>

            <Rte id="effect" value={requestDto.effect ?? ""} onChange={onRteChange} />

            <hr/>
            <EditorControls
                editorName="creatureEffect"
                service={creatureEffectService}
                subject={props.creatureEffect}
                subjectName={props.creatureEffect?.name ?? ""}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <hr/>
        </div>
    );
}