import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

import { ruleService } from "services";
import { RuleCommon, RuleDto, RuleRequestDto } from "dtos/shard/rule.dto";
import { UserDto } from "dtos/user.dto";

import widgets from "styles/widgets";
import { useResources } from "hooks/resources.hook";
import { useEditorActions } from "hooks/editor-actions.hook";
import { useEditorPreview } from "hooks/editor-preview.hook";
import EditorControls from "../editor-controls";
import Rte from "../rte";

const styles = createUseStyles({
    container: {
        width: "100%",
    },
    descriptionRte: {
        height: "40vh !important",
    },
});

export default function RuleEditor(props: {
    user?: UserDto, // Optional dto of the user.
    rule?: Partial<RuleDto>,
    preview?: Partial<RuleDto>,
    onPreviewUpdate?: (previewDto: Partial<RuleDto>) => void,
    onClose?: () => void,
    onSave?:(rule: RuleDto) => void,
}): JSX.Element
{
    // Dtos:
    const freshCommon: RuleCommon = {
        name: props.rule?.name ?? "",
        description: props.rule?.description ?? "",
        position: props.rule?.position ?? 100,
        ruleCategoryId: props.rule?.ruleCategoryId ?? "",
    };
    const freshRequestDto: RuleRequestDto = {
        id: props.rule?.id,
        ...freshCommon,
    };
    const freshPreviewDto: Partial<RuleDto> = props.preview ? structuredClone(props.preview) : props.rule ? structuredClone(props.rule) : {
        id: undefined,
        ...freshCommon,
    };

    // Hooks:
    const resources = useResources(props.user);
    const [previewDto, requestDto, previewUpdate, setRequestDto] = useEditorPreview(freshPreviewDto, freshRequestDto, props.onPreviewUpdate);
    const [onTextChange, onNumberChange, onRteChange, onCheckboxChange, onPopup] = useEditorActions(props.user, "rule", resources, requestDto, previewDto, setRequestDto, props.onPreviewUpdate);

    // Effects:
    useEffect(() => {
        setRequestDto(freshRequestDto);
    }, [props.rule]);

    // Styles:
    const widgetClasses = widgets();
    const classes = styles();

    // Actions:
    const reset = () => {
        setRequestDto(freshRequestDto);
        if (props.onPreviewUpdate) {
            props.onPreviewUpdate(props.rule ?? previewDto);
        }
    };
    
    // Return Component:
    return (
        <div className={`${widgetClasses.form} ${classes.container}`}>
            <div className={`${widgetClasses.row}`}>
                <label>Name</label>
                <input id="name" type="text" value={requestDto.name ?? ""} onChange={onTextChange} />
            </div>
            <div className={`${widgetClasses.row}`}>
                <label>Position</label>
                <input id="position" type="number" value={requestDto.position ?? ""} onChange={onNumberChange} />
            </div>

            <h3>Description</h3>
            <Rte
                id="description"
                value={requestDto.description ?? ""}
                onChange={onRteChange}
                textAlign="left"
                className={`${classes.descriptionRte}`}
            />

            <hr/>
            <EditorControls
                editorName="rule"
                service={ruleService}
                subject={props.rule}
                subjectName={props.rule?.name ?? ""}
                requestDto={requestDto}
                onSave={props.onSave}
                onReset={reset}
                onClose={props.onClose}
            />
            <hr/>
        </div>
    );
}