import { createUseStyles } from "react-jss";

import { CardSetTypeDto } from "dtos/shard/card-set-type.dto";

const styles = createUseStyles({
    container: {
        minWidth: "20em",
        textAlign: "center",
    },
    set: {
        textTransform: "capitalize",
    },
});

export default function CardSetTypeView(props: {
    cardSetType?: Partial<CardSetTypeDto>,
}): JSX.Element
{
    // Styles:
    const classes = styles();

    // Components:
    return (
        <div className={`${classes.container}`}>
            <h3 className={`${classes.set}`}>{props.cardSetType?.name ?? "New Card Set Type"}</h3>
        </div>
    );
}