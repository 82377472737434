import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "reportWebVitals";

import App from "components/app";

import "fonts/metamorphous.ttf"
import "fonts/black_castle_mf.ttf"
import "index.css";

// Create Root Component:
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Web Vitals: (disabled)
reportWebVitals();
