import { Link } from "react-router-dom";

import widgets from "styles/widgets";

export default function Void(): JSX.Element
{
    // Component:
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.page} ${widgetClasses.narrow}`}>
            <div className={`${widgetClasses.section}`}>
                <h1>404 - Looks like the Void!</h1>
                <img src="/images/card/elements/void.png" className={`img-small`} />
                <p>Either this page no longer exists or the url entered is wrong, you can escape the all consuming hunger of the void by <Link to="/">taking this portal back to the home page</Link>.</p>
            </div>
        </div>
    );
}