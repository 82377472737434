import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { CardDto } from "dtos/shard/card.dto";
import { CardEntryDto } from "dtos/shard/card-entry.dto";
import CardRender from "../renders/card-render";
import { CardTemplate, templates } from "templates";
import { UserDto } from "dtos/user.dto";

const styles = createUseStyles<any, {
    scale: number,
    template: CardTemplate,
}>({
    container: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: 0,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        flexGrow: 1,
    },
    wrapper: {
        position: "relative",
        width: "100%",
        height: "auto",

        margin: -40,
        padding: 40,
    },
    card: {
        width: (props) => props.template.fullWidth * props.scale,
        height: (props) => props.template.fullHeight * props.scale,
        transformOrigin: "0 0",
        transform: (props) => `
            scale(${props.scale})
        `,
    },

    tilt: {
        animation: "$tilt-animation",
        animationDuration: 6000,
        animationIterationCount: "infinite",
        animationTimingFunction:  "linear",
        animationDirection:  "normal",
        animationFillMode: "both",
    },
    "@keyframes tilt-animation": {
        "0%": {
            transform: `
                perspective(2000px)
                rotateX(-5deg)
                rotateY(-5deg)
            `,
        },
        "13%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(-7deg)
            `,
        },
        "25%": {
            transform: `
                perspective(2000px)
                rotateX(5deg)
                rotateY(-5deg)
            `,
        },
        "38%": {
            transform: `
                perspective(2000px)
                rotateX(7deg)
                rotateY(0deg)
            `,
        },
        "50%": {
            transform: `
                perspective(2000px)
                rotateX(5deg)
                rotateY(5deg)
            `,
        },
        "63%": {
            transform: `
                perspective(2000px)
                rotateX(0deg)
                rotateY(7deg)
            `,
        },
        "75%": {
            transform: `
                perspective(2000px)
                rotateX(-5deg)
                rotateY(5deg)
            `,
        },
        "88%": {
            transform: `
                perspective(2000px)
                rotateX(-7deg)
                rotateY(0deg)
            `,
        },
        "100%": {
            transform: `
                perspective(2000px)
                rotateX(-5deg)
                rotateY(-5deg)
            `,
        },
    },
});

export default function CardView(props: {
    user?: UserDto,
    card?: Partial<CardDto>,
    cardEntry?: Partial<CardEntryDto>,
    animation?: boolean,
}): JSX.Element
{
    // Refs:
    const containerRef = useRef<HTMLDivElement>(null);

    // States
    const [containerHeight, setContainerHeight] = useState(0);

    // Effects:
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    // Actions:
    const [mouseMove, mouseLeave] = useMouseTilt();
    const handleResize = () => {
        setContainerHeight(containerRef.current?.clientHeight ?? 0);
    };

    // Styles:
    const template: CardTemplate = templates[props.card?.cardType?.template.name ?? "spell"];
    const scale: number = containerHeight / template.fullHeight;
    const widgetClasses = widgets();
    const classes = styles({
        scale,
        template,
    });

    // Components:
    return (
        <div
            className={`${classes.container}`}
            ref={containerRef}
            onMouseMove={props.animation ? mouseMove : () => {}}
            onMouseLeave={props.animation ? mouseLeave : () => {}}
        >
            <div className={`${classes.wrapper} ${widgetClasses.mouseTilt} ${props.animation ? classes.tilt : ""}`}>
                <div className={`${classes.card}`}>
                    <CardRender user={props.user} card={props.card} cardEntry={props.cardEntry} mask={true} />
                </div>
            </div>
        </div>
    );
}