import { ShardGateway } from "gateways/shard.gateway";

import { LoginService } from "services/login.service";
import { UserService } from "services/user.service";

import { ImagesService } from "services/images.service";
import { TtsService } from "services/tts.service";

import { CardService } from "services/card.service";
import { CardEntryService } from "services/card-entry.service";
import { CardSetService } from "services/card-set.service";
import { CardSetTypeService } from "services/card-set-type.service";
import { ArtistService } from "services/artist.service";
import { RarityService } from "services/rarity.service";
import { CardTypeService } from "services/card-type.service";
import { TemplateService } from "services/template.service";
import { ElementService } from "services/element.service";
import { TitleService } from "services/title.service";
import { ActionService } from "services/action.service";
import { ActionTypeService } from "services/action-type.service";
import { ActionEffectService } from "services/action-effect.service";
import { CreatureEffectService } from "services/creature-effect.service";
import { RuleService } from "services/rule.service";
import { RuleCategoryService } from "services/rule-category.service";
import { DeckService } from "services/deck.service";

console.info("[Services] Initialising services...");

// Accounts:
export const shardGateway = new ShardGateway();

export const loginService = new LoginService(shardGateway.socket);
export const userService = new UserService(shardGateway.socket);

export const imagesService = new ImagesService(shardGateway.socket);
export const ttsService = new TtsService(shardGateway.socket);

export const cardService = new CardService(shardGateway.socket);
export const cardEntryService = new CardEntryService(shardGateway.socket);
export const cardSetService = new CardSetService(shardGateway.socket);
export const cardSetTypeService = new CardSetTypeService(shardGateway.socket);
export const artistService = new ArtistService(shardGateway.socket);
export const rarityService = new RarityService(shardGateway.socket);
export const cardTypeService = new CardTypeService(shardGateway.socket);
export const templateService = new TemplateService(shardGateway.socket);
export const elementService = new ElementService(shardGateway.socket);
export const titleService = new TitleService(shardGateway.socket);
export const actionService = new ActionService(shardGateway.socket);
export const actionTypeService = new ActionTypeService(shardGateway.socket);
export const actionEffectService = new ActionEffectService(shardGateway.socket);
export const creatureEffectService = new CreatureEffectService(shardGateway.socket);
export const ruleService = new RuleService(shardGateway.socket);
export const ruleCategoryService = new RuleCategoryService(shardGateway.socket);
export const deckService = new DeckService(shardGateway.socket);