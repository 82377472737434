import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { useMouseTilt } from "hooks/mouse-tilt.hook";
import { ActionTypeDto } from "dtos/shard/action-type.dto";

export default function ActionTypeItem(props: {
    id: string,
    actionType?: ActionTypeDto,
    selected: boolean,
    onClick?: (actionType?: ActionTypeDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.actionType);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.actionType,
        selected: props.selected,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${widgetClasses.mouseTilt}`}>
                {props.actionType ? (<>
                    <div className={`${listItemClasses.title}`}>
                        <img src={`/images/card/${props.actionType.name.toLowerCase()}.svg`} />
                        <span>{props.actionType.name}</span>
                    </div>
                </>) : (<>
                    <div className={`${listItemClasses.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Action Type</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}