import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import widgets from "styles/widgets";
import listItemStyles from "styles/list-item-styles";
import { ActionDto } from "dtos/shard/action.dto";
import { useMouseTilt } from "hooks/mouse-tilt.hook";

const styles = createUseStyles<any, {
    action?: ActionDto,
}>({
    tile: {
        borderColor: (props) => props.action ? props.action.element.color + "77" : variables.colorsLight.foreground,
        backgroundColor: (props) => props.action ? props.action.element.color + "77" : variables.colorsDark.background + "77",

        "&:hover": {
            borderColor: (props) => props.action ? props.action.element.color + "AA" : variables.colors.hover + "77",
            backgroundColor: (props) => props.action ? props.action.element.color + "AA" : variables.colors.hover + "77",
        },
        "& > $column": {
            paddingLeft: "0.5em",
            paddingTop: "0.5em",
        },
        "& > div": {
            padding: 0,
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        flexGrow: 1,
        gap: "0.25em",
    },
    attribute: {
        display: "flex",
        height: "auto",

        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",

        "& > span": {
            width: "auto",
            textAlign: "left",
            fontSize: "10pt",
        },
        "& > img": {
            width: "1.5em",
            height: "auto",
            marginRight: "0.25em",
        },
    },
    title: {
        "& > span": {
            fontSize: "11pt",
            fontWeight: "bold",
        },
    },
    power: {
        height: "100%",
        fontFamily: "Black Castle MF",
        padding: 0,

        "& > span": {
            fontSize: "18pt",
            marginRight: "0.25em",
        },
        "& > img": {
            width: "1em",
            height: "auto",
            marginRight: "0.25em",
        },
    },
});

export default function ActionItem(props: {
    id: string,
    action?: ActionDto,
    selected: boolean,
    onClick?: (action?: ActionDto) => void,
}): JSX.Element
{
    // Actions:
    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.action);
        }
    }
    const [mouseMove, mouseLeave] = useMouseTilt();

    // Styles:
    const widgetClasses = widgets();
    const listItemClasses = listItemStyles({
        create: !!props.action,
        selected: props.selected,
    });
    const classes = styles({
        action: props.action,
    });
    
    // Return Component:
    return (
        <div
            onClick={() => onClick()}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            className={`${listItemClasses.container}`}
        >
            <div className={`${listItemClasses.tile} ${classes.tile} ${widgetClasses.mouseTilt}`}>
                {props.action ? (<>
                    <div className={`${classes.column}`}>
                        <div className={`${classes.attribute} ${classes.title}`}>
                            <span>{props.action.name}</span>
                        </div>
                        <div className={`${classes.attribute}`}>
                            <span>{`${props.action.actionType.name}${props.action.actionEffect?.name ? ", " + props.action.actionEffect.name : ""}`}</span>
                        </div>
                        <div className={`${classes.attribute}`}>
                            <img src={`/images/card/elements/${props.action.element.name.toLowerCase()}.svg`} />
                            <span>{props.action.element.name}</span>
                        </div>
                    </div>
                    <div className={`${classes.attribute} ${classes.power}`}>
                        <img src={`/images/card/${props.action.actionType.name.toLowerCase()}.svg`} />
                        <span>{props.action.power}</span>
                    </div>
                </>) : (<>
                    <div className={`${classes.attribute} ${classes.title}`}>
                        <span>{props.id === "create" ? "Create New" : "No"} Action</span>
                    </div>
                </>)}
            </div>
        </div>
    );
}