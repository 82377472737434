import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import { highQuality } from "utils";

export default createUseStyles({
    column: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        flexBasis: 0,
        flexGrow: 1,
    },
    row: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexBasis: 0,
        flexGrow: 1,
    },
    grid: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "start",
        alignContent: "start",
        alignItems: "center",
        overflowY: "auto",
        flexBasis: 0,
        flexGrow: 1,
    },
    grow: {
        width: "100%",
        height: "100%",
        flexGrow: 1,
    },
    gap: {
        gap: "1em",
    },
    left: {
        textAlign: "left",
        alignItems: "start",
    },
    center: {
        textAlign: "center",
        alignItems: "middle",
    },
    right: {
        textAlign: "right",
        alignItems: "end",
    },
    background: {
        ...variables.box(),
    },
    page: {
        width: "100%",
        padding: "0 4em 1em 4em",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        gap: "1em",

        "& > $panel": {
            width: "100%",
        },
    },
    narrow: {
        maxWidth: 1024,
        "& > $section": {
            maxWidth: 1024,
        },
    },
    section: {
        width: "100%",
        padding: "2em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",

        borderRadius: "0.25em",
        ...variables.box(),

        "& img": {
            width: "100%",
        }
    },
    panel: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        textAlign: "left",
        overflowY: "auto",
        overflowX: "hidden",

        borderRadius: "0.25em",
        ...variables.box(),

        "& img": {
            width: "100%",
        }
    },
    sidebar: {
        width: "20%",
        height: "100%",
        minWidth: 256,
        flexGrow: 0,
    },
    overlay: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        marginLeft: "-2em",
        marginRight: "-2em",
        ...variables.box(),
    },
    widget: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        overflowY: "auto",
        gap: "1em",
    },
    popup: {
        position: "absolute",
        left: "50%",
        top: "8em",
        transform: "translateX(-50%)",
        padding: "2em",
        borderRadius: "0.25em",
        maxWidth: "60%",

        background: variables.colorsDark.neutral + (highQuality() ? "DD" : ""),
        boxShadow: `0 0 10px 10px ${variables.colorsDark.neutral + (highQuality() ? "DD" : "")}`,
        "& h1": {
            textAlign: "center",
        },
    },
    popupLarge: {
        top: "1em",
        minHeight: "70vh",
    },
    popupWide: {
        width: "70%",
        maxWidth: "70%",
    },
    popupList: {
        position: "absolute",
        display: "flex",
        left: "50%",
        top: 0,
        width: "80vw",
        height: "calc(100% - 8em)",
        padding: "2em",
        borderRadius: "0.25em",
        transform: "translateX(-50%)",
        overflowY: "auto",
        flexDirection: "column",
        gap: "1em",

        background: variables.colorsDark.neutral + (highQuality() ? "DD" : ""),
        boxShadow: `0 0 10px 10px ${variables.colorsDark.neutral + (highQuality() ? "DD" : "")}`,
        "& h1": {
            textAlign: "center",
        },
    },
    notification: {
        width: "16em",
        height: "auto",
        padding: "1em",
        textAlign: "center",

        borderRadius: "0.25em",
        backgroundColor: variables.colors.neutral + (highQuality() ? "AA" : ""),
    },
    form: {
        display: "flex",
        gap: "1em",
        flexDirection: "column",
        "& label": {
            marginLeft: "2em",
            flexGrow: 1,
            textAlign: "left",
        },
        "& input": {
            width: "55%",
            marginLeft: "2em",
        },
        "& input[type=checkbox]": {
            width: "auto",
            transform:" scale(2)",
        },
        "& > div button": {
            flexGrow: 1,
            marginLeft: 4,
            marginRight: 4,
        },
        "& > div .side-button": {
            flexGrow: 0,
        },
        "& h3": {
            margin: 0,
        },
        "& h4": {
            margin: 0,
            textAlign: "center",
        },
    },
    checkboxContainer: {
        width: "55%",
        marginLeft: "2em",
        flexGrow: 1,
    },
    confirm: {
        backgroundColor: variables.colors.positive + (highQuality() ? "AA" : ""),
    },
    info: {
        backgroundColor: variables.colors.primary + (highQuality() ? "AA" : ""),
    },
    warning: {
        backgroundColor: variables.colors.negative + (highQuality() ? "AA" : ""),
        "&:hover": {
            backgroundColor: variables.colors.negative,
        },
    },
    selected: {
        backgroundColor: variables.colors.average,
        "&:hover": {
            backgroundColor: variables.colors.average,
        },
    },
    capitalize: {
        textTransform: "capitalize",

        "& > button": {
            textTransform: "capitalize",
        },
    },
    mouseTilt: {
        willChange: "transform",
        transition: "transform 0.1s ease",
        transformStyle: "preserve-3d",
    },

    "@media screen and (max-width: 1280px)": {
        section: {
            width: "calc(100% - 4em)",
            padding: "2em",
        },
        narrow: {
            maxWidth: "100%",
            "& > $section": {
                maxWidth: "100%",
            },
        },
    },

    "@media screen and (max-width: 720px)": {
        column: {
            paddingBottom: 8,
            gap: 8,
            flexWrap: "wrap",
        },
        row: {
            // paddingBottom: 8,
            gap: 8,
            justifyContent: "center",
            flexWrap: "wrap",
        },
        grid: {
            paddingBottom: 8,
            gap: 8,
            justifyContent: "center",
            alignContent: "center",
        },
        grow: {
            height: "auto",
        },
        widget: {
            position: "relative",
            height: "auto",
        },
        popup: {
            width: "80%",
        },
        sidebar: {
            width: "100%",
            height: "auto",
        },
        form: {
            gap: 8,
            "& label": {
                marginLeft: 0,
                flexGrow: 1,
            },
            "& input": {
                flexGrow: 1,
                marginLeft: 0,
            },
            "& > div button": {
                flexGrow: 1,
                marginLeft: 4,
                marginRight: 4,
            },
            "& > div .side-button": {
                flexGrow: 1,
            },
            "& > div label": {
                textAlign: "center",
            },
        },
    },

    "@media screen and (min-width: 1280px)": {
        popup: {
            width: "40%",
        },
        popupWide: {
            width: "70%",
            maxWidth: "70%",
        },
    },
});